import React, { useState, useEffect } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Swal from 'sweetalert2';
import axios from 'axios';
import webservice from '../../../../services/webservice';
import { useNavigate } from 'react-router-dom';
import CalendarData from './calendarData';

const EventCalendar = () => {
   const [calendarEvents, setCalendarEvents] = useState([]);
   const [allData, setAllData] = useState(null);
   const navigate = useNavigate();

   useEffect(() => {
      fetchEvents();
   }, []);

   const userId = localStorage.getItem('id');

   const fetchEvents = async () => {
      try {
         const response = await axios.post(webservice + 'api/getcalenderevents', { userId });
         const entries = response.data.events ? response.data.events[0] : {}; // Adjusting based on your backend response structure

         if (!entries || Object.keys(entries).length === 0) {
            return;
         }
         const events = [];

         Object.keys(entries).forEach(key => {
            if (key !== '_id' && key !== 'userID') {
               const date = key;
               const eventData = entries[date];

               // Create a new event object for each event type if the length is greater than 0
               if (eventData.bloodPressureId && eventData.bloodPressureId.length > 0) {
                  const event = {
                     title: `Blood Pressure (${eventData.bloodPressureId.length})`,
                     ids: eventData.bloodPressureId,
                     type: 'BloodPressure',
                     start: formatDate(date),
                     allDay: true
                  };
                  events.push(event);
               }
               if (eventData.diabetesId && eventData.diabetesId.length > 0) {
                  const event = {
                     title: `Diabetes (${eventData.diabetesId.length})`,
                     ids: eventData.diabetesId,
                     type: 'Diabetes',
                     start: formatDate(date),
                     allDay: true
                  };
                  events.push(event);
               }
               if (eventData.moodDataId && eventData.moodDataId.length > 0) {
                  const event = {
                     title: `Mood (${eventData.moodDataId.length})`,
                     ids: eventData.moodDataId,
                     type: 'Mood',
                     start: formatDate(date),
                     allDay: true
                  };
                  events.push(event);
               }
               if (eventData.medicalRecordId && eventData.medicalRecordId.length > 0) {
                  const event = {
                     title: `Document's (${eventData.medicalRecordId.length})`,
                     ids: eventData.medicalRecordId,
                     type: 'Documents',
                     start: formatDate(date),
                     allDay: true
                  };
                  events.push(event);
               }
            }
         });

         setCalendarEvents(events);
      } catch (error) {
         console.error('Error fetching events:', error);
      }
   };



   // Function to format the date from DD/MM/YYYY to YYYY-MM-DD
   const formatDate = (dateString) => {
      const [day, month, year] = dateString.split('/');
      return `${year}-${month}-${day}`;
   };

   const handleDateClick = async (clickedDate) => {
      const userId = localStorage.getItem('id');

      try {
         // Send userId and clickedDate to the backend
         const response = await axios.post(webservice + 'api/processIds', { userId, clickedDate });
         const responseData = response.data;

         if (responseData && responseData.length > 0) {
            // Navigate to the new page with the response data
            navigate('/calenderData', { state: { data: responseData, clickedDate: clickedDate } });

         } else {

            // Display a Swal alert if there is no data available
            Swal.fire('No Data!', 'No data available for the selected date.', 'info');

         }

      } catch (error) {

         console.error('Error processing IDs:', error);
         // Display error message if needed
         Swal.fire('Error!', 'There was an error processing IDs.', 'error');

      }
   };


   const dateClick = async (dateClickInfo) => {
      const clickedDate = dateClickInfo.dateStr;
      handleDateClick(clickedDate);
   };

   const eventClick = async (eventClickInfo) => {
      const clickedDate = eventClickInfo.event.startStr;
      handleDateClick(clickedDate);
   };

   return (
      <div className="animated fadeIn demo-app">
         <Row>
            <Col lg={12}>
               <Card>
                  <Card.Body>
                     {/* <div   className="demo-app-calendar" id="mycalendartest"> */}
                     <div style={{ overflowX: 'scroll', maxWidth: '100%' }} className="demo-app-calendar" id="mycalendartest">
                        <FullCalendar
                           initialView="dayGridMonth"
                           headerToolbar={{
                              start: 'prev,next',
                              center: 'title',
                              end: '',
                              // end: 'dayGridMonth,timeGridWeek,timeGridDay',
                           }}
                           // plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                           plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                           weekends={true}
                           events={calendarEvents}
                           dateClick={dateClick}
                           eventClick={eventClick}
                           eventDidMount={(info) => {
                              info.el.style.cursor = 'pointer';
                           }}
                        />
                     </div>
                  </Card.Body>
               </Card>
            </Col>
         </Row>
         {allData && <CalendarData allData={allData} />}
      </div>
   );
};

export default EventCalendar;
