import React, { Fragment, useState } from "react";
import "../../../css/custom.css";
import { Card, CardBody, Button, Form, FormGroup, Label, Input, Alert } from "reactstrap";
import { useDispatch } from "react-redux";
import { AddStress } from "./store";
import { Link, useNavigate } from "react-router-dom";
import { AesDecrypt } from "../../../services/crypto";
import { useTranslation } from 'react-i18next';
import BreadCrumbs from "../../breadcrumbs";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from 'uuid';

const Stress = () => {
  const dispatch = useDispatch();
  const [stress, setStress] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedChoice, setSelectedChoice] = useState("");
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userId = localStorage.id;

  const handleChoiceSelection = (choice) => {
    setSelectedChoice(choice);
    setError("");
  };

  const handleNextQuestion = (e) => {
    e.preventDefault();
    if (!selectedChoice) {
      setError(t("Please select an option"));
      return;
    }
    const currentQuestion = stressQuestions[currentQuestionIndex].id;
    setStress((prevStress) => ({ ...prevStress, [currentQuestion]: selectedChoice }));
    setSelectedChoice(stress[stressQuestions[currentQuestionIndex + 1]?.id] || "");
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handlePreviousQuestion = (e) => {
    e.preventDefault();
    setError(""); // Clear any existing error
    setSelectedChoice(stress[stressQuestions[currentQuestionIndex - 1].id] || "");
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const calculateScore = (scoreData, type) => {
    if (!scoreData || typeof scoreData !== 'object') return 0;

    const scores = Object.values(scoreData).map(Number);
    let totalScore = scores.reduce((acc, score) => acc + score, 0);

    if (totalScore === 0) {
      totalScore = 5; // Consider 0 as 5
    }
    const adjustedScore = (totalScore * 100) / 40;
    return Math.round(Math.min(adjustedScore, 100)); // Return rounded score
  };



  const handleSaveMood = (e) => {
    e.preventDefault();

    if (!selectedChoice) {
      setError(t("Please select an option"));
      return;
    }

    // Capture current stress question and selected choice
    const currentQuestion = stressQuestions[currentQuestionIndex].id;
    const StressData = { ...stress, [currentQuestion]: selectedChoice };

    // Calculate stress score
    const score = calculateScore(StressData);

    // Prepare dataToInsert according to the structure
    const uid = uuidv4(); // Generate unique ID
    const UTCDate = new Date().toISOString(); // UTC date format for creation/modification time
    const collection = 'Stress';
    const lastSyncDate = ''; // If no sync date is provided, leave it empty
    const dataToInsert = [{
      stress: StressData,
      score,
      // testDate: UTCDate,       // The UTC formatted test date
      userId,
      createdDate: UTCDate,    // UTC date for creation time
      // modifiedDate: UTCDate,   // UTC date for modification time
      uid
    }];
    const dataToDelete = []; // Add any data that needs to be deleted, currently it's empty

    // Construct the final data object
    const data = {
      dataToInsert,
      lastSyncDate,
      collection,
      dataToDelete,
      userId
    };

    // Dispatch the data to be stored in the backend
    dispatch(AddStress(data)).then((response) => {

      const Response = response.payload.response;
      if (Response === "S") {
        Swal.fire({
          title: t("Stress Test Successful"),
          icon: "success",
        });
        navigate('/mentalhealth');
      }
    });

    // Reset states after save
    setStress({});
    setSelectedChoice("");
    setCurrentQuestionIndex(0);
  };



  const stressQuestions = [
    { question: t("1.In the last month, how often have you been upset because of something that happened unexpectedly?"), id: "s1" },
    { question: t("2.In the last month, how often have you felt that you were unable to control the important things in your life?"), id: "s2" },
    { question: t("3.In the last month, how often have you felt nervous and stressed?"), id: "s3" },
    { question: t("4.In the last month, how often have you found that you could not cope with all the things that you had to do?"), id: "s4" },
    { question: t("5.In the last month, how often have you been angered because of things that happened that were outside of your control?"), id: "s5" },
    { question: t("6.In the last month, how often have you felt difficulties were piling up so high that you could not overcome them?"), id: "s6" },
    { question: t("7.In the last month, how often have you felt that you were on top of things?"), id: "s7" },
    { question: t("8.In the last month, how often have you been able to control irritations in your life?"), id: "s8" },
    { question: t("9.In the last month, how often have you felt that things were going your way?"), id: "s9" },
    { question: t("10.In the last month, how often have you felt confident about your ability to handle your personal problems?"), id: "s10" }
  ];

  const stressChoices1 = [
    { label: t("Never"), value: "0" },
    { label: t("Almost Never"), value: "1" },
    { label: t("Sometimes"), value: "2" },
    { label: t("Fairly often"), value: "3" },
    { label: t("Very often"), value: "4" }
  ];

  const stressChoices2 = [
    { label: t("Never"), value: "4" },
    { label: t("Almost Never"), value: "3" },
    { label: t("Sometimes"), value: "2" },
    { label: t("Fairly often"), value: "1" },
    { label: t("Very often"), value: "0" }
  ];

  const getChoicesForQuestion = (questionId) => {
    const idNumber = parseInt(questionId.replace("s", ""), 10);
    return idNumber <= 7 ? stressChoices1 : stressChoices2;
  };

  const isLastQuestion = currentQuestionIndex === stressQuestions.length - 1;
  const isFirstQuestion = currentQuestionIndex === 0;

  return (
    <Fragment>
      <BreadCrumbs title={t('Stress')} data={[{ title: t('Stress') }]} />
      <span className="mentalhealth text-black" style={{ fontSize: "25px" }}>{t('Over the last 2 weeks, how often have you been bothered by the following problems?')}</span>
      <Card style={{ marginTop: "2%", boxShadow: "0px 0px 10px 5px rgb(144 144 144 / 56%)" }}>
        <CardBody>
          <Form onSubmit={isLastQuestion ? handleSaveMood : handleNextQuestion}>
            <FormGroup tag="fieldset">
              <legend className="text-black" style={{ fontSize: "30px" }}>{stressQuestions[currentQuestionIndex].question}</legend>
              {getChoicesForQuestion(stressQuestions[currentQuestionIndex].id).map((choice, i) => (
                <FormGroup className="text-black" check key={i}>
                  <Label check>
                    <Input
                      type="checkbox"
                      className="form-check-input mb-4"
                      value={choice.value}
                      checked={selectedChoice === choice.value}
                      onChange={() => handleChoiceSelection(choice.value)}
                    />
                    {choice.label}
                  </Label>
                </FormGroup>
              ))}
            </FormGroup>
            {error && <Alert color="danger">{error}</Alert>}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {!isFirstQuestion && (
                <Button color="secondary" onClick={handlePreviousQuestion} style={{ marginRight: "10px" }}>
                  {t('Previous')}
                </Button>
              )}
              <Button style={{ width: 'auto' }} color="primary" type="submit" className="small-button"  >
                {isLastQuestion ? t('Save') : t('Next')}
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Stress;
