import React, { useState, useEffect } from "react";
import { json, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Card, Modal, Form, FormLabel } from "react-bootstrap";
import ReactSelect from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import BreadCrumbs from "../../breadcrumbs";
import webservice from "../../../services/webservice";
import { blooddata, bloodreceiverdatas, userAccesptPost } from './store/index'; // Ensure this is the correct path to the action
import { AesDecrypt, AesEncrypt } from "../../../services/crypto";
import img from '../../../images/EmptyFile.svg'
import moment from "moment";

const Blood = () => {
  // const [userData, setUserData] = useState([]); // Ensure initial state is an array
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [donateModal, setDonateModal] = useState(false);
  const [address, setAddress] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedTaluk, setSelectedTaluk] = useState(null);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [talukOptions, setTalukOptions] = useState([]);
  const [stateData, setStateData] = useState({});
  const userId = localStorage.id;
  const [bloodReceivers, setBloodReceivers] = useState([]);
  const [isToggled, setIsToggled] = useState(false);
  const [isdonor, setIsdonor] = useState('');
  const [showMobileFor, setShowMobileFor] = useState(null);

  useEffect(() => {
    fetchInitialState();
  }, []);

  const fetchInitialState = async () => {
    try {
      const maindata = {
        userId: userId,

      }
      // const userId = localStorage.id; // Assuming localStorage.id holds the userId
      const Encparams = AesEncrypt(maindata)
      const response = await axios.post(`${webservice}api/getWillingStatus`, {
        Encparams: Encparams
      });
      if (response.data.response === 'F') {
        setIsdonor('NoDonor')
      }
      if (response.data.response === 'S') {
        const decryptedResponce = AesDecrypt(response.data.data)
        const initialWillingState = decryptedResponce.willing;
        setIsToggled(initialWillingState);
      } else {
        setDonateModal(false)
      }

    } catch (error) {
      console.error('Error fetching initial state:', error);
    }
  };

  const handleToggle = async () => {
    if (isdonor !== 'NoDonor') {
      setIsToggled(!isToggled);
    }
    const willing = !isToggled;
    const Encparams = AesEncrypt({ willing, userId })
    try {
      const response = await axios.post(`${webservice}api/updateWillingStatus`, {
        Encparams: Encparams
      });
      if (response.data.response === 'F') {
        setDonateModal(true);
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Great!',
          text: 'You are now a donor. You can save a life!',
        });
      }
    } catch (error) {
      console.error('Error sending data to backend:', error);
    }
    // Show SweetAlert based on toggle state
    if (isToggled) {
      Swal.fire({
        icon: 'info',
        title: 'Thank you!',
        text: 'We will contact you soon to welcome you back as a donor.',
      });
    }
  };


  const handleStateChange = async (selectedOption) => {
    setSelectedState(selectedOption);
    setSelectedDistrict(null);
    setSelectedTaluk(null);
    setDistrictOptions([]);
    setTalukOptions([]);
    try {
      const response = await axios.post(webservice + "api/get-districts-taluks", { state: selectedOption.value });
      const data = response.data.districtsAndTaluks;
      setStateData(data);
      const districts = Object.keys(data).map((district) => ({ value: district, label: district }));
      setDistrictOptions(districts);
    } catch (error) {
      console.error("Error fetching districts and taluks:", error);
    }
  };

  const handleDistrictChange = (selectedOption) => {
    if (!selectedOption) {
      setSelectedDistrict(null);
      setTalukOptions([]);
      return;
    }

    setSelectedDistrict(selectedOption);
    if (selectedState && selectedOption) {
      const taluks = stateData[selectedOption.value].map((taluk) => ({ value: taluk, label: taluk }));
      setTalukOptions(taluks);
      setSelectedTaluk("");
    }
  };

  const handleSubmit = async () => {
    if (!selectedState || !selectedDistrict || !selectedTaluk) {
      return;
    }
    const postData = {
      state: selectedState.value,
      district: selectedDistrict.value,
      subDistrict: selectedTaluk.value,
      address: address,
      userId: userId,
    };
    const Encparams = AesEncrypt(postData);
    try {
      const response = await axios.post(`${webservice}api/addDonorDetails`, { Encparams });
      setDonateModal(false);
      setSelectedState(null);
      setSelectedDistrict(null);
      setSelectedTaluk(null);
      setAddress("");

      let title, message, icon;

      if (response.data.response === "S") {
        title = "Registered Successfully!";
        message = "Thank you for registering to donate blood. Your contribution can save lives!";
        icon = "success";
        setIsToggled(true)
        setIsdonor('Donor')
      } else {
        title = "Already Registered";
        message = "Thank you for your continued support as a blood donor. Your contributions are invaluable!";
        icon = "info";
      }

      Swal.fire({
        title: title,
        text: message,
        icon: icon,
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };


  useEffect(() => {
    dispatch(bloodreceiverdatas(userId)).then((response) => {

      const responsedata = response.payload.data;
      if (!responsedata || responsedata.length === 0) {
        setBloodReceivers([]);
        return;
      }
      const decryptedData = AesDecrypt(responsedata);

      setBloodReceivers(decryptedData);
    });
  }, [dispatch, userId]);


  const stateOptions = [
    { value: "Andhra Pradesh", label: "ANDHRA PRADESH" },
    { value: "Arunachal Pradesh", label: "ARUNACHAL PRADESH" },
    { value: "Assam", label: "ASSAM" },
    { value: "Bihar", label: "BIHAR" },
    { value: "Chhattisgarh", label: "CHHATTISGARH" },
    { value: "Goa", label: "GOA" },
    { value: "Gujarat", label: "GUJARAT" },
    { value: "Haryana", label: "HARYANA" },
    { value: "Himachal Pradesh", label: "HIMACHAL PRADESH" },
    { value: "Jharkhand", label: "JHARKHAND" },
    { value: "Karnataka", label: "KARNATAKA" },
    { value: "Kerala", label: "KERALA" },
    { value: "Madhya Pradesh", label: "MADHYA PRADESH" },
    { value: "Maharashtra", label: "MAHARASHTRA" },
    { value: "Manipur", label: "MANIPUR" },
    { value: "Meghalaya", label: "MEGHALAYA" },
    { value: "Mizoram", label: "MIZORAM" },
    { value: "Nagaland", label: "NAGALAND" },
    { value: "Odisha", label: "ODISHA" },
    { value: "Punjab", label: "PUNJAB" },
    { value: "Rajasthan", label: "RAJASTHAN" },
    { value: "Sikkim", label: "SIKKIM" },
    { value: "Tamil Nadu", label: "TAMIL NADU" },
    { value: "Telangana", label: "TELANGANA" },
    { value: "Tripura", label: "TRIPURA" },
    { value: "Uttar Pradesh", label: "UTTAR PRADESH" },
    { value: "Uttarakhand", label: "UTTARAKHAND" },
    { value: "West Bengal", label: "WEST BENGAL" },
    { value: "Andaman and Nicobar Islands", label: "ANDAMAN AND NICOBAR ISLANDS" },
    { value: "Chandigarh", label: "CHANDIGARH" },
    { value: "Dadra and Nagar Haveli and Daman and Diu", label: "DADRA AND NAGAR HAVELI AND DAMAN AND DIU" },
    { value: "Lakshadweep", label: "LAKSHADWEEP" },
    { value: "Delhi", label: "DELHI" },
    { value: "Puducherry", label: "PUDUCHERRY" },
    { value: "Ladakh", label: "LADAKH" },
    { value: "Jammu and Kashmir", label: "JAMMU AND KASHMIR" },
  ];

  const handleWillingToDonate = async (receiver) => {

    try {
      if (isdonor === 'NoDonor') {
        setDonateModal(true);
      } else {
        setShowMobileFor(receiver.postId);
        const response = await dispatch(userAccesptPost(receiver))
        const decryptedData = response.payload.response
        if (decryptedData === 'S') {
          dispatch(bloodreceiverdatas(userId)).then((response) => {
            const responsedata = AesDecrypt(response.payload.data);
            if (!responsedata || responsedata.length === 0) {
              setBloodReceivers([]);
              return;
            } else {
              setBloodReceivers(responsedata);
            }
          });
          if (!receiver.acceptStatus) {
            Swal.fire({
              title: 'Success',
              text: 'You have accepted to donate.',
              icon: 'success',
              confirmButtonText: 'OK',
            });
          } else {
            setShowMobileFor(null);
            Swal.fire({
              title: 'Canceled',
              text: 'You have canceled the donation.', // need to work on it both remove and cancel same message showing now
              icon: 'info',
              confirmButtonText: 'OK',
            });
          }
        } else {
        }
        return;
      }
    } catch (error) {
      console.error('Error fetching isDonor:', error);
      Swal.fire({
        title: "Error",
        text: "Error while accepting status. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };


  return (
    <>
      <div>
        <div  className="main_table" >
          <h1 className="container_heading" style={{ marginBottom: '2%' }}>Blood Groups</h1>
          {/* <BreadCrumbs title={t("Blood Groups")} data={[{ title: t("Blood Groups") }]} /> */}

        </div>
        <div className="blooddonation-topmenu">
          {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '50px' }}> */}
          <Link to={"/createpost"}>
            <Button variant="danger">
              {t("Your Request")}
            </Button>
          </Link>
          <Link to={"/trackHistory"}>
            <Button variant="danger">
              {t("Track History")}
            </Button>
          </Link>
          <Link to={"/acceptedList"}>
            <Button variant="danger">
              {t("Accepted List")}
            </Button>
          </Link>
          <Link to={"/bloodNotification"}>
            <Button variant="danger">
              <i id="icon-dark" className="notification-icon">&#128276; {t("Notification")}</i>
            </Button>
          </Link>
          <div className={`blood-card ${isToggled ? 'on' : 'off'}`} >
            <div className="text-black" style={{ textAlign: 'center' }}>
              {t('Donate blood')}
            </div>
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            <div className="toggle-switch" onClick={handleToggle} style={{ cursor: 'pointer' }}>
              <div className={`switch ${isToggled ? 'on' : 'off'}`} style={{ backgroundColor: isToggled ? 'green' : 'red' }}>
                <span className="toggle-text" style={{ color: 'white' }}>{isToggled ? 'On' : 'Off'}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={donateModal} onHide={() => setDonateModal(!donateModal)}>
        <Modal.Header closeButton>
          <Modal.Title> {t("Your Details")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <FormLabel> {t("Select your State")}:</FormLabel>
              <ReactSelect options={stateOptions} value={selectedState} onChange={handleStateChange} />
            </Form.Group>
            <Form.Group className="mt-3">
              <FormLabel> {t("Select your District")} :</FormLabel>
              <ReactSelect isDisabled={!selectedState} options={districtOptions} value={selectedDistrict} onChange={handleDistrictChange} />
            </Form.Group>
            <Form.Group className="mt-3">
              <FormLabel> {t("Select your Taluk")} :</FormLabel>
              <ReactSelect isDisabled={!selectedDistrict} options={talukOptions} value={selectedTaluk} onChange={(selectedOption) => setSelectedTaluk(selectedOption)} />
            </Form.Group>
            <Form.Group className="">
              <FormLabel> {t("Enter your address")} :</FormLabel>
              <Form.Control className="text-black" as="textarea" rows={3} value={address} onChange={(e) => setAddress(e.target.value)} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSubmit}>
            {t("Submit")}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="card-container">
        {Array.isArray(bloodReceivers) && bloodReceivers.length === 0 ? (
          <div className="d-flex justify-content-center">
            <img src={img} alt="No Data" style={{ position: "relative", top: "100px", height: "400px" }} />
          </div>
        ) : (
          Array.isArray(bloodReceivers) && bloodReceivers.map((receiver) => (


            <Card key={receiver.postId} className="card-custom">
              <div className="card-header-custom">
                <h2>{receiver.name} needs blood</h2>
                <span className="blood-group-blood">  {receiver.bloodGroup || "All Blood Types"}</span>
              </div>
              <div className="card-body-custom">
                <p>
                  <strong>Mobile :</strong> {receiver.acceptStatus ? receiver.mobile : '**********'}
                </p>
                {/* <p>Mobile: {receiver.mobile}</p> */}
                {/* <p> <strong>Mobile :</strong> {'**********'}</p> */}
                <p> <strong>hospitalName : </strong> {receiver.hospitalName || 'Not Mentioned'}</p>
                <p> <strong>Taluk : </strong> {receiver.subDistrict || 'Not Mentioned'}</p>
                <p> <strong>District : </strong> {receiver.district || 'Not Mentioned'}</p>
                <p> <strong>State : </strong> {receiver.state || 'Not Mentioned'}</p>
                <p> <strong>hospital Address : </strong> {receiver.address || 'Not Mentioned'}</p>
                <p> <strong>reason : </strong> {receiver.reason || 'Not Mentioned'}</p>
                <p> <strong>surgeryDate : </strong>{receiver.surgeryDate ? moment(receiver.surgeryDate).format('MMMM D') : 'Not Mentioned'}</p>
              </div>
              {/* <div className=""> */}
              <div className="card-footer-custom-space">
                Posted on: {receiver.surgeryDate ? moment(receiver.surgeryDate).format('MMM D, h:m A') : 'Not Mentioned'}
                <Button
                  className={`donate-button ${receiver.acceptStatus ? 'cancel-button' : ''}`}
                  onClick={() => handleWillingToDonate(receiver)}
                >
                  {receiver.acceptStatus ? receiver.status === 'NT' ? 'Remove' : 'Cancel' : 'Donate'}
                </Button>
              </div>
            </Card>
          ))
        )}

      </div>

    </>
  );
};

export default Blood;