import React, { useState, useEffect } from 'react';
import Gender_boy from './Demographic-images/gender_boy.svg';
import { useTranslation } from 'react-i18next';

const HeightPage = ({ handleNextClick, value, setvalue }) => {
  const [height, setHeight] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  const handleHeightChange = (e) => {
    setErrorMessage('');
    let newHeight = parseInt(e.target.value, 10);
    if (isNaN(newHeight)) {
      newHeight = '';
    } else if (newHeight > 250) {
      newHeight = 250;
    } else if (newHeight < 0) {
      newHeight = 0;
    }
    setHeight(newHeight);
  };

  const handleHeightInputChange = (e) => {
    setErrorMessage('');
    let newHeight = parseInt(e.target.value, 10);
    if (isNaN(newHeight)) {
      newHeight = '';
    } else if (newHeight > 250) {
      newHeight = 250;
    } else if (newHeight < 0) {
      newHeight = 0;
    }
    setHeight(newHeight);
  };

  const calculateImageHeight = () => {
    const validHeight = parseInt(height, 10);
    if (isNaN(validHeight)) {
      return 80; // Default image height if height is invalid
    }
    if (validHeight > 200) {
      return 200;
    } else if (validHeight < 80) {
      return 80;
    } else {
      return validHeight;
    }
  };

  useEffect(() => {
    const storedHeight = localStorage.getItem('height');
    setHeight(storedHeight !== null ? parseInt(storedHeight) : '');
    setErrorMessage('');
  }, []);

  const handleSubmit = () => {
    if (height === '' || height === 0) {
      setvalue(6);
      setErrorMessage(t('Please select your height'));
    } else {
      localStorage.setItem('height', height.toString());
      handleNextClick();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (value === 7) {
      handleSubmit();
    }
  }, [value]);

  return (
    <>
      <div className='D_container'>
        <div className='D_column D_img_container'>
          <img
            className='Height_boy_image'
            src={Gender_boy}
            alt="Boy"
          // style={{ height: `${2 * calculateImageHeight()}px`, minWidth: '400px' }}
          />
        </div>
        <div className='D_column D_contant_container demography_card'>
          <div className='demography_main_contant'>
            <div id='height_Demography_card'>
              <h2 style={{ color: "black" }}>{t('Enter your height')}</h2>
              <input
                value={height}
                type='number'
                onChange={handleHeightInputChange}
              />
              <label style={{ maxWidth: '80%', position: 'relative', top: '7%' }}>
                {t("Click and drag to increase your height using slider")}
              </label>
              <input
                type="range"
                min="0"
                max="250"
                onKeyDown={handleKeyDown}
                value={height || 0} // Show 0 when height is an empty string
                onChange={handleHeightChange}
                className="height-input"
              />
              <div style={{ textAlign: 'center' }}>
                <p style={{ textAlign: 'center' }}> {t('You selected : ')} <span style={{ fontSize: '1rem', color: 'red' }}>{height || 0} cm</span>  </p>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeightPage;
