import React from 'react';
import { Fulldetails } from '../../../store/actions/AuthActions';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next'
const FullDetailPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const name = localStorage.getItem('name');
  const dob = localStorage.getItem('dob');
  const gender = localStorage.getItem('gender');
  const maritalStatus = localStorage.getItem('maritial');
  const bloodGroup = localStorage.getItem('bloodgroup');
  const height = localStorage.getItem('height');
  const weight = localStorage.getItem('weight');
  const profession = localStorage.getItem('profession');
  const mobile = localStorage.getItem('mobile');
  const userId = localStorage.getItem('userID')
  const handleNextClick = async () => {
    dispatch(Fulldetails(name,
      dob,
      gender,
      maritalStatus,
      bloodGroup,
      profession,
      height,
      weight,
      mobile,
      userId, navigate));
  };

  return (
    <div className="container">
      <h2 className="title">{t('Full Details')}</h2>
      <p className="detail">{t('Name')}: {name}</p>
      <p className="detail">{t('Date of Birth')}: {dob}</p>
      <p className="detail">{t('Gender')}:{gender}</p>
      <p className="detail">{t('Maritial Status')}: {maritalStatus}</p>
      <p className="detail">{t('Height')}: {height}</p>
      <p className="detail">{t('Weight')}: {weight}</p>
      <p className="detail">{t('Blood Group')}: {bloodGroup}</p>
      <p className="detail">{t('Profession')}: {profession}</p>
      <button className="btn btn-primary" onClick={handleNextClick}>
        {t('Finish')}
      </button>
    </div>
  );
};

export default FullDetailPage;
