import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { Row, Col, Card, Table, Button, Modal, CardBody } from "react-bootstrap";
import { updateBloodPressureData, deleteBloodPressureData, AddBloodPressureData } from "./store";
import DatePicker from "react-datepicker";
import "../../../css/custom.css";
import webservice from '../../../services/webservice';
import { useTranslation } from 'react-i18next';
import { BloodPressureData } from "./store/index";
import { Badge, Input, UncontrolledTooltip } from "reactstrap";
import { v4 as uuidv4 } from 'uuid';
import img from '../../../images/EmptyFile.svg'
import { IoIosAddCircle } from "react-icons/io";
import { AesDecrypt } from "../../../services/crypto";
import { IoMdAddCircle } from 'react-icons/io';  // Import the icon
import Swal from "sweetalert2";

// const BloodPressureTable = ({ alldata, handleReload }) => {
const BloodPressureTable = ({ allData, setAllData }) => {

  const [editingIndex, setEditingIndex] = useState(null);
  const moment = require('moment');
  const [editedData, setEditedData] = useState({});
  const dispatch = useDispatch();
  // const [_id, setDeletedId] = useState(null);
  // const [uid, setUid] = useState(null);
  // const [createdDate, setCreatedDate] = useState(null);
  const { t } = useTranslation();
  const [basicModal, setBasicModal] = useState(false);
  const [systolic, setSystolic] = useState('');
  const [diastolic, setDiastolic] = useState('');
  const [pulse, setPulse] = useState('');
  const [systolicValue, setSystolicValue] = useState('');
  const [diastolicValue, setDiastolicValue] = useState('');
  const defalutDate = moment(new Date()).format('DD-MM-YYYY hh:mm A')
  const [date, setDate] = useState(defalutDate);
  const [errors, setErrors] = useState({});
  const userId = localStorage.id
  const [pulseCategory, setPulseCategory] = useState('');
  const [systalictooltipContent, setSystalicTooltipContent] = useState('');
  const [diastolictooltipContent, setDiastolicTooltipContent] = useState('');
  const [pulsetooltipContent, setPulseTooltipContent] = useState('');
  const [age, setAge] = useState('');
  const handleEdit = (index, data) => {
    setEditingIndex(index);
    setEditedData({ ...data });
  };


  const handleDelete = (_id, uid, createdDate) => {
    Swal.fire({
      title: 'Do you really want to delete this Blood Pressure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        handleConfirmDelete(_id, uid, createdDate);
        Swal.fire(
          'Deleted!',
          'The Blood Pressure has been deleted.',
          'success'
        );
      }
    });
  };

  const handleConfirmDelete = (_id, uid, createdDate) => {

    // setDeletedId(id);
    // setUid(uid);
    // setCreatedDate(createdDate);
    const collection = 'Medical_BP';
    const lastSyncDate = '';

    const dataToInsert = [];
    const dataToDelete = [{
      _id,
      uid,
      createdDate
    }]// Set the deleted ID state
    const data = {
      dataToDelete,
      dataToInsert,
      userId,
      collection,
      lastSyncDate
    }
    dispatch(deleteBloodPressureData(data)).then((response) => {
      const Returendata = AesDecrypt(response.payload.data);
      setAllData(Returendata.dataToInsert)
    });
  };


  const handleSave = (index) => {
    // Implement saving edited data logic here
    const { ...editedFields } = editedData;
    dispatch(updateBloodPressureData({ data: editedFields })).then((responce) => {
      // handleReload()
    });
    setEditingIndex(null);

  };
  const handlediscard = (index) => {
    // Implement saving edited data logic here
    setEditingIndex(null);

  };


  const handleInputChange = (value, key) => {
    setEditedData(prevData => ({
      ...prevData,
      [key]: value
    }));

  };

  const handleEditDateChange = (date1) => {
    const formattedDate = moment(date1, 'DD-MM-YYYY hh:mm A').utc().toISOString();

    setEditedData(prevData => ({
      ...prevData,
      'testDate': formattedDate,
      'createdDate': formattedDate,
      'modifiedDate': formattedDate
    }));
  };



  const handleDateChange = (date1) => {
    setDate(moment(date1).format('DD-MM-YYYY hh.mm A'))
  };

  const UTCDate = moment(date, 'DD-MM-YYYY hh:mm A').utc().toISOString();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const userID = localStorage.getItem('id');
        const response = await fetch(webservice + 'api/bmiValue', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userID: userID })
        });
        const data = await response.json();
        const dob = data.user.dob;

        // Format dob into a valid date string
        const dobParts = dob.split('-');
        const formattedDOB = dobParts[1] + '-' + dobParts[0] + '-' + dobParts[2];
        const birthDate = new Date(formattedDOB);

        // Calculate age from date of birth
        const today = new Date();

        let age = today.getFullYear() - birthDate.getFullYear();

        const monthDiff = today.getMonth() - birthDate.getMonth();

        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }

        // Set the age using setAge
        if (!isNaN(age)) {
          setAge(age);
        } else {
          // console.error('Invalid age:', age);
        }
      } catch (error) {
        // console.error('Error fetching data:', error);
      }
    };

    const intervalId = setInterval(fetchData, 1000); // Fetch data every 1 second
    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);



  const handlesystalicinfo = () => {
    let suggestion = '';
    const age1 = age;

    if (age1 >= 1 && age1 <= 12) {
      suggestion = 'Your systolic pressure should be between 75 mmHg to 110 mmHg.';
    } else if (age1 >= 13 && age1 <= 60) {
      suggestion = 'Your systolic pressure should be between 90mmHg to 115 mmHg.';
    } else if (age1 >= 61 && age1 <= 64) {
      suggestion = 'Your systolic pressure should be between 121mmHg to 147 mmHg.';
    } else {
      suggestion = 'Systolic range for this age group is not available.';
    }
    setSystalicTooltipContent(suggestion);
  };

  const handlediastolicinfo = () => {
    let suggestion = '';
    const age1 = age;

    if (age1 >= 1 && age1 <= 12) {
      suggestion = 'Your diastolic pressure should be between 50 mmHg to 75 mmHg.';
    } else if (age1 >= 13 && age1 <= 60) {
      suggestion = 'Your diastolic pressure should be between 60 mmHg to 80 mmHg.';
    } else if (age1 >= 61 && age1 <= 64) {
      suggestion = 'Your diastolic pressure should be between 83 mmHg to 91 mmHg.';
    } else {
      suggestion = 'Diastolic range for this age group is not available.';
    }
    setDiastolicTooltipContent(suggestion);
  };

  const handlePulseInfo = () => {
    let suggestion = '';
    const age1 = age;

    if (age1 >= 1 && age1 <= 12) {
      suggestion = 'Your pulse rate should be between 70 to 110 beats per minute.';
    } else if (age1 >= 13 && age1 <= 60) {
      suggestion = 'Your pulse rate should be between 60 to 100 beats per minute.';
    } else if (age1 >= 61 && age1 <= 64) {
      suggestion = 'Your pulse rate should be between 58 to 100 beats per minute.';
    } else {
      suggestion = 'Pulse rate range for this age group is not available.';
    }

    setPulseTooltipContent(suggestion);
  };


  const handleSystolicChange = (value) => {
    if (value === '') {
      setSystolicValue('')
    }

    if (isNaN(parseFloat(value))) {
      // Handle the case when the input is not a valid number
      setErrors({ systolic: t('') });
    } else {
      const systolicValue = parseFloat(value);

      if (value.trim() === '') {
        // If the input is empty, set an error
        setErrors({ systolic: t('Systolic Pressure is required') });
      } else {
        // Clear any previous error if the input is valid
        setErrors({ systolic: '' });

        if (systolicValue < 0) {
          // Negative values are not valid for systolic pressure
          setErrors({ systolic: t('Systolic Pressure cannot be negative') });
        } else if (systolicValue < 90) {
          // Values below 90 are considered too low and invalid
          setSystolicValue(t('Systolic Pressure is too low'));
        } else if (systolicValue < 120) {
          // Normal blood pressure range
          setSystolicValue(t('Systolic Pressure is Normal'));
        } else if (systolicValue >= 120 && systolicValue < 130) {
          // Elevated blood pressure
          setSystolicValue(t('Systolic Pressure is Elevated'));
        } else if (systolicValue >= 130 && systolicValue < 140) {
          // Hypertension Stage 1
          setSystolicValue(t('Hypertension Stage 1'));
        } else if (systolicValue >= 140 && systolicValue < 180) {
          // Hypertension Stage 2
          setSystolicValue(t('Hypertension Stage 2'));
        } else {
          // Hypertensive Crisis
          setSystolicValue(t('Hypertensive Crisis, Consult your doctor immediately'));
        }
      }
    }


  };


  const handleDiastolicChange = (value) => {
    if (value === '') {
      setDiastolicValue('');  // Clear the value if input is empty
      return;
    }

    // Check if the entered value is a valid number
    if (isNaN(parseFloat(value))) {
      setErrors(prevErrors => ({
        ...prevErrors,
        diastolic: t('Invalid input, please enter a number')
      }));
    } else {
      const diastolicValue = parseFloat(value);

      if (diastolicValue < 0) {
        // Prevent negative values
        setErrors(prevErrors => ({
          ...prevErrors,
          diastolic: t('Diastolic Pressure cannot be negative')
        }));
      } else {
        // Clear any previous errors if input is valid
        setErrors(prevErrors => ({
          ...prevErrors,
          diastolic: ''
        }));

        // Validate against diastolic blood pressure categories
        if (diastolicValue <= 49) {
          setDiastolicValue(t('Diastolic Pressure is too low'));
        } else if (diastolicValue >= 50 && diastolicValue <= 69) {
          setDiastolicValue(t('Low diastolic value'));
        } else if (diastolicValue >= 70 && diastolicValue <= 80) {
          setDiastolicValue(t('Diastolic Pressure is Normal'));
        } else if (diastolicValue > 80 && diastolicValue <= 90) {
          setDiastolicValue(t('Hypertension Stage 1'));
        } else if (diastolicValue > 90 && diastolicValue <= 120) {
          setDiastolicValue(t('Hypertension Stage 2'));
        } else {
          setDiastolicValue(t('Hypertensive Crisis, Consult your doctor immediately'));
        }
      }
    }
  };



  const handlePulseChange = (value) => {
    if (value === '') {
      setPulseCategory('');
      return; // Return early if the input is empty
    }

    // Check if the entered value is a valid number
    if (isNaN(parseFloat(value))) {
      setErrors(prevErrors => ({
        ...prevErrors,
        pulse: t('')
      }));
      return; // Return early if the input is invalid
    }

    const pulseValue = parseFloat(value);

    // Clear any previous errors if the input is valid
    setErrors(prevErrors => ({
      ...prevErrors,
      pulse: ''
    }));

    if (pulseValue < 0) {
      setErrors(prevErrors => ({
        ...prevErrors,
        pulse: t('Pulse cannot be negative')
      }));
    } else if (pulseValue <= 59) {
      setPulseCategory('low');
    }
    else if (pulseValue >= 60 && pulseValue <= 100) {
      setPulseCategory('normal');
      // setPulseMessage(t('Pulse is Normal')); // Optional: if you want to display a message
    } else if (pulseValue > 100 && pulseValue <= 120) {
      setPulseCategory('elevated');
      // setPulseMessage(t('Pulse is Elevated')); // Optional: if you want to display a message
    } else if (pulseValue > 120 && pulseValue <= 205) {
      setPulseCategory('hypertensive');
      // setPulseMessage(t('Hypertensive Range')); // Optional: if you want to display a message
    } else {
      setPulseCategory('Pulse value out of expected range');
      setErrors(prevErrors => ({
        ...prevErrors,
        pulse: t('')
      }));
    }
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for empty fields
    const errors = {};

    if (!systolic.trim()) {
      errors.systolic = t('Systolic Pressure is required');
    }
    if (!diastolic.trim()) {
      errors.diastolic = t('Diastolic Pressure is required');
    }
    if (!pulse.trim()) {
      errors.pulse = t('Pulse is required');
    }
    if (!date || date === '') {
      errors.testDate = t('Date is required');
    }
    const uid = uuidv4();
    const collection = 'Medical_BP';
    const lastSyncDate = '';

    if (Object.keys(errors).length === 0) {
      // Create the insert object with necessary fields
      const dataToInsert = [{
        systolic,
        diastolic,
        pulse,
        testDate: UTCDate,       // The UTC formatted test date
        userId,
        createdDate: UTCDate,      // UTC date for creation time
        modifiedDate: UTCDate,     // UTC date for modification time
        uid
      }];

      const dataToDelete = [];

      // Prepare the final data object for the API call
      const data = {
        dataToInsert,
        lastSyncDate,
        collection,
        dataToDelete,
        userId
      };

      // Dispatch the action and await its completion
      dispatch(AddBloodPressureData(data))
        .then((response) => {
          const Encparam = AesDecrypt(response.payload.data);
          setAllData(Encparam.dataToInsert)

          // handleReload(); // Mov.ed this inside the `then` block
        })
      // .catch((error) => {
      //   console.error("Error posting blood pressure data:", error);
      // });

      // Reset form and close modal
      setBasicModal(false);
      setSystolicValue('');
      setDiastolicValue('');
      setPulseCategory('');
      setSystolic('');
      setDiastolic('');
      setPulse('');
      setDate(new Date());
    } else {
      setErrors(errors);
    }
  };


  const handleClose = async (e) => {
    setBasicModal(false);
    setSystolicValue('')
    setDiastolicValue('')
    setPulseCategory('')
    setSystolic('')
    setDiastolic('');
    setPulse('');
    setErrors('')
    setDate('');
  }


  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      ref={ref}
      value={value}
      onClick={onClick}
      readOnly
      className="input-field"
    />
  ));


  const renderData = () => {
    if (!Array.isArray(allData) || allData.length === 0) {
      return (
        <div className="d-flex justify-content-center">
          <img src={img} alt="No Data" style={{ position: "relative", top: "50px", height: "400px" }} />
        </div>
      );
    }
    const lastSyncDate = allData[0].lastSyncDate;
    const _id = allData[0]._id;



    return (
      <div className="row" >

        {allData
          .slice()
          // .sort((a, b) => new Date(b.lastSyncDate) - new Date(a.lastSyncDate))
          .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
          .map((data, index) => (
            <div key={index} className="mood-responsive-card">
              <div className="card-custom card" style={{ borderRadius: "10px" }}>
                <div className="card-body">
                  <h5 style={{ display: "flex", margin: "auto 0", justifyContent: "space-between", alignItems: "center" }}>
                    <p style={{ fontSize: "15px", fontWeight: "600", margin: "auto 0" }}>
                      <span>
                        {moment(data.testDate).format('MMM DD')},{' '}
                      </span>
                      <span style={{ color: '#888' }}>
                        {moment(data.testDate).format('hh:mm A')}
                      </span>
                    </p>
                    {editingIndex === index ? (
                      <>
                        <Badge color="success" className="badgeblood badge-success" onClick={() => handleSave(index)}>
                          {t('Save')}
                        </Badge>
                        <Badge color="danger" className="badgeblood badge-danger" onClick={() => handlediscard(index)}>
                          {t('Discard')}
                        </Badge>
                      </>
                    ) : (
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "auto 0" }}>
                        <div>
                          <p className="top_headingbp"
                            style={{
                              // #ff8d24
                              backgroundColor: data.systolic >= 130 || data.diastolic >= 80
                                ? "#ff8d24"
                                : (data.systolic < 90 || data.diastolic <= 60)
                                  ? "#ccc"
                                  : "#306eff",

                              color: data.systolic >= 130 || data.diastolic >= 80
                                ? "white"
                                : (data.systolic < 90 || data.diastolic <= 60)
                                  ? "#000"
                                  : "#fff",
                            }}
                          >
                            {(data.systolic < 90 || data.diastolic <= 60)
                              ? "low blood pressure"
                              : (data.systolic >= 130 || data.diastolic >= 80)
                                ? "high blood pressure"
                                : "normal blood pressure"}

                          </p>
                        </div>
                        <div onClick={() => handleDelete(data._id, data.uid, data.createdDate)}>
                          <i className="fa fa-trash" style={{ color: "red" }}></i>
                        </div>
                      </div>
                    )}
                  </h5>






                  <div style={{ width: "100%", height: "1px", background: "#ccc", marginTop: "10px" }}></div>
                  <div style={{ marginTop: "15px" }}>
                    <div className="systalic">
                      <div className="heading_systalic">Systolic</div>
                      <div
                        className="data_systalic"

                      >
                        <h4
                          className="systalicdata"
                          style={{
                            color: "#000"
                          }}
                        >
                          {data.systolic}
                        </h4>
                        <p
                          style={{
                            color: data.systolic < 70
                              ? '#fc7200'
                              : data.systolic < 120
                                ? 'green'
                                : data.systolic < 130
                                  ? '#fc7200'
                                  : 'red',
                            backgroundColor: data.systolic < 70
                              ? '#f8e8ca'
                              : data.systolic < 120
                                ? '#c4fce4'
                                : data.systolic < 130
                                  ? '#f8e8ca'
                                  : '#fed9d9',
                          }}
                        >
                          {data.systolic < 70 ? 'low' : data.systolic < 120 ? 'normal' : data.systolic < 130 ? 'Elevated' : data.systolic <= 140 ? 'Stage 1' : data.systolic <= 180 ? 'Stage 2' : 'Consult doctor'}
                        </p>
                      </div>
                    </div>

                    <div className="systalic">
                      <div className="heading_systalic">Diastolic</div>
                      <div
                        className="data_systalic"
                        style={{
                          color: "#000"

                        }}
                      >
                        <h4
                          className="systalicdata"
                          style={{
                            color: "#000"
                          }}
                        >
                          {data.diastolic}
                        </h4>
                        <p
                          style={{
                            color: data.diastolic < 70
                              ? '#fc7200'
                              : data.diastolic < 80
                                ? 'green'
                                : 'red',
                            backgroundColor: data.diastolic < 70
                              ? '#f8e8ca'
                              : data.diastolic < 80
                                ? '#c4fce4'
                                : '#fed9d9',
                          }}
                        >
                          {data.diastolic < 70 ? 'low' : data.diastolic < 80 ? 'normal' : data.diastolic <= 90 ? 'Stage 1' : data.diastolic <= 120 ? 'Stage 2' : 'Consult doctor'}
                        </p>
                      </div>
                    </div>

                    <div className="systalic">
                      <div className="heading_systalic">Pulse</div>
                      <div
                        className="data_systalic"
                        style={{
                          color: "#000",

                        }}
                      >
                        <h4
                          className="systalicdata"
                          style={{
                            // color: data.pulse < 90 ? 'orange' : data.pulse < 110 ? 'green' : 'red',
                            color: "#000"
                          }}
                        >
                          {data.pulse}
                        </h4>
                        <p
                          style={{
                            color: data.pulse < 60
                              ? '#fc7200'
                              : data.pulse <= 100
                                ? 'green'
                                : data.pulse <= 120
                                  ? '#fc7200'
                                  : 'red',
                            backgroundColor: data.pulse < 60
                              ? '#f8e8ca'
                              : data.pulse <= 100
                                ? '#c4fce4'
                                : data.pulse <= 120
                                  ? "#f8e8ca"
                                  : '#fed9d9',
                          }}
                        >
                          {data.pulse < 60 ? 'low' : data.pulse <= 100 ? 'normal' : data.pulse <= 120 ? 'elevated' : 'Consult doctor'}
                        </p>
                      </div>
                    </div>
                  </div>




                </div>

              </div>
            </div>

          ))}
      </div>
    );
  };

  return (
    <>
      <div className="main_table" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div >
          <h1 className="container_heading">Blood Pressure</h1>
        </div>
        <div >
          <IoMdAddCircle onClick={() => setBasicModal(true)} style={{ fontSize: '40px', color: 'green' }} />
        </div>
      </div>
      <Fragment>
        {/* <Row> */}
        {/* <CardBody> */}
        <div style={{ position: 'relative', padding: "2%" }}>
          {renderData()}
        </div>


        {/* </CardBody> */}
        {/* <Card.Body> */}
        <div className="bootstrap-modal">
          <Modal className="fade " show={basicModal} onHide={() => setBasicModal(false)} backdrop="static">
            <Modal.Header>
              <Card.Title className="text-black">{t('Enter Blood Pressure Details Here')}</Card.Title>
            </Modal.Header>
            <Modal.Body>
              <div className={`form-group ${errors.systolic ? 'has-error' : ''} text-black`}>
                <label>{t('Systolic Pressure (mmHg) :')} <span style={{ color: "red" }}>*</span>
                </label>
                <div style={{ position: 'relative' }}>

                  <Input
                    type="text"
                    // className={`form-control ${errors.systolic ? 'is-invalid' : ''} text-black`}
                    className={`text-black`}
                    value={systolic}
                    placeholder="Enter Systolic Pressure"
                    onChange={(e) => {
                      if (/^\d{0,3}$/.test(e.target.value)) {
                        setSystolic(e.target.value);
                        handleSystolicChange(e.target.value);
                      }
                    }}
                    required
                  />
                  <span
                    style={{ position: 'absolute', cursor: 'pointer', right: 10, top: 18 }}
                    onMouseEnter={handlesystalicinfo}
                  >
                    <i className="fa fa-info-circle" id="systolicInfoIcon">
                      <UncontrolledTooltip target="systolicInfoIcon">{systalictooltipContent}</UncontrolledTooltip>
                    </i>

                  </span>
                </div>
                <div className="Bpsuger_lable">
                  <label style={{ color: systolicValue.toLowerCase().includes('normal') ? 'green' : systolicValue.toLowerCase().includes('elevated') ? '#c39e19' : 'red' }}>{systolicValue}</label>
                </div>
                {errors.systolic && <div style={{ color: 'red', marginTop: '5px' }}>{errors.systolic}</div>}
              </div>

              <div className={`form-group${errors.diastolic ? ' has-error' : ''} text-black`}>
                <label>{t('Diastolic Pressure (mmHg) :')} <span style={{ color: "red" }}>*</span>

                </label>
                <div style={{ position: 'relative' }}>

                  <Input
                    type="text"
                    // className={`form-control ${errors.systolic ? 'is-invalid' : ''} ${systolicCategory === 'normal' ? 'normal' : systolicCategory === 'elevated' ? 'elevated' : systolicCategory === 'hypertensive' ? 'hypertensive' : ''} text-black`}
                    className={`text-black`}
                    value={diastolic}
                    placeholder="Enter Diastolic Pressure"
                    onChange={(e) => {
                      if (/^\d{0,3}$/.test(e.target.value)) {
                        setDiastolic(e.target.value);
                        handleDiastolicChange(e.target.value);
                      }
                    }}
                    required
                  />
                  <span
                    style={{ position: 'absolute', cursor: 'pointer', right: 10, top: 18 }}

                    onMouseEnter={handlediastolicinfo}
                  >
                    <div>
                      <i className="fa fa-info-circle" id="diastolicInfoIcon">
                        <UncontrolledTooltip target="diastolicInfoIcon">{diastolictooltipContent}</UncontrolledTooltip>
                      </i>
                    </div>
                  </span>
                </div>

                <div className="Bpsuger_lable">
                  <label style={{ color: diastolicValue.toLowerCase().includes('normal') ? 'green' : diastolicValue.toLowerCase().includes('elevated') ? '#c39e19' : 'red' }}>{diastolicValue}</label>
                </div>
                {errors.diastolic && (
                  <div style={{ color: 'red', marginTop: '5px' }}>
                    {errors.diastolic}
                  </div>
                )}
              </div>

              <div className={`form-group${errors.pulse ? ' has-error' : ''} text-black`}>
                <label>{t('Pulse :')} <span style={{ color: "red" }}>*</span>
                </label>
                <div style={{ position: 'relative' }}>

                  <Input
                    type="text"
                    className={`text-black`}
                    // className={`form-control${errors.pulse ? ' is-invalid' : ''} text-black`}
                    value={pulse}
                    placeholder="Enter Pulse Value"
                    onChange={(e) => {
                      if (/^\d{0,3}$/.test(e.target.value)) {
                        setPulse(e.target.value);
                        handlePulseChange(e.target.value);
                      }
                    }}
                    required
                  />
                  <span
                    style={{ position: 'absolute', cursor: 'pointer', right: 10, top: 18 }}
                    onMouseEnter={handlePulseInfo}
                  >
                    <div  >
                      < i className="fa fa-info-circle" id="pulseInfoIcon">
                        <UncontrolledTooltip target="pulseInfoIcon">{pulsetooltipContent}</UncontrolledTooltip>
                      </i>
                    </div>
                  </span>
                </div>

                <div className="Bpsuger_lable">
                  <label style={{ color: pulseCategory.toLowerCase() === 'normal' ? 'green' : pulseCategory.toLowerCase() === 'elevated' ? '#c39e19' : 'red', }}>{pulseCategory}</label>
                </div>
                {errors.pulse && (
                  <div style={{ color: 'red', marginTop: '5px' }}>
                    {errors.pulse}
                  </div>
                )}
              </div>

              <div className={`form-group${errors.date ? ' has-error' : ''}`}>
                <div>
                  <label className="text-black">{t('Date :')}</label>

                </div>
                <DatePicker
                  selected={date ? moment(date, 'DD-MM-YYYY hh:mm A').toDate() : new Date()}
                  onChange={(date) => handleDateChange(date)}
                  showTimeSelect
                  timeFormat="hh:mm a"
                  timeIntervals={15} // Adjust the interval as needed
                  dateFormat="dd-MM-yyyy hh:mm a"
                  maxDate={new Date()}
                  customInput={<CustomInput />}
                  className={`form-control${errors.date ? ' is-invalid' : ''} text-black`}
                />

                {errors.date &&
                  <div style={{ color: 'red', marginTop: '5px' }}>
                    {errors.date}</div>}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Row>
                <Col>
                  <Button variant="primary" onClick={handleSubmit} className='text-center mt-2 pt-50' style={{ width: "100%" }}>
                    {t('Save')}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  {/* <Button onClick={() => setBasicModal(false)} variant="danger" className='text-center mt-2 pt-50'> */}
                  <Button onClick={handleClose} variant="danger" className='text-center mt-2 pt-50'>
                    {t('Close')}

                  </Button>
                </Col>
              </Row>

            </Modal.Footer>
          </Modal>
        </div>

      </Fragment>
    </>
  );
};


export default BloodPressureTable;