import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Link, useNavigate } from 'react-router-dom';
import doctor from './Demographic-images/profession.png';
import { useDispatch } from 'react-redux'; // Import useDispatch hook
import { Fulldetails } from '../../../store/actions/AuthActions';
import webservice from '../../../services/webservice'
import { useTranslation } from 'react-i18next'
import { createAsyncThunk } from '@reduxjs/toolkit';


export const getProfession = createAsyncThunk('AppMenu/getProfession', async () => {
  try {
    const response = await fetch(webservice + 'api/professions', {
      method: 'POST', // Change method to GET
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    });
    const responseData = await response.json();

    return responseData;
  } catch (error) {
    console.error('Error:', error);
    throw error; // Rethrow the error to be handled by Redux Toolkit
  }
});

const ProfessionalPage = ({ handleNextClick, value, setvalue }) => {

  const [selectedProfession, setSelectedProfession] = useState('');
  const [otherProfession, setOtherProfession] = useState('');
  const { t } = useTranslation();
  const [mappedJob, setmappedJobs] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');


  const handleProfessionChange = (selectedOption) => {
    setSelectedProfession(selectedOption.value);
    setErrorMessage('');
  };


  if (selectedProfession === 'Others') {
    localStorage.setItem('profession', selectedProfession);
  }

  const handleOtherProfessionChange = (e) => {
    setOtherProfession(e.target.value);
    localStorage.setItem('profession', e.target.value);
  };


  useEffect(() => {
    dispatch(getProfession()).then((res) => {
      const job = res.payload.data
      const mappedJobs = job.map(temp => ({ label: temp.job, value: temp.job }));
      setmappedJobs(mappedJobs)
    })
  }, [dispatch]);



  localStorage.setItem('profession', selectedProfession)


  const handleSubmit = () => {
    if (selectedProfession !== '') {
      if (selectedProfession === 'Others' && !otherProfession) {
        setErrorMessage(t('Please enter your profession'));
      } else {
        const name = localStorage.getItem('name');
        const dateString = localStorage.getItem('dob');
        const gender = localStorage.getItem('gender');
        const foodType = localStorage.getItem('foodType');
        const maritalStatus = localStorage.getItem('maritial');
        const bloodGroup = localStorage.getItem('bloodgroup');
        const height = localStorage.getItem('height');
        const weight = localStorage.getItem('weight');
        const profession = selectedProfession === 'Others' ? otherProfession : selectedProfession; // Use otherProfession if "Others" is selected
        const email = localStorage.getItem('email');
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        const mobile = userDetails.mobile;
        const userId = userDetails._id;
        const parts = dateString.split('-');
        const dob = `${parts[2]}-${parts[1]}-${parts[0]}`;



        if (name && dob && gender && maritalStatus && bloodGroup && height && weight && profession && email && mobile && userId) {
          dispatch(Fulldetails(name, dob, gender, foodType, maritalStatus, bloodGroup, profession, height, weight, email, mobile, userId, navigate));
        } else {
          setErrorMessage('Please fill out all the necessary details.');
        }
      }
    } else {
      setErrorMessage(t('Please select your profession'));
    }
  };


  useEffect(() => {
    if (value === 10) {
      handleSubmit();

    }
  });

  return (
    <>
      <div className='D_container'>
        <div className='D_column D_img_container'>
          <br></br>
          <br></br>
          <img className='Demograhy_image profession-image' src={doctor} />
        </div>
        <div className='D_column D_contant_container demography_card'>
          <div id='profession_Demography_card'>
            <h2 style={{ color: "black" }} >{t('Select your profession')}</h2>
            <Select
              className='profession-dropdown'
              value={mappedJob.find(option => option.value === selectedProfession)}
              onChange={handleProfessionChange}
              options={mappedJob}
              placeholder={t("Select profession...")}
            />
            &nbsp;
            {selectedProfession === 'Others' && (
              <input
                type="text"
                value={otherProfession}
                onChange={handleOtherProfessionChange}
                placeholder="Enter your profession"
              />
            )}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <br />
          </div>
        </div>

      </div>
    </>

  );
};

export default ProfessionalPage;
