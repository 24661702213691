import webservice from '../../../../services/webservice';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AesEncrypt } from '../../../../services/crypto';


// Get Recent Moods
export const RecentMood = createAsyncThunk('Mood/RecentMood', async (data) => {
    const Encparams = AesEncrypt(data)

    const response = await fetch(webservice + 'api/mentalhealthpushData', {
        method: 'POST',
        body: JSON.stringify({ Encparams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
    const moodData = await response.json();
    return moodData;
})



// Add Mood Monitor
export const AddMood = createAsyncThunk('Mood/AddMood', async (data) => {


    const Encparams = AesEncrypt(data)
    const response = await fetch(webservice + 'api/mentalhealthpushData', {
        method: 'POST',
        body: JSON.stringify({ Encparams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
    const moodData = await response.json();
    return moodData;
})



export const getStressData = createAsyncThunk('AppMenu/getProfileData', async (data) => {

    const requestData = {
        userId: data, // Pass the userId in the request body
    };
    const Encparams = AesEncrypt((requestData));
    try {
        const response = await fetch(webservice + 'api/getStressData', {
            method: 'POST',
            body: JSON.stringify({ Encparams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        })
        const responseData = await response.json();

        return responseData;


    } catch (err) {
        console.error(err);
        throw err;
    }
})


export const getDepressionData = createAsyncThunk('AppMenu/getProfileData', async (data) => {

    const requestData = {
        userId: data, // Pass the userId in the request body
    };
    const Encparams = AesEncrypt((requestData));
    try {
        const response = await fetch(webservice + 'api/getDepressionData', {
            method: 'POST',
            body: JSON.stringify({ Encparams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        })
        const responseData = await response.json();
        return responseData;
    } catch (err) {
        console.error(err);
        throw err;
    }
})
export const getAnxietyData = createAsyncThunk('AppMenu/getProfileData', async (data) => {

    const requestData = {
        userId: data, // Pass the userId in the request body
    };
    const Encparams = AesEncrypt((requestData));
    try {
        const response = await fetch(webservice + 'api/getAnxietyData', {
            method: 'POST',
            body: JSON.stringify({ Encparams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        })
        const responseData = await response.json();
        return responseData;
    } catch (err) {
        console.error(err);
        throw err;
    }
})


export const AddDepression = createAsyncThunk('Mood/AddMood', async (data) => {

    const Encparams = AesEncrypt(data)
    const response = await fetch(webservice + 'api/mentalhealthpushData', {
        method: 'POST',
        body: JSON.stringify({ Encparams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
    const moodData = await response.json();
    return moodData;
})



///dep history

export const getUserDataWithHistory = async (userId) => {

    // Prepare the request data
    const requestData = {
        userId: userId, // Pass the userId in the request body
    };

    // Encrypt the request data
    const Encparams = AesEncrypt(requestData);

    try {
        // Send the POST request with the encrypted data
        const response = await fetch(webservice + 'api/getUserDataWithHistory', {
            method: 'POST',
            body: JSON.stringify({ Encparams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        // Check if the response is ok
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        // Parse the JSON response
        const responseData = await response.json();

        // Return the complete user data along with history
        return responseData;
    } catch (err) {
        console.error("Error fetching user data with history:", err);
        throw err;
    }
};

export const AddAnxiety = createAsyncThunk('Mood/AddMood', async (data) => {

    const Encparams = AesEncrypt(data)
    const response = await fetch(webservice + 'api/mentalhealthpushData', {
        method: 'POST',
        body: JSON.stringify({ Encparams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
    const moodData = await response.json();
    return moodData;
})

export const AddStress = createAsyncThunk('Mood/AddMood', async (data) => {

    const Encparams = AesEncrypt(data)
    const response = await fetch(webservice + 'api/mentalhealthpushData', {
        method: 'POST',
        body: JSON.stringify({ Encparams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
    const moodData = await response.json();
    return moodData;
})

export const TrackUserMood = createAsyncThunk('Mood/AddMood', async (data) => {

    const Encparams = AesEncrypt(data)

    const response = await fetch(webservice + 'api/checkMoodTracking', {
        method: 'POST',
        body: JSON.stringify({ userId: Encparams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
    const TrackedMoodData = await response.json();

    return TrackedMoodData;
})


const userID = localStorage.getItem('id')

// DeleteMood Mood Monitor
export const DeleteMood = createAsyncThunk('Mood/DeleteMood', async (data) => {


    const Encparams = AesEncrypt(data); // Assuming _id is the key for the ID
    const response = await fetch(webservice + 'api/mentalhealthpushData', {
        method: 'POST',
        body: JSON.stringify({ Encparams }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    });
    const moodData = await response.json();
    return moodData;
});



export const MoodMonitorSlice = createSlice({
    name: 'mood_monitor',
    initialState: {
        data: [],
        total: 0,
        params: {},
        allData: []
    },
    reducers: {


    },
    extraReducers: builder => {
        builder.addCase(AddMood.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.allData = action.payload.allData
            state.total = action.payload.totalPages
            state.params = action.payload.params
            state.no_Data = action.payload.no_Data
        })
    }
})


