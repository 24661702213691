import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getRequestedDonor, updateCancelAndRemove } from "./store";
import { AesDecrypt } from "../../../services/crypto";
import { useDispatch } from "react-redux";
import { Button, Card, Label } from "reactstrap";
import { t } from "i18next";
import BreadCrumbs from "../../breadcrumbs";
import Swal from "sweetalert2";
const userId = localStorage.id


const RequestDonner = () => {
    const { id } = useParams();
    const [bloodRequestedUsers, setBloodRequestedUsers] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
            dispatch(getRequestedDonor(id)).then((response) => {
                if (response.payload.response === 'S') {
                    const decryptedData = AesDecrypt(response.payload.data);
                    setBloodRequestedUsers(decryptedData);

                }

            });
        }
    }, [dispatch, id]); // Added receiverId as a dependency

    const handleRemoveClick = (receiver) => {
        Swal.fire({
            title: 'Do you really want to delete this ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                const postData = {
                    requestId: receiver.requestId,
                    requestStatus: false,
                    status: receiver.status
                };

                dispatch(updateCancelAndRemove(postData)).then((response) => {
                    if (response.payload.response === 'S') {
                        dispatch(getRequestedDonor(id)).then((response) => {
                            const decryptedData = AesDecrypt(response.payload.data);
                            setBloodRequestedUsers(decryptedData);
                        });
                        Swal.fire(
                            'Deleted!',
                            'The data has been deleted.',
                            'success'
                        );
                    }
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'The data is safe :)',
                    'error'
                );
            }
        });
    };

    return (
        <>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Link to="/createpost">
                        <i style={{ color: '#007bff', fontSize: '32px', marginBottom: '2%' }} className="fas fa-arrow-alt-circle-left"></i>
                    </Link>
                    <div style={{ marginLeft: 'auto' }}>
                        <Link to={{ pathname: `/finddonor/${id}` }}>
                            <Button style={{ backgroundColor: '#99c990', borderColor: '#99c990' }} variant="danger">
                                {t("New Request")}
                            </Button>
                        </Link>
                    </div>
                </div>
                <div>
                    {Array.isArray(bloodRequestedUsers) && bloodRequestedUsers.length === 0 ? (
                        null
                    ) : (
                        <h1 className="text-align-center">
                            You requested these donors.
                        </h1>)}
                    {/* <BreadCrumbs title={t('You requested these donors')} data={[{ title: t('Blood Request') }]} /> */}
                </div>
            </div>

            <div className="card-container">
                {Array.isArray(bloodRequestedUsers) && bloodRequestedUsers.length === 0 ? (
                    <div style={{ textAlign: 'center', marginTop: '10%' }}>
                        <h3>
                            You don't even give request to a single donor.
                        </h3>
                        <Link to={{ pathname: `/finddonor/${id}` }}>
                            <Button className="new-request-button" variant="danger">
                                {t("Request Donor")}
                            </Button>
                        </Link>
                    </div>
                ) : (
                    Array.isArray(bloodRequestedUsers) && bloodRequestedUsers.map((receiver) => (
                        <Card key={receiver.requestId} className="blood-request-card">
                            <div className="blood-request-card-header">
                                <div className="blood-request-card-title">
                                    <strong>{receiver.name}</strong><br />
                                    {receiver.subDistrict}, {receiver.district}, {receiver.state}
                                </div>
                                <div style={{ backgroundColor: 'red', color: 'white', padding: '5px', borderRadius: '5px' }} className="blood-group">{receiver.bloodGroup}</div>
                            </div>
                            <div className="blood-request-card-body">
                                <div className="blood-request-card-mobile">
                                    <div className="blood-request-card-icon">📞</div>
                                    {receiver.mobile}
                                </div>
                            </div>


                            <div className="blood-request-card-footer">
                                {receiver.status === 'NT' ? (
                                    <div className="flex-container">
                                        <div className="content">
                                            It looks like you previously declined this request. Thank you for your attention.
                                        </div>
                                        <Button onClick={() => handleRemoveClick(receiver)} className="blood-request-completed-button">Remove</Button>
                                    </div>
                                ) : receiver.status === 'RE' ? (
                                    <div className="flex-container">
                                        <div className="content">
                                            We regret to inform you that we can't process your blood request currently. Thanks for your patience.
                                        </div>
                                        <Button onClick={() => handleRemoveClick(receiver)} className="blood-request-completed-button">Remove</Button>
                                    </div>
                                ) : (
                                    <div className="flex-container">
                                        <div className="content"></div>
                                        <Button onClick={() => handleRemoveClick(receiver)} className="blood-request-Cancel-button">Cancel</Button>
                                    </div>
                                )}
                            </div>


                        </Card>
                    ))
                )}
            </div>
        </>
    );
}

export default RequestDonner;
