import React, { Fragment, useState } from "react";
import "../../../css/custom.css";
import { Card, CardBody, CardTitle, Input, Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { AddMood } from "./store";
import { Link, useNavigate } from "react-router-dom";
import { AesDecrypt } from "../../../services/crypto";
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import love from '../../../images/emoji/Emoji - Heart Eyes.gif'
import angry from '../../../images/emoji/anger.gif'
import Fear from '../../../images/emoji/fear.gif'
import Sadness from '../../../images/emoji/sad.gif'
import Happy from '../../../images/emoji/happy.gif'
import Cheerful from '../../../images/emoji/cheerful.gif'
import Madness from '../../../images/emoji/Madness.gif'
import Calm from '../../../images/emoji/calm.gif'
import Negative from '../../../images/emoji/negative_mood.gif'
import Anxious from '../../../images/emoji/Anxious.gif'
import Irritability from '../../../images/emoji/irritability.gif'
import Disgust from '../../../images/emoji/disgust.gif'
import moment from "moment";
import { CardHeader } from "react-bootstrap";


const MoodMonitor = ({ onMoodSaved }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mood, setMood] = useState("");
  const [reason, setReason] = useState("");
  const [note, setNote] = useState("");
  const [activeSection, setActiveSection] = useState("mood");
  const { t } = useTranslation();
  const [selectedMood, setSelectedMood] = useState("");
  const [selectedReason, setSelectedReason] = useState("");

  const handleMoodSelection = (selectedMood) => {
    setMood(selectedMood);
    setSelectedMood(selectedMood);
    setTimeout(() => {
      setActiveSection("reason");
    }, 500); // Delay of 2 seconds
  };

  const handleReasonSelection = (selectedReason) => {
    setReason(selectedReason);
    setSelectedReason(selectedReason);
    setTimeout(() => {
      setActiveSection("note");
    }, 500); // Delay of 2 seconds
  };

  const [dateTime, setDateTime] = useState(new Date());

  const UTCDate = moment(dateTime, 'MM-DD-YYYY hh:mm a').utc().toISOString();

  const userId = localStorage.id;

  const handleSaveMood = () => {

    const uid = uuidv4();
    const collection = 'Mood';
    const lastSyncDate = '';
    const dataToInsert = [{
      mood,
      reason,
      note,
      userId,
      uid,
      createdDate: UTCDate,      // UTC date for creation time
      modifiedDate: UTCDate,     // UTC date for modification time
    }];

    const dataToDelete = [];

    const data = {
      dataToInsert,
      lastSyncDate,
      collection,
      dataToDelete,
      userId
    };

    dispatch(AddMood(data)).then((response) => {
      const decryptedMoodData = AesDecrypt(response.payload.data);
      if (response.payload.response === 'S') {
        navigate('/mood_monitor');
      }
    });
    setActiveSection("mood");
    setMood("");
    setReason("");
    setNote("");
    setSelectedMood("");
    setSelectedReason("");
  };

  const style = {
    width: '30%',
    height: '60%'
  }

  const moodOptions = [
    { value: "Anger", label: t("Anger"), emoji: <img src={angry} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Fear", label: t("Fear"), emoji: <img src={Fear} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Sadness", label: t("Sadness"), emoji: <img src={Sadness} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Happy", label: t("Happy"), emoji: <img src={Happy} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Cheerful", label: t("Cheerful"), emoji: <img src={Cheerful} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Romantic", label: t("Romantic"), emoji: <img src={love} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Madness", label: t("Madness"), emoji: <img src={Madness} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Calm", label: t("Calm"), emoji: <img src={Calm} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Negative", label: t("Negative"), emoji: <img src={Negative} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Anxious", label: t("Anxious"), emoji: <img src={Anxious} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Irritability", label: t("Irritability"), emoji: <img src={Irritability} alt="Heart Eyes Emoji" style={style} /> },
    { value: "Disgust", label: t("Disgust"), emoji: <img src={Disgust} alt="Heart Eyes Emoji" style={style} /> }
  ];

  const reasonOptions = [
    { value: "Family", label: t("Family") },
    { value: "Friend", label: t("Friend") },
    { value: "School", label: t("School") },
    { value: "College", label: t("College") },
    { value: "Education", label: t("Education") },
    { value: "Love", label: t("Love") },
    { value: "Care", label: t("Care") },
    { value: "Others", label: t("Others") },
  ];

  return (
    <Fragment>
      <div>
        <div className=''>
          <Link to={"/mood_monitor"}>
            <i style={{ color: '#007bff', fontSize: '32px' }} className="fas fa-arrow-alt-circle-left"></i>
          </Link>
        </div>
      </div>
      <br />
      <h2 className="data-title bloodpressure-title">
        {/* <strong>{t('Mood Monitor')}</strong> */}
        </h2>
      <Card>
        <CardHeader style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={() => setActiveSection("mood")}>
          <CardTitle className="text-black" >
            {t('Select your mood')}
          </CardTitle>
          <i
            className={`fas fa-chevron-${activeSection === "mood" ? "up" : "down"}`}
            style={{ cursor: 'pointer' }}
          ></i>
        </CardHeader>
        {activeSection === "mood" && (
          <CardBody>
            <div className="mood-grid">
              {moodOptions.map((option, index) => (
                <div key={index}
                  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'white'}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = selectedMood === option.value ? 'white' : 'white'}
                  className={`mood-item ${selectedMood === option.value ? "selected" : ""}`}
                >
                  <span
                    style={{ height: '100px' }}
                    className={selectedMood === option.value ? "selected" : ""}
                    onClick={() => handleMoodSelection(option.value)}
                  >
                    {option.emoji}
                  </span>
                  <br></br>
                  <p onClick={() => handleMoodSelection(option.value)}>{option.label}</p>
                </div>
              ))}
            </div>
          </CardBody>
        )}
      </Card>
      <Card>
        <CardHeader style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={() => selectedMood && setActiveSection("reason")}>
          <CardTitle className="text-black" >
            {t('Reason For Your Mood')}
          </CardTitle>
          <i
            className={`fas fa-chevron-${activeSection === "reason" ? "up" : "down"}`}
            style={{ cursor: 'pointer' }}
          ></i>
        </CardHeader>
        {activeSection === "reason" && selectedMood && (
          <CardBody>
            <div className="mood-grid-reason">
              {reasonOptions.map((option, index) => (
                <div key={index} className="mood-item">
                  <p
                    className={selectedReason === option.value ? "selected" : ""}
                    onClick={() => handleReasonSelection(option.value)}
                  >
                    {option.label}
                  </p>
                </div>
              ))}
            </div>
          </CardBody>
        )}
      </Card>

      <Card>
        <CardHeader style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={() => selectedMood && setActiveSection("note")}>
          <CardTitle className="text-black" >
            {t('Add A Note')}
          </CardTitle>
          <i
            className={`fas fa-chevron-${activeSection === "note" ? "up" : "down"}`}
            style={{ cursor: 'pointer' }}
          ></i>
        </CardHeader>
        {activeSection === "note" && selectedReason && (
          <CardBody>

            <div className="mood-input">
              <textarea
                style={{ height: '200px', padding: '10px' }}
                placeholder=""
                className="important-height"
                onChange={(e) => setNote(e.target.value)}
              />
              <Button
                style={{ marginTop: '20px' }}
                color="primary"
                className="small-button"
                onClick={handleSaveMood}
              >
                {t('Save')}
              </Button>
            </div>
          </CardBody>
        )}

      </Card>
    </Fragment>
  );
};

export default MoodMonitor;