import { getFirstAid, getlanguage } from "./store/index";
import { useDispatch } from "react-redux";
import React, { Fragment, useEffect, useState } from "react";
import { Tab, Card, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const FirstAIdDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [FirstAidData, setFirstaiddata] = useState({});

  // useEffect(() => {
  //   dispatch(getFirstAid(id)).then((response) => {
  //     setImageData(response.payload.data);
  //   });
  // }, [id, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const _id = localStorage.id;
      if (_id && _id !== "undefined") {
        const langResponse = await dispatch(getlanguage(_id));
        const lang = langResponse.payload.language;
        const data = { id, lang };
        const fruitsResponse = await dispatch(getFirstAid(data));


        if (fruitsResponse.payload.data) {
          setFirstaiddata(fruitsResponse.payload.data);
        } else {
          setFirstaiddata('')
        }
      }
    };

    fetchData();
  }, [dispatch, id]);



  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          background: 'transparent',
          right: '-100px',
          cursor: 'pointer',
          transition: 'transform 0.3s ease'
        }}
        onClick={onClick}
      // onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1)'}
      // onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
      >
        <i className="fas fa-chevron-right" style={{ fontSize: '30px', color: '#000' }}></i>
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          background: 'transparent',
          left: '-100px',
          cursor: 'pointer',
          transition: 'transform 0.3s ease'
        }}
        onClick={onClick}
      // onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1)'}
      // onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
      >
        <i className="fas fa-chevron-left" style={{ fontSize: '30px', color: '#000' }}></i>
      </div>
    );
  };




  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };


  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <Card className="card" style={{ boxShadow: "0px 0px 10px 10px rgb(144 144 144 / 56%)" }}>
            <Card.Header>
              <Col>
                <div className='icon_backButton'>
                  <Link to={"/first-aid"}>
                    <i className="fas fa-arrow-alt-circle-left"></i>
                  </Link>
                </div>
              </Col>
              <Col>
                <h2 className="text-black" style={{ marginLeft: "-10%" }}>{t(FirstAidData.parent_name)}</h2>
              </Col>
            </Card.Header>
            <Card.Body>
              <div className="row">
                <div className=" ">
                  <Tab.Container defaultActiveKey="first">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                          <img className="img-fluid" src={FirstAidData.img} alt="" style={{ maxWidth: '250px' }} />
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
                <div className="col" style={{ padding: '5%' }}>
                  <div className="text-black">
                    <div dangerouslySetInnerHTML={{ __html: FirstAidData.content }}></div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </Fragment>
  );
};

export default FirstAIdDetails;
