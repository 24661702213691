import React, { useEffect, useState } from 'react';
import MicrosoftTeamsImage from './Demographic-images/image.png';
import { useTranslation } from 'react-i18next'

const NamePage = ({ handleNextClick, value, setvalue }) => {
  const [name, setName] = useState('');
  const sessionName = localStorage.getItem('name'); // State to manage whether to show the next page or not
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  const handleNameChange = (e) => {
    const value = e.target.value;
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
    setName(capitalizedValue);
    setErrorMessage(''); // Clear error message when input changes
  }


  useEffect(() => {
    if (sessionName !== 'undefined') {
      setName(localStorage.getItem('name'))
      setErrorMessage('')
    }
  }, [sessionName])

  const handleSubmit = () => {
    if (name.trim() !== '') { // Check if name is not empty
      localStorage.setItem('name', name); // Store name in localStorage
      handleNextClick();  // Set showNextPage to true to navigate
    } else {
      setvalue(1)

      setErrorMessage(t('Please provide your name')); // Set error message
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(); // Call handleNextClick function when Enter key is pressed
    }
  }

  useEffect(() => {
    if (value === 2) {
      handleSubmit();

    }
  });
  // Move the navigation logic outside of the render method


  return (
    <>
      {/* <form> */}



      <div className='D_container'>
        <div className='D_column D_img_container'>

          <img style={{ height: "auto", width: "100%" }} id='username_image' className='Demograhy_image' src={MicrosoftTeamsImage} alt="Animated Image" />
        </div>
        <div className='D_column D_contant_container demography_card'>
          <div id='Demography_card'>
            {/* <div className='demography_main_contant'> */}
            <h4 className="name-page-title " style={{ color: "black" }} >{t("What is your name?")}</h4>

            <input onKeyDown={handleKeyDown} placeholder={t('Type Your name Here')} type="text" value={name} onChange={handleNameChange} className="name-input" />


            {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Display error message if present */}
            {/* {errorMessage && <p style={{ marginLeft: '3%', position: 'relative', bottom: '20px' }} className="error-message">{errorMessage}</p>} Display error message if present */}
            {/* </div> */}
          </div>
        </div>

      </div>
      {/* </form> */}

    </>
  );
}

export default NamePage;
