import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useDispatch } from "react-redux";
import { getbloodpresurechartData } from "./store";
import { AesDecrypt } from "../../../services/crypto";
import { Button } from "react-bootstrap";
import '../../../css/custom.css';
import { Link } from "react-router-dom";
import { t } from "i18next";
import moment from "moment";

const DualLine = () => {
  const dispatch = useDispatch();
  const [bloodPressureData, setBloodPressureData] = useState([]);
  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const _id = localStorage.id;
        const res = await dispatch(getbloodpresurechartData(_id));
        const tempdata = AesDecrypt(res.payload.data);

        if (Array.isArray(tempdata) && tempdata.length !== 0) {
          setBloodPressureData(tempdata);
          setShowChart(true);
        } else {
          setShowChart(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  const chartData = {
    labels: bloodPressureData.map((entry) => moment(entry.testDate).format('MMM-DD')),
    datasets: [
      {
        label: "Systolic",
        data: bloodPressureData.map((entry) => entry.systolic),
        borderColor: "rgba(235,129,83,1)",
        borderWidth: 2,
        backgroundColor: "transparent",
        pointBackgroundColor: "rgba(235,129,83,1)",
        tension: 0.4,
      },
      {
        label: "Diastolic",
        data: bloodPressureData.map((entry) => entry.diastolic),
        borderColor: "rgba(30, 227, 145)",
        borderWidth: 2,
        backgroundColor: "transparent",
        pointBackgroundColor: "rgba(30, 227, 145)",
        tension: 0.4,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          fontSize: 12,
        },
      },
      annotation: {
        annotations: [{
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y',
          value: 50,
          borderColor: 'rgb(75, 192, 192)',
          borderWidth: 2,
          label: {
            enabled: true,
            content: 'No data available',
            position: 'center',
          }
        }]
      },
      tooltips: {
        intersect: false,
      },
      hover: {
        intersect: true,
      },
    },
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          max: 100,
          min: 0,
          stepSize: 20,
          padding: 10,
        },
        display: true,
      },
      x: {
        ticks: {
          padding: 5,
        },
        display: true,
      },
    },
  };

  return (
    <>
      <Line data={chartData} options={options} height={150} />
      {!showChart && (
        <Link to={'/BloodPressure'}>
          <div className="BP_nodata_container">
            <div className="BP_contant_container">
              <h3 className="bp-header-font" >{t('No data available to display')}</h3>
              {/* <h5 className="bp-header-font" >{t('Click here to track your Bloodpressure')}</h5> */}
            </div>
          </div>
        </Link>
      )}
    </>
  );
};

export default DualLine;
