import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import logo from "../../../images/logo-login.png";
import { navtoggle } from "../../../store/actions/AuthActions";
import { useTranslation } from 'react-i18next';
import { AesDecrypt } from '../../../services/crypto';
import { userdataflag } from "./store";

const NavHeader = () => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const navigate = useNavigate(); // Use useNavigate hook for navigation
   const [isTitleVisible, setIsTitleVisible] = useState(true);
   const [userDataFlag, setUserDataFlag] = useState('');
   const [showModal, setShowModal] = useState(false);
   const [colapse, setColapse] = useState();


   let path = window.location.pathname.split("/").pop();

   useEffect(() => {
      const _id = localStorage.id;
      dispatch(userdataflag(_id))
         .then((res) => {
            if (res.payload.response === "S") {
               const data1 = AesDecrypt(res.payload.data);
               if (data1.length !== 0) {
                  setUserDataFlag(data1);
               }
            }
         })
         .catch((error) => {
            console.error('Error fetching profile:', error);
         });
   }, [dispatch]);

   const handleToggle = () => {
      const response = dispatch(navtoggle());
      setColapse(colapse => !colapse)
   };

   const handleBrandLogoClick = () => {
      // Navigate to the dashboard directly using navigate
      navigate('/');
   };

   const handleLinkClick = () => {
      if (userDataFlag === "A") {
         handleBrandLogoClick();
      } else {
         handleMenuClick();
      }
   };




   const handleMenuClick = () => {
      if (userDataFlag === 'I') {
         setShowModal(true);
      }

   };
   useEffect(() => {
      setUserDataFlag('I'); // For demonstration, you can set this dynamically based on your app's logic
   }, []);

   return (
      <>
         <div className="nav-header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 20px' }}>
            <Link
               to="/"
               className="brand-logo"
               style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
               onClick={handleLinkClick} // Use this function for the logo click
            >
               <img
                  className="logo-abbr"
                  src={logo}
                  alt=""
                  style={{ marginLeft: isTitleVisible ? '-10px' : '-30px', marginRight: isTitleVisible ? '0px' : '-10px' }}
               />
               {isTitleVisible && window.innerWidth > 1284 && (

                  colapse ? (null) : (<span
                     style={{
                        marginLeft: '-20px',
                        marginTop: '10px',
                        fontSize: '1.8rem',
                        fontWeight: '700',
                        color: '#000000',
                        fontFamily: "'Dancing Script', cursive"
                     }}
                  >
                     edHorizone
                  </span>)
               )}
            </Link>

            <div className="nav-control" onClick={handleToggle}>
               <div className='hamburger'>
                  <span className="line"></span>
                  <span className="line"></span>
                  <span className="line"></span>
               </div>
            </div>
         </div >

         <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
               <Modal.Title>{t("MedHorizone BOT:")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div style={{ marginLeft: '5%' }}>
                  {t("You need to complete your profile details to access this menu")}
               </div>
            </Modal.Body>
            <Modal.Footer>
               <Link to={'/Demography'}>
                  <div>
                     <Button style={{ width: '100%' }} onClick={() => setShowModal(false)}>Click Here</Button>
                  </div>
               </Link>
            </Modal.Footer>
         </Modal>
      </>
   );
};

export default NavHeader;
