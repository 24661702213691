import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next'

import Swal from 'sweetalert2';

import { Link } from "react-router-dom";
/// Scroll
import { useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";

/// Image
import Setting from "./setting";
// import Calender from'../../components/AppsMenu/Calendar/Calendar'
import { ThemeContext } from "../../../context/ThemeContext";
import IntlDropdown from "../langdropdown";
import { getfamilyData, switchuseraccount, getprofile, getdataFlag, changeActivestatus } from "./store";
import { AesDecrypt } from "../../../services/crypto";
import { Label } from "reactstrap";


const AvatarPaths = {
  A: "",
  B: "",
  C: "",
  D: "",
  E: "",
  F: "",
  G: "",
  H: "",
  I: "",
  J: "",
  K: "",
  L: "",
  M: "",
  N: "",
  O: "",
  P: "",
  Q: "",
  R: "",
  S: "",
  T: "",
  U: "",
  V: "",
  W: "",
  X: "",
  Y: "",
  Z: ""
};
const Header = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // const history = useHistory();
  const [Id, setId] = useState("");
  const [Alldata, setAllHead] = useState("");
  const [userFlag, setuserFlag] = useState("");

  const handleReloadTable = () => {
    dispatch(getfamilyData(Id)).then((res) => {
      const head = AesDecrypt(res.payload.data)
      setAllHead(head)
    })
  };

  useEffect(() => {
    const id = localStorage.parentid;
    setId(id);
    if (Id) {
      dispatch(getfamilyData(Id)).then((res) => {
        if (res.payload) {
          const head = AesDecrypt(res.payload.data)
          setAllHead(head)
        } else {

        }

      })
    }
  }, [dispatch, Id])

  const getFlag = () => {

    const Id = localStorage.id;
    dispatch(getprofile(Id))
      .then((res) => {
        if (res.payload.response === 'S') {
          const data1 = AesDecrypt(res.payload.data);
          if (Array.isArray(data1) && data1.length !== 0) {
            setAvatarImg(data1[0].image);
          }
        }
      })

    dispatch(getdataFlag(Id)).then((response) => {
      const userdataFlag = response.payload
      setuserFlag(userdataFlag)
    })
    handleReloadTable()
  }


  const switchuser = (switchid) => {
    const currentid = localStorage.id;
    const data = { currentid, switchid };
    Swal.fire({
      title: t("Confirm Switch Account"),
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes"),
      cancelButtonText: t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(changeActivestatus(data)).then((res) => {
          const demo = res;
        });
        dispatch(switchuseraccount(switchid)).then((response) => {
          const responseData = response.payload;
          const data = AesDecrypt(response.payload.encryptedData);
          if (responseData.status === "success") {
            localStorage.removeItem('id', 'name', 'dob', 'lang');
            localStorage.setItem('id', (data._id));
            localStorage.setItem('dob', (data.dob));
            localStorage.setItem('name', (data.name));
            if (data.parentId) {
              localStorage.setItem('isChild', true)
            } else {
              localStorage.setItem('isChild', false)
            }
            Swal.fire(
              t("Account Switch Successful"),
              "",
              "success"
            ).then(() => {
              window.history.replaceState(null, null, "/dashboard");

              // Navigate to the dashboard without adding a new history entry
              window.location.replace("/dashboard");

              // Reload the page to ensure the dashboard is fully loaded
              window.location.reload();
            });
          } else {
            Swal.fire(
              t("Failed to switch account"),
              "",
              "error"
            );
          }
        });
      }
    });
  };


  const [avatarImg, setAvatarImg] = useState(null);



  const renderAvatar = () => {
    if (!Alldata.img) {
      const firstLetter = localname ? localname.charAt(0).toUpperCase() : '';
      return firstLetter;
    }
    // If image data is available, return the image
    return `data:image/jpeg;base64,${Alldata.img}`;
  };

  useEffect(() => {
    const _id = localStorage.id;
    dispatch(getprofile(_id))
      .then((res) => {

        if (res.payload.response === 'S') {
          const data1 = AesDecrypt(res.payload.data);

          if (Array.isArray(data1) && data1.length !== 0) {
            setAvatarImg(data1[0].image);
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching profile:', error);
        // Handle error
      });
  }, [dispatch]);

  const { background, changeBackground } = useContext(ThemeContext);
  function ChangeMode() {
    if (background.value === "light") {
      changeBackground({ value: "dark", label: 'Dark' })
    } else {
      changeBackground({ value: "light", label: 'Light' })
    }
  }
  const localname = localStorage.name

  const [dropdownopen, setDropdownOpen] = useState (false)
  const handleMenuClick = () => {
    setDropdownOpen(false)
  }

  return (

    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
            </div>
            <ul className="header-right">
              {/* <ul className="navbar-nav header-right"> */}
              {/* <Setting /> */}
              {/* <IntlDropdown /> */}
              {localname && localname !== "undefined" && localname !== '' ? (
                <Dropdown as="li" className=" " show={dropdownopen} onToggle={() => setDropdownOpen(!dropdownopen)} onClick={() => getFlag(Id)}>
                  <Dropdown.Toggle
                    variant=""
                    className="nav-link i-false"
                    role="button"
                    data-toggle="dropdown"
                  >
                    {/* <Link to=""> */}
                    {avatarImg ? (
                      <img src={`data:image/jpeg;base64,${avatarImg}`} width={20} alt="Profile" />
                    ) : (
                      <div className="avatar-circleheader avatar-letter1">{renderAvatar()}</div>
                    )}


                  </Dropdown.Toggle>
                  {userFlag.userDataFlag === "A" && Alldata && Alldata.filter(data => data._id !== localStorage.id) && (
                    <Dropdown.Menu align="start" className="mt-2" onClick={handleMenuClick}>

                      <Link className="dropdown-item ai-icon" to={'/app-profile'}>
                        <svg
                          id="icon-user1"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-black"
                          width={18}
                          height={18}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                          <circle cx={12} cy={7} r={4} />
                        </svg>
                        <span className="ms-2 text-black">{'Your Profile'}</span>
                        {/* <Label className="ms-2 text-black" style={{textAlign:'center',justifyContent:"center"}}>Your Profile</Label> */}
                      </Link>

                      {Alldata.map((data, index) => (
                        data._id !== localStorage.id ? (
                          <>

                            <Link
                              key={index}
                              className="dropdown-item ai-icon"
                              onClick={() => switchuser(data._id)}
                            >
                              <svg
                                id="icon-user1"
                                xmlns="http://www.w3.org/2000/svg"
                                className="text-black"
                                width={18}
                                height={18}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                <circle cx={12} cy={7} r={4} />
                              </svg>
                              <span className="ms-2 text-black">{data.name}</span>
                            </Link>
                          </>

                        ) : null
                      ))}
                    </Dropdown.Menu>
                  )}
                </Dropdown>
              ) : null}
            </ul>
          </div>
        </nav>
      </div>
    </div>


  );
};

export default Header;
