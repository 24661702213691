import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { AesDecrypt } from "../../../services/crypto";
import { useDispatch } from "react-redux";
import { getstepChart } from "./store";

const VisitorActivity = ({ selectedMonth, width, height }) => {
  const [activityData, setActivityData] = useState({});
  const [monthsData, setMonthsData] = useState({});
  const [yearlyData, setYearlyData] = useState({});
  const _id = localStorage.id;
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch data based on selectedMonth
    const fetchData = async () => {
      try {
        const res = await dispatch(getstepChart(_id, selectedMonth));
        const tempdata = res.payload.data;
        if (tempdata && tempdata.length !== 0) {
          const data = AesDecrypt(tempdata);

          setActivityData(data);
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
        // Handle error
      }
    };

    fetchData();
  }, [dispatch, _id, selectedMonth]);

  useEffect(() => {
    // Calculate monthsData and yearlyData
    if (selectedMonth !== 0) {
      const newMonthsData = {};
      const newYearlyData = {};
      Object.entries(activityData).forEach(([date, count]) => {
        const [dd, mm, yyyy] = date.split("-");
        const monthYear = `${mm}-${yyyy}`;
        const year = `${yyyy}`;
        if (!newMonthsData[monthYear]) {
          newMonthsData[monthYear] = 0;
        }
        newMonthsData[monthYear] += parseInt(count);
        if (!newYearlyData[year]) {
          newYearlyData[year] = 0;
        }
        newYearlyData[year] += parseInt(count);
      });
      setMonthsData(newMonthsData);
      setYearlyData(newYearlyData);
    }
  }, [activityData, selectedMonth]);

  const generateLabels = () => {
    if (selectedMonth === 0) {
      // Generate labels for days of the current month
      const currentDate = new Date();
      const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
      return Array.from({ length: daysInMonth }, (_, i) => {
        const day = i + 1;
        return `${day}-${currentDate.toLocaleString('default', { month: 'short' })}`;
      });
    } else if (selectedMonth === 1) {
      // Generate labels for months
      return Object.keys(monthsData).map(monthYear => {
        const [mm, yyyy] = monthYear.split("-");
        const monthName = new Date(yyyy, mm - 1).toLocaleString('default', { month: 'short' });
        return `${monthName}-${yyyy}`;
      });
    } else if (selectedMonth === 2) {
      // Generate labels for years
      return Object.keys(yearlyData);
    }
  };


  const generateCurrentMonthData = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Get current month (1-12)
    const currentYear = currentDate.getFullYear();   // Get current year

    // Filter data for the current month
    return Array.from({ length: 31 }, (_, i) => {
      const day = i + 1;
      const formattedDay = day < 10 ? `0${day}` : `${day}`;
      const formattedMonth = currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`;
      const dateKey = `${formattedDay}-${formattedMonth}-${currentYear}`;

      return activityData[dateKey] ? parseInt(activityData[dateKey]) : 0;
    });
  };


  const data = {
    labels: generateLabels(),
    datasets: [
      {
        label: "Steps Count",
        data: selectedMonth === 0 ? generateCurrentMonthData() :
          selectedMonth === 1 ? Object.values(monthsData) :
            selectedMonth === 2 ? Object.values(yearlyData) : [],
        borderColor: "rgba(39,149,210, 1)",
        borderWidth: "0",
        backgroundColor: "rgba(39,149,210, 1)",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      }
    },
    // Your other options
  };

  return (
    <div style={{ minHeight: "290px", }}>
      <Bar
        data={data}
        width={width ? width : 433}
        height={height ? height : 251}
        options={options}
      />
    </div>
  );
};

export default VisitorActivity;
