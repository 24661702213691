// BMICalculator.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next'
function BMICalculator() {
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    const [bmi, setBMI] = useState(null);
    const [interpretation, setInterpretation] = useState('');
    const [showModal, setShowModal] = useState(false);
    const { t } = useTranslation();
    const [interpretationColor, setInterpretationColor] = useState('');
    const calculateBMI = () => {
        const heightMeters = height / 100;
        const calculatedBMI = (weight / (heightMeters * heightMeters)).toFixed(1);
        setBMI(calculatedBMI);

        let color;

        if (calculatedBMI < 18.5) {
            setInterpretation(t('Underweight'));
            color = '#c5bf10';
        } else if (calculatedBMI >= 18.5 && calculatedBMI < 25) {
            setInterpretation(t('Normal weight'));
            color = 'green';
        } else if (calculatedBMI >= 25 && calculatedBMI < 30) {
            setInterpretation(t('Overweight'));
            color = 'orange';
        } else {
            setInterpretation(t('Obese'));
            color = 'red';
        }
        setInterpretationColor(color);
        // Now 'color' holds the appropriate color based on BMI interpretation

    };

    const handleCalculateButtonClick = () => {
        calculateBMI();
    };

    const handleToggleCalculator = () => {
        setShowModal(!showModal);
    };

    const handleGoBack = () => {
        setShowModal(false);
    };

    return (
        <>
            <button onClick={handleToggleCalculator} className="calculator-toggle-button">{t('BMI Calculator')}</button>

            <Modal show={showModal} onHide={handleGoBack}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-black'>
                        {t('BMI Calculator')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="calculator-container">
                        <div className="calculator-content">
                            <label htmlFor="height" className="input-label text-black">{t('Height')} (cm):</label>
                            <input
                                type="number"
                                id="height"
                                value={height}
                                onChange={(e) => setHeight(e.target.value)}
                                placeholder={t("Enter Height")}
                                className="input-field"
                            />

                            &nbsp;

                            <label htmlFor="weight" className="input-label text-black">{t('Weight')} (kg):</label>
                            <input
                                type="number"
                                id="weight"
                                value={weight}
                                onChange={(e) => setWeight(e.target.value)}
                                placeholder={t("Enter Weight")}
                                className="input-field"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
                    {bmi && interpretation && (
                        <div className="result">
                            <h3 className="result-heading text-black">{t('Your BMI')} : <span style={{ color: interpretationColor, fontSize: '1.4rem' }}>{bmi}</span></h3>
                            <p className="interpretation text-black" >{t('Interpretation')}: <span style={{ color: interpretationColor, fontSize: '1.4rem' }}> {interpretation} </span> </p>
                        </div>
                    )}
                    <button onClick={handleCalculateButtonClick} className="calculate-button">{t('Calculate BMI')}</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default BMICalculator;
