import React, { Fragment, useState } from "react";
import "../../../css/custom.css";
import { Card, CardBody, Button, Form, FormGroup, Label, Input, Alert } from "reactstrap";
import { useDispatch } from "react-redux";
import { AddDepression } from "./store";
import { Link, useNavigate } from "react-router-dom";
import { AesDecrypt } from "../../../services/crypto";
import { useTranslation } from 'react-i18next';
import BreadCrumbs from "../../breadcrumbs";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from 'uuid';

const Depression = () => {
  const dispatch = useDispatch();
  const [depression, setDepression] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedChoice, setSelectedChoice] = useState("");
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const userId = localStorage.id;

  const handleChoiceSelection = (choice) => {
    setSelectedChoice(choice);
    setError("");
  };

  const handleNextQuestion = (e) => {
    e.preventDefault();
    if (!selectedChoice) {
      setError(t("Please select an option"));
      return;
    }
    const currentQuestion = depressionQuestions[currentQuestionIndex].id;
    setDepression((prevMood) => ({ ...prevMood, [currentQuestion]: selectedChoice }));
    setSelectedChoice(depression[depressionQuestions[currentQuestionIndex + 1]?.id] || "");
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handlePreviousQuestion = (e) => {
    e.preventDefault();
    setError(""); // Clear any existing error
    setSelectedChoice(depression[depressionQuestions[currentQuestionIndex - 1].id] || "");
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const calculateScore = (scoreData, type) => {
    if (!scoreData || typeof scoreData !== 'object') return 0;

    const scores = Object.values(scoreData).map(Number);
    let totalScore = scores.reduce((acc, score) => acc + score, 0);

    if (totalScore === 0) {
      totalScore = 5; // Consider 0 as 5
    }
    const adjustedScore = (totalScore * 100) / 27;
    return Math.round(Math.min(adjustedScore, 100)); // Return rounded score
  };



  const handleSaveMood = (e) => {
    e.preventDefault();

    if (!selectedChoice) {
      setError(t("Please select an option"));
      return;
    }

    // Capture current depression question and selected choice
    const currentQuestion = depressionQuestions[currentQuestionIndex].id;
    const DepressionData = { ...depression, [currentQuestion]: selectedChoice };

    // Calculate depression score
    const score = calculateScore(DepressionData);

    // Prepare dataToInsert according to the structure
    const uid = uuidv4(); // Generate unique ID
    const UTCDate = new Date().toISOString(); // UTC date format for creation/modification time
    const collection = 'Depression';
    const lastSyncDate = ''; // If no sync date is provided, leave it empty
    const dataToInsert = [{
      depression: DepressionData,
      score,
      // testDate: UTCDate,       // The UTC formatted test date
      userId,
      createdDate: UTCDate,    // UTC date for creation time
      // modifiedDate: UTCDate,   // UTC date for modification time
      uid
    }];
    const dataToDelete = []; // Add any data that needs to be deleted, currently it's empty

    // Construct the final data object
    const data = {
      dataToInsert,
      lastSyncDate,
      collection,
      dataToDelete,
      userId
    };

    // Dispatch the data to be stored in the backend
    dispatch(AddDepression(data)).then((response) => {
      const Response = response.payload.response;
      if (Response === "S") {
        Swal.fire({
          title: t("Depression Test Successful"),
          icon: "success",
        });
        navigate('/mentalhealth');
      }
    });

    // Reset states after save
    setDepression({});
    setSelectedChoice("");
    setCurrentQuestionIndex(0);
  };



  const depressionQuestions = [
    { id: "d1", question: t("1.Little interest or pleasure in doing things") },
    { id: "d2", question: t("2.Feeling down, depressed or hopeless") },
    { id: "d3", question: t("3.Trouble falling asleep, staying asleep, or sleeping too much") },
    { id: "d4", question: t("4.Feeling tired or having little energy") },
    { id: "d5", question: t("5.Poor appetite or overeating") },
    { id: "d6", question: t("6.Feeling bad about yourself - or that you’re a failure or have let yourself or your family down") },
    { id: "d7", question: t("7.Trouble concentrating on things, such as reading the newspaper or watching television") },
    { id: "d8", question: t("8.Moving or speaking so slowly that other people could have noticed. Or, the opposite - being so fidgety or restless that you have been moving around a lot more than usual") },
    { id: "d9", question: t("9.Thoughts that you would be better off dead or of hurting yourself in some way") }
  ];

  const commonChoices = [
    { label: t("Not at all"), value: "0" },
    { label: t("Several days"), value: "1" },
    { label: t("More than half the days"), value: "2" },
    { label: t("Nearly every day"), value: "3" }
  ];

  const isLastQuestion = currentQuestionIndex === depressionQuestions.length - 1;
  const isFirstQuestion = currentQuestionIndex === 0;

  return (
    <Fragment>
      <BreadCrumbs title={t('Depression')} data={[{ title: t('Depression') }]} />

      <span className="mentalhealth text-black">{t('Over the last 2 weeks, how often have you been bothered by the following problems?')}</span>

      <div></div>
      <Card style={{ marginTop: "2%", boxShadow: "0px 0px 10px 5px rgb(144 144 144 / 56%)" }}>
        <CardBody>
          <Form onSubmit={isLastQuestion ? handleSaveMood : handleNextQuestion}>
            <FormGroup tag="fieldset" >
              <legend className="text-black" style={{ fontSize: "30px" }}>{depressionQuestions[currentQuestionIndex].question}</legend>
              {commonChoices.map((choice, i) => (
                <FormGroup className="text-black" check key={i}>
                  <Label check>
                    <Input
                      type="checkbox"
                      className="form-check-input mb-4"
                      value={choice.value}
                      checked={selectedChoice === choice.value}
                      onChange={() => handleChoiceSelection(choice.value)}
                    />
                    {choice.label}
                  </Label>
                </FormGroup>
              ))}
            </FormGroup>
            {error && <Alert color="danger">{error}</Alert>}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {!isFirstQuestion && (
                <Button color="secondary" onClick={handlePreviousQuestion} style={{ marginRight: "10px" }}>
                  {t('Previous')}
                </Button>
              )}
              <Button style={{ width: 'auto' }} color="primary" type="submit" className="small-button"  >
                {isLastQuestion ? t('Save') : t('Next')}
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Depression;
