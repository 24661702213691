import { AesEncrypt } from '../../../../services/crypto';
import webservice from '../../../../services/webservice';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'


export const getlanguage = createAsyncThunk('sidemenu/getlanguage', async (data) => {

    const response = await fetch(webservice + 'api/getLanguage', {
        method: 'POST',
        body: JSON.stringify({ data }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    });
    // Dispatch an action to get all data after update
    const responseData = await response.json();
    return responseData; // Return the updated data
});

// export const getAllData = createAsyncThunk('vaccination/getAllData', async () => {
//     try {
//         const response = await fetch(webservice + 'api/allVaccination', {
//             method: 'POST', // Change method to GET
//             headers: {
//                 'Content-type': 'application/json; charset=UTF-8',
//             }
//         });

//         const responseData = await response.json();
//         return responseData;
//     } catch (error) {
//         console.error('Error:', error);
//         throw error; // Rethrow the error to be handled by Redux Toolkit
//     }
// });


export const getAllData = createAsyncThunk('vaccination/getAllData', async (lang) => {
    try {
        const response = await fetch(webservice + 'api/allVaccination', {
            method: 'POST', // Change method to GET
            body: JSON.stringify({ lang }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});

export const getDob = createAsyncThunk('vaccination/getDob', async (data) => {
    const Encparams = AesEncrypt(data)
    try {
        const response = await fetch(webservice + 'api/getDob', {
            method: 'POST',
            body: JSON.stringify({ Encparams }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});


export const vaccine_details = createAsyncThunk('vaccination/Vaccinedetails', async (data) => {

    try {
        const response = await fetch(webservice + 'api/vaccineDetails', {
            method: 'POST',
            body: JSON.stringify({ data }), // Pass the entire data object directly
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to be handled by Redux Toolkit
    }
});


export const VaccinationSlice = createSlice({
    name: 'vaccination',
    initialState: {
        data: [],
        total: 0,
        params: {},
        allData: []
    },
    reducers: {


    },
    extraReducers: builder => {
        builder.addCase(getAllData.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.allData = action.payload.allData
            state.total = action.payload.totalPages
            state.params = action.payload.params
            state.no_Data = action.payload.no_Data
        })
    }
})