// ** Third Party Components
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useDispatch } from "react-redux";
import { getLanguage, updateLanguage } from './nav/store';
// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'

const IntlDropdown = () => {
  // ** Hooks
  const { i18n } = useTranslation()
  const dispatch = useDispatch();
  const [Id, setId] = useState("");
  const [lang, setUserLanguage] = useState(""); // Renamed Uselanguage to userLanguage

  const langObj = {
    en: 'English',
    ta: 'தமிழ்',
    kn: 'ಕನ್ನಡ',
    ml: 'മലയാളം',
    hi: 'हिन्दी',
    te: 'తెలుగు'
  };

  localStorage.setItem('lang', (lang));

  useEffect(() => {
    const id = localStorage.getItem('id');
    if (!id) {
    } else {
      setId(id);
      dispatch(getLanguage(id)).then((res) => {
        const lang = res.payload.language;
        if (!lang) {
          // e.preventDefault(); // Prevent default behavior
        } else {
          setUserLanguage(lang);
          i18n.changeLanguage(lang);
        }
      }).catch((error) => {
        console.error('Error during fetching user data:', error);
      });
    }
  }, [dispatch, i18n]);


  const handleLangUpdate = (e, lang) => {
    e.preventDefault();
    if (!Id) {
      i18n.changeLanguage(lang); // Change language in i18n
      // localStorage.setItem('lang', (lang));
      setUserLanguage(lang);
      return; // Exit function if Id is not found
    }
    const data = { Id, lang }; // Data to update user's language
    dispatch(updateLanguage(data)).then((res) => {
      if (res.payload) {
        const lang = res.payload.language;
        i18n.changeLanguage(lang); // Change language in i18n
        setUserLanguage(lang); // Update user's language state
        window.location.reload()
      } else {
        console.error('Error updating language');
      }
    });
  };


  return (
    <Fragment>
      <UncontrolledDropdown href='/' tag='li' className='dropdown-language nav-item'>

        <DropdownToggle href='/' tag='a' className='nav-link' onClick={e => e.preventDefault()}>
          {/* <span className='selected-language'>{<i className="fa-solid fa-globe" style={{ fontSize: '25px', marginRight: '5px' }}></i>}</span> */}
          {/* <span className='selected-language'>{<i className="fa-solid fa-globe" style={{ fontSize: '25px', marginRight: '5p x' }}></i>}</span> */}
        </DropdownToggle>
        <DropdownToggle href='/' tag='a' className='nav-link' onClick={e => e.preventDefault()}>
          <span className='selected-language ' style={{color:"#0072ff"}}>{langObj[i18n.language]}</span>
        </DropdownToggle>
        <DropdownMenu className='mt-0'>
          <DropdownItem href='/' tag='a' onClick={e => handleLangUpdate(e, 'en')}>
            <span className='ms-1 text-black'>English</span>
          </DropdownItem>
          <DropdownItem href='/' tag='a' onClick={e => handleLangUpdate(e, 'ta')}>
            <span className='ms-1 text-black'>தமிழ்</span>
          </DropdownItem>
          <DropdownItem href='/' tag='a' onClick={e => handleLangUpdate(e, 'kn')}>
            <span className='ms-1 text-black'>ಕನ್ನಡ</span>
          </DropdownItem>
          <DropdownItem href='/' tag='a' onClick={e => handleLangUpdate(e, 'ml')}>
            <span className='ms-1 text-black'>മലയാളം</span>
          </DropdownItem>
          <DropdownItem href='/' tag='a' onClick={e => handleLangUpdate(e, 'hi')}>
            <span className='ms-1 text-black'>हिन्दी</span>
          </DropdownItem>
          <DropdownItem href='/' tag='a' onClick={e => handleLangUpdate(e, 'te')}>
            <span className='ms-1 text-black'>తెలుగు</span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Fragment>

  )
}

export default IntlDropdown
