import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BloodImage from './Demographic-images/blood-image.png'; // Import the blood image
import { useTranslation } from 'react-i18next'

const BloodGroupPage = ({ handleNextClick, value, setvalue }) => {
  const [bloodGroup, setBloodGroup] = useState('');
  const [showNextPage, setShowNextPage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setBloodGroup(localStorage.getItem('bloodgroup'))
    setErrorMessage('')

  }, []);

  const handleBloodGroupClick = (selectedBloodGroup) => {
    setErrorMessage('')
    setBloodGroup(selectedBloodGroup);
  }




  const handleSubmit = () => {
    if (!bloodGroup || bloodGroup === '') {
      setErrorMessage(t('Please select your blood group'));
      setvalue(8)
    } else {
      localStorage.setItem('bloodgroup', bloodGroup);
      handleNextClick();
    }
  }

  useEffect(() => {
    if (value === 9) {
      handleSubmit();

    }
  });

  const bloodGroups = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'];

  return (
    <>
      <div className='D_container'>

        <div className="blood-group-container">
          <div className="content-container">
            <h2 style={{ textAlign: 'center', justifyContent: 'center', color: "black" }} >{t('Select your blood group')}</h2>
            <div className="blood-group-images">
              {bloodGroups.map((group) => (
                <div
                  className={`blood-group ${bloodGroup === group ? 'selected' : ''}`}
                  key={group}
                  onClick={() => handleBloodGroupClick(group)}
                >
                  <img src={BloodImage} alt="Blood" />
                  <span className="blood-group-label">{group}</span>
                </div>
              ))}
            </div>

            {errorMessage && (
              <p style={{ marginTop: '25px' }} className=" error-message ">{errorMessage}</p>
              // <p  style={{position:'absolute',top:'100%',left:'35%'}} className=" error-message ">{errorMessage}</p>
            )}



          </div>
        </div>
      </div>
    </>
  );
}

export default BloodGroupPage;
