import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { Row, Col, Card, Table, Button, Modal } from "react-bootstrap";
// import { updateBloodPressureData, deleteBloodPressureData } from "../store";
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import { Form } from 'react-bootstrap';
import { Badge, Progress } from "reactstrap";
import { AesDecrypt, AesEncrypt } from '../../../../services/crypto';
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { HiOutlineCheck } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";

import { useDropzone } from "react-dropzone";
import "../DocumentUpload.css";
import { postDoctorPrescription, getselectedData, updateDoctorPrescription, deleteDoctorPrescription } from "../store/index";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Select from "react-select";
import classnames from "classnames";
import { useTranslation } from 'react-i18next'
import moment from "moment";
import img from '../../../../images/EmptyFile.svg'
import { IoMdAddCircle } from "react-icons/io";
import Swal from "sweetalert2";
import "./../../../../css/custom.css"
import { useForm, Controller } from "react-hook-form";




const BloodPressureTable = ({ alldata, handleReload, selectedValue, handleSelectChange }) => {

  const [editingIndex, setEditingIndex] = useState(null);
  const [editedData, setEditedData] = useState({});
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isEditMode, setIsEditMode] = useState(false); // State for edit mode
  const [basicModal, setBasicModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [documentName, setDocumentName] = useState("");
  const [testDate, setTestDate] = useState(new Date);
  const [selectedRecord, setSelectedRecord] = useState("RecordType");
  const [selectedViewFile, setSelectedViewFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [documentNameError, setDocumentNameError] = useState('');
  const [testDateError, setTestDateError] = useState('');
  const [selectedRecordError, setSelectedRecordError] = useState('');
  const [fileError, setFileError] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleEdit = (index, data) => {

    setEditingIndex(index);
    setEditedData({ ...data });
  };

  const Options = [
    { value: "Lab Report", key: "0", label: "Lab Report" },
    { value: "Prescription", key: "1", label: "Prescription" },
    { value: "Invoice", key: "2", label: "Invoice" },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '120px',
      margin: "auto 4px", // Set fixed width
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      color: 'black',
      '&:hover': {
        backgroundColor: '#d6e0ef',
      },
      whiteSpace: 'nowrap', // Prevent text wrapping
      overflow: 'hidden', // Hide overflow
      textOverflow: 'clip', // Prevent ellipsis
    }),
    singleValue: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      color: 'black',
      whiteSpace: 'nowrap', // Prevent text wrapping
      overflow: 'hidden', // Hide overflow
      textOverflow: 'clip', // Prevent ellipsis
    }),
  };

  const handleDelete = (id) => {

    Swal.fire({
      title: 'Do you really want to delete this Document?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteDoctorPrescription(id._id)).then((response) => {
          if (response.payload.response === 'S') {
            handleReload()
            Swal.fire(
              'Deleted!',
              'The Document has been deleted.',
              'success'
            );
          }
          else {
            Swal.fire(
              'Could Not Delete Document',
              'Please Try Again Later',
              'info'
            )
          }
        })

      }
    });
  };

  const handleSave = (index) => {
    // Implement saving edited data logic here
    const { ...editedFields } = editedData;
    dispatch(updateDoctorPrescription({ data: editedFields })).then(() => {
      handleReload()
      setEditingIndex(null);
    })
  };

  const handlediscard = (index) => {
    // Implement saving edited data logic here
    setEditingIndex(null);
  };


  const handleInputChange = (value, key) => {
    setEditedData(prevData => ({
      ...prevData,
      [key]: value
    }));
  };

  const handleDateChange = (date1, key) => {
    const formattedDate = moment(date1).utc().toISOString();  // Add parentheses here
    setEditedData(prevData => ({
      ...prevData,
      'testDate': formattedDate
    }));
  };

  const [downloading, setdownloading] = useState(false);
  const [downloadProgress, setdownloadProgress] = useState(0);
  const [duplicateFileName, setDuplicateFileName] = useState('');

  useEffect(() => {
    let progressInterval;

    if (downloading) {
      // Start a progress simulation
      progressInterval = setInterval(() => {
        setdownloadProgress((prevProgress) => {
          if (prevProgress >= 80) {
            clearInterval(progressInterval); // Stop simulation when reaching 80%
            return prevProgress;
          }
          return prevProgress + 5; // Increment progress by 5%
        });
      }, 500); // Every 500ms (0.5s)
    }

    return () => clearInterval(progressInterval);
  }, [downloading]);

  const handleView = async (data) => {
    setdownloading(true);  // Start loading
    try {
      const response = await dispatch(getselectedData(data));
      setdownloadProgress(80); // Set progress to 80% after file fetching

      if (response.payload.response === 'S') {
        const decryptedData = AesDecrypt(response.payload.data);
        setSelectedViewFile(decryptedData[0]);
        setdownloadProgress(100); // Complete progress when decryption is done
        setShowModal(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setTimeout(() => {
        setdownloading(false); // Stop loading after a slight delay
        setdownloadProgress(0);    // Reset progress after completion
      }, 1000); // Delay for smoothness
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const allowedFormats = ['application/pdf', 'image/jpeg', 'image/png'];
  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {

      if (!allowedFormats.includes(file.type)) {
        alert(`Error: Only PDF, JPG, and PNG files are allowed. File "${file.name}" is not supported.`);
        return;
      }
      if (selectedFiles.some((selectedFile) => selectedFile.name === file.name)) {
        setDuplicateFileName(file.name);
        return;
      }
      setSelectedFiles((prev) => [...prev, file]);
    });
  };
  const removeFile = (fileName) => {
    setSelectedFiles(selectedFiles.filter((file) => file.name !== fileName));
    setFileError('');
  };
  const handleConfirmDuplicate = () => {
    setDuplicateFileName(''); // Clear duplicate error on confirmation
  };
  const userById = localStorage.userDetails;
  const userDetails = JSON.parse(userById);
  const reset = () => {
    setSelectedRecord("RecordType")
    setDocumentName("")
    setTestDate(new Date())
    setSelectedFiles([])
    setDocumentNameError('');
    setTestDateError('');
    setSelectedRecordError('');
    setFileError('');
  }
  const MAX_FILES = 10; // Set max file limit

  const handleUpload = () => {
    const userId = localStorage.id;

    // Determine userId
    // if (userDetails && userDetails._id) {
    //   userId = userDetails._id;
    // } else if (userDetails && userDetails.data && userDetails.data._id) {
    //   userId = userDetails.data._id;
    // } else {
    //   console.error(t("User ID is not available"));
    //   return;
    // }

    // Reset error states
    setDocumentNameError('');
    setTestDateError('');
    setSelectedRecordError('');
    setFileError('');

    let hasError = false;

    // Validate documentName
    if (!documentName) {
      setDocumentNameError(t('Document Name is required'));
      hasError = true;
    }

    // Validate testDate
    if (testDate === null) {
      setTestDateError(t('Test Date is required'));
      hasError = true;
    }

    // Validate selectedRecord
    if (!selectedRecord || selectedRecord === "RecordType") {
      setSelectedRecordError(t('Record Type is required'));
      hasError = true;
    }

    // Validate selectedFiles
    if (selectedFiles.length === 0) {
      setFileError(t('Please select at least one file'));
      hasError = true;
    }
    if (selectedFiles.length > MAX_FILES) {
      swal(t(`You can upload a maximum of ${MAX_FILES} files.`), {
        icon: "error",
      });
      return;
    }
    // If there are validation errors, return early
    if (hasError) {
      return;
    }


    setLoading(true);
    setProgress(0);
    // Format date
    // Prepare formData
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append(`file`, file);
    });

    const FormDataArr = {
      documentName: documentName,
      documentType: selectedRecord.value,
      testDate: moment(testDate).utc().toISOString(),
      userId: userId
    };

    formData.append(
      `fileData`,
      JSON.stringify({ Encparams: AesEncrypt(FormDataArr) })
    );

    // Dispatch the action
    const uploadFile = async () => {
      try {
        const response = await dispatch(postDoctorPrescription(formData));
        // Simulate progress for demonstration (replace with actual progress tracking)
        for (let i = 0; i <= 100; i += 20) {
          setProgress(i);
          await new Promise((resolve) => setTimeout(resolve, 100)); // Simulating delay
        }

        // Reset loading state
        setLoading(false);

        if (response.payload.response !== "S") {
          Swal.fire(t("Duplicate file Uploading please upload new file"), {
            icon: "error",
          });
        } else {
          Swal.fire({
            title: t("Uploaded successfully"),
            icon: "success",
          });

          handleReload();
          setBasicModal(false);
          setSelectedRecord("RecordType");
          setDocumentName("");
          setTestDate(new Date());
          setSelectedFiles([]);
        }
      } catch (error) {
        // Reset loading state
        setLoading(false);
        console.error("Error:", error);
      }
    };

    uploadFile();
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'application/pdf': []
    }
  });
  const [fullScreenView, setFullScreenView] = useState(false);
  const [fullScreenData, setFullScreenData] = useState(null);

  const handleOpenFullScreen = (data) => {
    setFullScreenData(data);
    setFullScreenView(true);
  };

  const handleDownload = () => {
    // Assuming fullScreenData contains the file data
    const { image, pdf, filename } = fullScreenData;
    // Create a blob for the file data
    const blob = image ? base64ToBlob(image, 'image/png') :
      pdf ? base64ToBlob(pdf, 'application/pdf') :
        null;
    if (blob) {
      // Create a URL for the blob
      const url = URL.createObjectURL(blob);
      // Create an anchor element for downloading
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      // Trigger the click event to download the file
      document.body.appendChild(a);
      a.click();
      // Cleanup
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };
  // Function to convert base64 to blob
  const base64ToBlob = (base64Data, contentType) => {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  };


  const Listoptions = [
    { value: 10, label: "Last 10" },
    { value: 50, label: "Last 50" },
    { value: 100, label: "Last 100" }
  ]


  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      ref={ref}
      value={value}
      placeholder="Test Date"
      onClick={onClick}
      readOnly
      className={`form-control ${testDateError ? 'is-invalid' : ''} text-black`}
    />
  ));


  const LoadingModal = ({ isOpen }) => {
    if (!isOpen) return null;

    return (
      <div className="loading-modal-overlay">
        <div className="loading-modal">
          <h3>Uploading...</h3>
          <div className="progress-container">
            <div className="progress-bar" style={{ width: `${progress}%` }}></div>
          </div>
          <span className="progress-text">{progress}%</span>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="main_table" >

        <div className="doc_up_container">
          <h1 className="container_heading">Document's</h1>
        </div>
        <div className="form_select">
          {/* <Form.Select
            value={selectedValue}
            onChange={handleSelectChange}
            style={{
              maxWidth: "35%",
              width: '18%', // Set the width
              padding: '6px', // Add padding for better spacing
              border: '2px solid #007bff', // Custom border color
              borderRadius: '5px', // Rounded corners
              backgroundColor: '#f9f9f9', // Light background color
              color: '#333', // Text color
              fontSize: '16px', // Font size
              transition: 'border-color 0.3s, box-shadow 0.3s', // Smooth transition for focus
            }}
          >

              <option value={10} className="react-select css-b62m3t-container">Last 10</option>
              <option value={50} className="react-select css-b62m3t-container">Last 50</option>
              <option value={100} className="react-select css-b62m3t-container">Last 100</option>
     
          </Form.Select> */}

          <Select
            // value={Listoptions.find(option => option.value === selectedValue) || null}
            placeholder={selectedValue}
            onChange={handleSelectChange}
            options={Listoptions}
            styles={customStyles}

          />

          <div >

            <IoMdAddCircle style={{ fontSize: '35px', color: 'green' }} onClick={() => {
              setBasicModal(true);
              setIsEditMode(false);
            }} />

          </div>
        </div>
      </div>
      <Fragment>
        <Row>

          <div>
            {downloading && (
              <div className="loading-modal-overlay">
                <div className="loading-modal">
                  <h3>Loading...</h3>
                  <div className="progress-container">
                    <div className="progress-bar" style={{ width: `${downloadProgress}%` }}></div>
                  </div>
                  <span className="progress-text">{downloadProgress}%</span>
                </div>
              </div>
            )}
          </div>


          <Card.Body>
            <div style={{ overflowX: 'auto' }}>
              <table
                className="table-no-border table-rounded-sides"
                style={{ width: '100%', minWidth: '800px', }} // Increased minWidth for better display
                responsive
                striped
                bordered={false}
                hover
              >
                <thead className="text-wrap-responsive">
                  <tr className="table-th-blue">
                    <th style={{ maxWidth: '100px', whiteSpace: 'nowrap' }}>{t('No')}</th>
                    <th style={{ whiteSpace: 'nowrap' }}>{t('Record Name')}</th>
                    <th style={{ whiteSpace: 'nowrap' }}>{t('Record Type')}</th>
                    <th style={{ whiteSpace: 'nowrap' }}>{t('Test Date')}</th>
                    <th style={{ whiteSpace: 'nowrap' }}>{t('File Count')}</th>
                    <th style={{ whiteSpace: 'nowrap' }}>{t('Action')}</th>
                  </tr>
                </thead>
                <tbody style={{ wordWrap: 'break-word', textAlign: 'left' }}>
                  {Array.isArray(alldata) && alldata.length > 0 ? (
                    alldata.map((data, index) => (
                      <tr key={index} className="table-tr-hover " style={{}}>
                        <td className="table-cell-border" style={{ maxWidth: '100px', whiteSpace: 'nowrap' }}>
                          {index + 1}
                        </td>
                        <td className="table-cell-border" style={{ fontSize: '15px', whiteSpace: 'nowrap' }}>
                          {editingIndex === index ? (
                            <input
                              type="text"
                              value={editedData.documentName || ''}
                              onChange={(e) => handleInputChange(e.target.value, 'documentName')}
                              style={{
                                fontSize: '14px',
                                padding: '8px', // Adjusted padding
                                height: '40px', // Increased height for better visibility
                                minWidth: '80px',
                                borderRadius: '10px',
                              }}
                              aria-label="Document Name"
                            />
                          ) : (
                            data.documentName
                          )}
                        </td>
                        <td className="table-cell-border" style={{ fontSize: '15px', whiteSpace: 'nowrap' }}>
                          {editingIndex === index ? (
                            <Select
                              isClearable={false}
                              options={Options}
                              classNamePrefix="custom-select"
                              placeholder={t('Record Type')}
                              className={classnames("react-select select-borderless", { 'is-invalid': selectedRecordError })}
                              value={Options.find((opt) => opt.value === editedData.documentType)}
                              onChange={(selectedOption) =>
                                setEditedData((prevData) => ({
                                  ...prevData,
                                  documentType: selectedOption.value,
                                }))
                              }
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  fontSize: '14px',
                                  height: '40px', // Increased height for better visibility
                                  minHeight: '40px',
                                  borderRadius: '4px',
                                }),
                                menu: (base) => ({
                                  ...base,
                                  fontSize: '14px',
                                }),
                              }}
                              menuPortalTarget={document.body}
                              aria-label="Record Type"
                            />
                          ) : (
                            data.documentType
                          )}
                        </td>

                        <td className="table-cell-border" style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                          {editingIndex === index ? (
                            <div
                            // style={{
                            //   width: '100%',
                            //   maxWidth: '250px',
                            //   zIndex: 9999,
                            //   position: 'relative',
                            // }}
                            >
                              <DatePicker
                                selected={editedData.testDate ? new Date(editedData.testDate) : null}
                                onChange={(date) => handleDateChange(date)}
                                placeholderText={`${selectedRecord ? selectedRecord.label || selectedRecord : 'Record Type'} Test Date`}
                                className={`form-control ${testDateError ? 'is-invalid' : ''} text-black`}
                                maxDate={new Date()}
                                showTimeSelect
                                dateFormat="dd-MM-yyyy hh:mm a"
                                minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 100))}
                                customInput={<CustomInput />}
                                popperPlacement="bottom-start"
                                popperModifiers={[
                                  {
                                    name: 'offset',
                                    options: {
                                      offset: [0, 8],
                                    },
                                  },
                                ]}
                                portalId="root-portal" // Optional: Set an ID to specify where to render the portal
                              />
                            </div>


                          ) : (
                            moment(data.testDate).format('MMM-D, h:mm a')
                          )}
                        </td>

                        <td className="table-cell-border">{data.filePath.length}</td>
                        <td
                          className="table-cell-border"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            flexWrap: 'nowrap',
                            justifyContent: 'center',
                          }}
                        >
                          {editingIndex === null && (
                            <div className="badge_view" onClick={() => handleView(data)} role="button" aria-label="View Record">
                              {t('View')}
                            </div>
                          )}
                          {editingIndex === index ? (
                            <div className="badge_save_edit" style={{margin:"8% 0"}} onClick={() => handleSave(index)} role="button" aria-label="Save Changes">
                              <HiOutlineCheck />
                            </div>
                          ) : (
                            <div className="badge_save_edit" onClick={() => handleEdit(index, data)} role="button" aria-label="Edit Record">
                              <FiEdit />
                            </div>
                          )}

                          {editingIndex === index ? (
                            <div className="badge_disc_del" onClick={() => handlediscard(index)} role="button" aria-label="Discard Changes">
                              <RxCross2 />
                            </div>
                          ) : (
                            <div className="badge_disc_del" onClick={() => handleDelete(data)} role="button" aria-label="Delete Record">
                              <AiOutlineDelete />
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center table-cell-border">
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '60vh' }}>
                          <img src={img} alt="No Data" style={{ height: '50vh' }} />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Card.Body>

          {/* <Card.Body> */}
          <div className="bootstrap-modal">
            <Modal className="fade" show={basicModal} onHide={() => setBasicModal(false)} backdrop="static">
              <Modal.Header>
                <Card.Title>{t('Add Your Medical Records Here')}</Card.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="dropzone-container">
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Record Name"
                      value={documentName}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const capitalizedValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
                        setDocumentName(capitalizedValue);
                      }}
                      className={`form-control ${documentNameError ? 'is-invalid' : ''} text-black`}
                    />
                    {documentNameError && <div style={{ color: 'red', marginTop: '5px' }}>{documentNameError}</div>}

                    <div className="select-container mt-3">
                      <Select
                        isClearable={true}
                        options={Options}
                        classNamePrefix="select"
                        placeholder="Record Type"
                        className={classnames("react-select select-borderless", { 'is-invalid': selectedRecordError })}
                        value={selectedRecord}
                        onChange={(selectedOption) => setSelectedRecord(selectedOption)}
                      />
                    </div>
                    {selectedRecordError && <div style={{ color: 'red', marginTop: '5px' }}>{selectedRecordError}</div>}

                    <div className="mt-3">
                      <DatePicker
                        selected={testDate}
                        value={testDate === null ? testDate : new Date()}
                        onChange={(date) => setTestDate(date)}
                        placeholderText={`${selectedRecord ? selectedRecord.label || selectedRecord : 'Record Type'} Test Date`}
                        className={`form-control ${testDateError ? 'is-invalid' : ''} text-black`}
                        maxDate={new Date()}
                        showTimeSelect
                        dateFormat="dd-MM-yyyy hh:mm a"
                        minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 100))}
                        customInput={<CustomInput />}
                      />
                    </div>
                    {testDateError && <div style={{ color: 'red', marginTop: '5px' }}>{testDateError}</div>}

                    <div {...getRootProps()} className="dropzone mt-3">
                      <input {...getInputProps()} />
                      <p>{t('Drag & drop a file here, or click to select a file')}</p>
                      <span className="file-format-info">
                        {t('Only PDF, JPEG, and PNG files are allowed.')}
                      </span>
                    </div>


                    <div className="added-files-container mt-3">
                      {selectedFiles.map((file, index) => (
                        <div key={index} className="added-file">
                          <span>{file.name}</span>
                          <button
                            style={{ float: "left", width: "10%" }}
                            onClick={() => removeFile(file.name)}
                          >
                            X
                          </button>
                        </div>
                      ))}
                    </div>
                    {fileError && <div style={{ color: 'red', marginTop: '5px' }}>{fileError}</div>}

                    {duplicateFileName && (
                      <>
                        <div className="duplicate-alert">
                          <p style={{ color: 'red' }}>The file "{duplicateFileName}" is already added.</p>
                          <Badge color="primary" className="btn-edit " onClick={handleConfirmDuplicate}>OK</Badge>
                        </div>

                      </>
                    )}
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Row>
                  <Col>
                    <Button onClick={() => { setBasicModal(false); reset(); }} variant="danger" className="text-center mt-2 pt-50">
                      {t('Cancel')}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button variant="success" onClick={handleUpload} className="text-center mt-2 pt-50">
                      {t('Upload')}
                    </Button>
                    <LoadingModal isOpen={loading} progress={progress} />
                  </Col>
                </Row>
              </Modal.Footer>
            </Modal>
          </div>

          <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header style={{ backgroundColor: 'green', color: 'white' }} closeButton>
              <Modal.Title style={{ color: "white" }}> {t('Record Name')}  - {selectedViewFile && selectedViewFile.documentName}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: '400px' }}>
              <div className="d-flex flex-wrap ">
                {selectedViewFile &&
                  selectedViewFile.fileBase64.map((fileData, index) => (
                    <div key={index} className="m-2" style={{ position: 'relative' }}>
                      <Card
                        style={{ width: '150px', cursor: 'pointer', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '20px', overflow: 'hidden' }} // Added borderRadius and overflow: hidden
                        onClick={() => handleOpenFullScreen(fileData)}
                      >
                        {fileData.pdf ? (
                          <FontAwesomeIcon
                            icon={faFilePdf}
                            style={{
                              fontSize: '200px', cursor: "pointer", width: "100%",
                              height: "80%"
                            }}
                          />
                        ) : (
                          <>
                            <div style={{ position: 'relative', display: 'inline-block' }}>
                              {/* <div
                              style={{
                                position: 'absolute',
                                top: '5px',
                                right: '5px',
                                backgroundColor: 'red',
                                color: 'white',
                                borderRadius: '50%',
                                width: '25px',
                                height: '25px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                              }}
                            >
                              X
                            </div> */}

                              <img
                                src={`data:image/png;base64,${fileData.image}`}
                                alt={`Image ${index + 1}`}
                                style={{ width: '100%', height: '150px', objectFit: 'cover' }}
                              />
                            </div>

                          </>

                        )}
                      </Card>
                      <span style={{ position: 'absolute', top: '90%', left: '10px', color: '#000', padding: '5px', borderRadius: '4px' }}>{fileData.filename}</span>
                    </div>
                  ))
                }
              </div>
            </Modal.Body>
          </Modal>

          <Modal show={fullScreenView} onHide={() => setFullScreenView(false)} size="lg"  >
            <Modal.Header closeButton>
              {/* Add the download icon here */}
              <div className="d-flex justify-content-end align-items-center">
                <a href="#" onClick={() => handleDownload()} className="btn btn-link">
                  <i className="fas fa-download"></i> {t('Download')}
                </a>
              </div>
            </Modal.Header>
            <Modal.Body style={{ height: '600px' }}>
              {fullScreenData && fullScreenData.image ? (
                <img src={`data:image/png;base64,${fullScreenData.image}`} alt="Full Screen Preview" width="100%" height="100%" />
              ) : fullScreenData && fullScreenData.pdf ? (
                <embed src={`data:application/pdf;base64,${fullScreenData.pdf}`} type="application/pdf" width="100%" height="100%" />
              ) : null}
            </Modal.Body>
          </Modal>
        </Row>
      </Fragment>
    </>
  );
};

export default BloodPressureTable;
