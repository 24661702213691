import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import '../../../../css/custom.css';
import moment from 'moment';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import webservice from '../../../../services/webservice';

const renderDetails = (data, index, setProgress, setDownloading) => {

   const handleDownload = async (data) => {
      try {
         setProgress(0);
         setDownloading(true);
         const ids = data.map(item => item._id)[0];

         const response = await fetch(webservice + 'api/downloadFiles', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ids }),
         });

         const contentLength = response.headers.get('Content-Length');
         const total = contentLength ? parseInt(contentLength, 10) : 0;

         const reader = response.body.getReader();
         const chunks = [];
         let receivedLength = 0;


         while (true) {
            const { done, value } = await reader.read();

            if (done) break;

            chunks.push(value);
            receivedLength += value.length;
            const percentCompleted = total ? (receivedLength / total) * 100 : 0;
            setProgress(Math.round(percentCompleted));
         }

         const blob = new Blob(chunks);

         const responseData = await blob.text();
         const parsedData = JSON.parse(responseData);
         if (parsedData.length !== 1) {
            const zip = new JSZip();
            for (const item of parsedData) {
               const fileName = item.fileName;
               const base64Data = item.base64;
               zip.file(fileName, base64Data, { base64: true });
            }
            const content = await zip.generateAsync({ type: 'blob' });
            FileSaver.saveAs(content, 'files.zip');
         } else {
            const singleFile = parsedData[0];
            const fileName = singleFile.fileName;
            const base64Data = singleFile.base64;

            const byteCharacters = atob(base64Data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
               byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/octet-stream' });

            FileSaver.saveAs(blob, fileName);
         }

      } catch (error) {
         console.error('Error downloading files:', error);
      }
   };

   switch (data.type) {
      case 'Bloodpressure':
         return (
            <Col md={25} key={`bloodpressure-${index}`} >
               <div>
                  <h2 className="data-title bloodpressure-title">
                     <strong>
                        Blood Pressure Data :
                     </strong>
                  </h2>
                  <div className="row">
                     {data.data.map((item, subIndex) => {
                        const formatedDateandTime = moment(item.testDate).format('DD-MM-YYYY h:m A');
                        return (
                           <div className='data-section documents-section calander-responsive-card data-col' style={{ backgroundColor: 'silver', marginTop: '5px' }} key={subIndex}>
                              <div className="data-item bloodpressure-item" style={{ backgroundColor: 'aliceblue' }}>
                                 <p className='text-black'><strong>Systolic :</strong> {item.systolic}</p>
                                 <p className='text-black'><strong>Diastolic :</strong> {item.diastolic}</p>
                                 <p className='text-black'><strong>Pulse :</strong> {item.pulse}</p>
                                 <p className='text-black'><strong>Test Date :</strong> {moment(item.testDate).format('MMM-D YYYY h:m A')}</p>
                              </div>
                           </div>
                        );
                     })}
                  </div>
               </div>
            </Col>
         );
      case 'Diabetes':
         return (
            <Col md={12} key={`diabetes-${index}`}>
               <div>
                  <h2 className="data-title diabetes-title"><strong>Diabetes Data :</strong></h2>
                  <Row>
                     {data.data.map((item, subIndex) => {
                        const formatedDateandTime = moment(item.testDate).format('DD-MM-YYYY hh:mm A');
                        return (
                           <div className='data-section documents-section calander-responsive-card data-col' style={{ backgroundColor: 'silver', marginTop: '5px' }} key={subIndex}>

                              <div className="data-item diabetes-item" style={{ backgroundColor: 'aliceblue' }}>
                                 <p className='text-black'><strong>Sugar Level :</strong> {item.sugarLevel}</p>
                                 <p className='text-black'><strong>Sugar Type :</strong> {item.sugarType}</p>
                                 <p className='text-black'><strong>Meal :</strong> {item.meal}</p>
                                 {/* <p><strong>Test Date :</strong> {formatedDateandTime}</p> */}
                                 <p className='text-black'><strong>Test Date :</strong> {moment(item.testDate).format('MMM-D YYYY h:m A')}</p>
                                 {/* <p><strong>Updated Date :</strong> {item.formattedDate}</p> */}
                              </div>

                           </div>

                        );
                     })}
                  </Row>
               </div>
            </Col>
         );
      case 'Mood':
         return (
            <Col md={12} key={`mood-${index}`}>
               <div>
                  <h2 className="data-title mood-title"><strong>Mood Data :</strong></h2>
                  <Row>
                     {data.data.map((item, subIndex) => {
                        const formatedDateandTime = moment(item.createdDate, 'DD-MM-YYYY hh:mm:ss').format('MMM-D YYYY h:m A');

                        return (
                           <div className='data-section documents-section calander-responsive-card data-col' style={{ backgroundColor: 'silver', marginTop: '5px' }} key={subIndex}>
                              <div className="data-item  " style={{ backgroundColor: 'aliceblue' }}>
                                 <p className='text-black'><strong>Mood :</strong> {item.mood}</p>
                                 <p className='text-black'><strong>Reason :</strong> {item.reason}</p>
                                 <p className="note text-black"><strong>Note :</strong> {item.note ? item.note : 'You haven\'t given any reason for this mood.'}</p>
                                 <p className='text-black'><strong>Time :</strong> {formatedDateandTime}</p>
                                 {/* <p><strong>Updated Date :</strong> {item.formattedDate}</p> */}
                              </div>
                           </div>

                        );
                     })}
                  </Row>
               </div>
            </Col>
         );
      case 'Documents':
         return (
            <Col md={12} key={`documents-${index}`}>
               <div>
                  <h2 className="data-title documents-title">
                     <strong>Document Data :</strong>
                  </h2>
                  <Row>
                     {data.data.map((item, subIndex) => {
                        const formatedDateandTime = moment(item.testDate).format('MMM-D YYYY h:m A');
                        return (
                           <div className='data-section documents-section calander-responsive-card data-col' style={{ backgroundColor: 'silver', marginTop: '5px' }} key={subIndex}>
                              <div className="data-item documents-item" style={{ backgroundColor: 'aliceblue' }}>
                                 <div className="d-flex justify-content-between align-items-center">
                                    <p className='text-black mb-0'>
                                       <strong>Document Name :</strong> {item.documentName}
                                    </p>
                                    <div onClick={() => handleDownload([item])} className="btn btn-link">
                                       <i className="fas fa-download"></i>
                                    </div>
                                 </div>
                                 <p className='text-black'>
                                    <strong>Document Type :</strong> {item.documentType}
                                 </p>
                                 <p className='text-black'>
                                    <strong>Test Date :</strong> {formatedDateandTime}
                                 </p>
                              </div>
                           </div>
                        );
                     })}
                  </Row>
               </div>
            </Col>


         );
      default:
         return [];
   }
};

const CalendarData = () => {
   const location = useLocation();

   const [clickedDate, setClickedDate] = useState(null);
   const [allData, setAllData] = useState(null);
   const [progress, setProgress] = useState(0);
   const [downloading, setDownloading] = useState(false);


   useEffect(() => {
      // Get the clicked date from location state and update the state with formatted date
      if (location.state?.clickedDate) {
         const formattedDate = moment(location.state.clickedDate).format('MMM-D YYYY');
         setClickedDate(formattedDate);
      }
      if (location.state?.data) {

         setAllData(location.state?.data);
      }
   }, [location.state]);

   useEffect(() => {
      if (progress === 100) {
         const timeout = setTimeout(() => {
            setDownloading(false);
         }, 1000);


         return () => clearTimeout(timeout);
      }
   }, [progress]);

   return (
      <>
         <div>
            <Link to={"/app-calender"}>
               <i style={{ color: '#007bff', fontSize: '32px' }} className="fas fa-arrow-alt-circle-left"></i>
            </Link>
            <div>
               <h1 style={{ textAlign: 'center' }}>{clickedDate}</h1>
            </div>
         </div>
         &nbsp;
         &nbsp;
         <div className='calendar-data'>
            {allData && allData.length > 0 ? (
               <Row>
                  {allData.map((item, index) => (
                     renderDetails(item, index, setProgress, setDownloading)
                  ))}
               </Row>
            ) : (
               <p className="no-data-msg">No data available</p>
            )}
            {downloading && (
               <div className="progress-overlay">
                  <div className="loaderBar" style={{ width: `${progress}%` }}></div>
                  <div className="progress-text">{progress} %</div>
               </div>
            )}
         </div>
      </>
   );
};

export default CalendarData;
