import React, { Fragment, useEffect, useState } from "react";
import { Button, Modal, Form, Card, Badge } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import "react-toastify/dist/ReactToastify.css";
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import "react-datepicker/dist/react-datepicker.css";
import { AesDecrypt, AesEncrypt } from '../../../../services/crypto';
import webservice from '../../../../services/webservice'
import { useTranslation } from 'react-i18next'
import Select from 'react-select';
// import Switchuser from "./Switchuser";
import { getProfileData, getprofession, updateUserData, Addfamilydata, getfamilyData, deletemember, getprofile, removeprofile } from "../store";
import DatePicker from "react-flatpickr";
import IntlDropdown from "../../../layouts/langdropdown";
import LogoutPage from '../../../layouts/nav/Logout';

const AvatarPaths = {
  A: "",
  B: "",
  C: "",
  D: "",
  E: "",
  F: "",
  G: "",
  H: "",
  I: "",
  J: "",
  K: "",
  L: "",
  M: "",
  N: "",
  O: "",
  P: "",
  Q: "",
  R: "",
  S: "",
  T: "",
  U: "",
  V: "",
  W: "",
  X: "",
  Y: "",
  Z: ""
};


const AppProfile = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [Alldata, setAlldata] = useState([]);
  const [Id, setId] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [UserType, setUserType] = useState("");
  const [parentId, setParentId] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [customProfession, setCustomProfession] = useState('');
  const [FamilyData, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [relationship, setRelation] = useState('');
  const [error, setErrorchange] = useState({ name: '', dob: '', gender: '', relationship: '' });
  const handleReloadTable = () => {
    dispatch(getfamilyData(parentId)).then((res) => {
      const Encparam = AesDecrypt(res.payload.data)

      setData(Encparam)
    })
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#cce5ff' : 'transparent', // Customize background color
      color: state.isSelected ? 'white' : 'black', // Customize text color
    }),
  };

  const lang = localStorage.lang

  const usertype = localStorage.getItem('isChild') === 'true';
  // to get family members 
  useEffect(() => {
    const data = localStorage.getItem('userDetails');
    const id = localStorage.getItem('id');
    // const UserType = JSON.parse(data).UserType
    const parentId = JSON.parse(data)._id
    setParentId(parentId)
    setId(id);

    if (id !== "undefined") {
      dispatch(getProfileData(id)).then((response) => {
        const profiledata = AesDecrypt(response.payload.encryptedData)
        setAlldata(profiledata);
      })
      dispatch(getfamilyData(parentId)).then((res) => {
        const Encparam = AesDecrypt(res.payload.data)
        setData(Encparam)
      })
    }
  }, [dispatch]);


  const renderAvatar = () => {
    if (!Alldata.img) {
      const firstLetter = Alldata.name ? Alldata.name.charAt(0).toUpperCase() : '';
      return firstLetter;
    }
    // If image data is available, return the image
    return `data:image/jpeg;base64,${Alldata.img}`;
  };

  ///update  the user Details

  const bloodGroupOptions = [
    { value: 'A+', label: 'A+' },
    { value: 'A-', label: 'A-' },
    { value: 'B+', label: 'B+' },
    { value: 'B-', label: 'B-' },
    { value: 'AB+', label: 'AB+' },
    { value: 'AB-', label: 'AB-' },
    { value: 'O+', label: 'O+' },
    { value: 'O-', label: 'O-' }
  ];

  const maritalStatusOptions = [
    { value: 'Single', label: t('Single') },
    { value: 'Married', label: t('Married') },
    { value: 'Divorced', label: t('Divorced') },
    { value: 'Widowed', label: t('Widowed') },
  ];

  const genderOptions = [
    { value: 'Male', label: t('Male') },
    { value: 'Female', label: t('Female') },
    { value: 'Transgender', label: t('Transgender') },

  ];

  // const RelationOptions = [
  //   { value: 'Father', label: t('Father') },
  //   { value: 'Mother', label: t('Mother') },
  //   { value: 'Son', label: t('Son') },
  //   { value: 'Brother', label: t('Brother') },
  //   { value: 'Sister', label: t('Sister') },
  //   { value: 'Wife', label: t('Wife') },
  // ];

  const FoodOptions = [
    { value: 'Veg', label: t('Veg') },
    { value: 'Non-Veg', label: t('Non-Veg') },
    { value: 'Vegan', label: t('Vegan') },
  ];
  const [typeOptions, setTypeOptions] = useState([]);

  // useEffect(() => {
  //   const id = localStorage.id;
  //   if (id !== "undefined") {
  //     dispatch(getlanguage(id)).then((res) => {
  //       const lang = res.payload.language;
  //       dispatch(getprofession(lang)).then((response) => {
  //         const profession = response.payload.data
  //         const options = profession.map((user) => ({
  //           value: user.job,
  //           label: user.label,
  //         }));
  //         setTypeOptions(options);
  //       });
  //     });
  //   }
  // }, [dispatch]);

  useEffect(() => {
    dispatch(getprofession()).then((response) => {
      const profession = response.payload.data
      const options = profession.map((user) => ({
        value: user.job,
        label: t(user.label),
      }));
      setTypeOptions(options);

    })
  }, [dispatch]);


  const handleCloseModal = () => {
    setErrors({
      name: "",
      dob: "",
      gender: "",
      bloodGroup: "",
      maritalStatus: "",
      profession: "",
      relationShip: "",
      foodType: ""
    });
    setUpdatedData({
      name: "",
      dob: "",
      gender: "",
      bloodGroup: "",
      maritalStatus: "",
      profession: "",
      relationShip: "",
      foodType: ""
    });
    setShowModal(false);
  };


  const handleUpdateClick = (data) => {

    setShowModal(true);
    const originalDate = Alldata.dob;
    const [day, month, year] = originalDate.split('-');
    const formattedDate = `${year}-${month}-${day}`;
    setUpdatedData({
      name: Alldata.name,
      // mobile: Alldata.mobile,
      dob: formattedDate, // Use formattedDate instead of Alldata.dob
      gender: Alldata.gender,
      bloodGroup: Alldata.bloodGroup,
      maritalStatus: Alldata.maritalStatus,
      profession: Alldata.profession,
      Id: Alldata._id,
      // relationShip: Alldata.relationShip,
      foodType: Alldata.foodType
      // Add other fields here as needed
    });
  };
  const [errors, setErrors] = useState({
    name: "",
    // mobile: "",
    dob: "",
    gender: "",
    bloodGroup: "",
    maritalStatus: "",
    profession: "",
    // relationShip: "",
    foodType: ""
  });

  const [updatedData, setUpdatedData] = useState({
    name: "",
    // mobile: "",
    dob: "",
    gender: "",
    bloodGroup: "",
    maritalStatus: "",
    profession: "",
    // relationShip: "",
    foodType: "",
    Id
    // Add other fields here as needed
  });

  // Function to handle changes in name field
  const handleNameChange = (e) => {
    const value = e.target.value;
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
    setUpdatedData({ ...updatedData, name: capitalizedValue });
    setErrors({ ...errors, name: '' }); // Clear the error message
  };

  // Function to handle changes in blood group field
  const handleBloodGroupChange = (selectedOption) => {
    setUpdatedData({ ...updatedData, bloodGroup: selectedOption.value });
    setErrors({ ...errors, bloodGroup: '' }); // Clear the error message
  };

  // Function to handle changes in date of birth field
  const handleDOBChange = (e) => {
    setUpdatedData({ ...updatedData, dob: e.target.value });
    setErrors({ ...errors, dob: '' }); // Clear the error message
  };

  const handleMenuItemClick = () => {
    setDropdownOpen(false);
  };

  // Function to handle changes in marital status field
  const handleMaritalStatusChange = (selectedOption) => {
    setUpdatedData({ ...updatedData, maritalStatus: selectedOption.value });
    setErrors({ ...errors, maritalStatus: '' }); // Clear the error message
  };

  // Function to handle changes in profession field
  const handleProfessionChange = (selectedOption) => {
    if (selectedOption.value === 'Others') {
      setShowInput(true);
    } else {
      setShowInput(false);
      setUpdatedData({ ...updatedData, profession: selectedOption.value });
    }
  };

  const handleCustomProfessionChange = (e) => {
    setCustomProfession(e.target.value);
    if (!e.target.value.trim()) {
      setErrors(true);
    } else {
      setErrors(false);
    }
    setUpdatedData({ ...updatedData, profession: e.target.value });
  };


  // Function to handle changes in gender field
  const handleGenderChange = (selectedOption) => {
    setUpdatedData({ ...updatedData, gender: selectedOption.value });
    setErrors({ ...errors, gender: '' }); // Clear the error message
  };

  // const handlerelationshipChange = (selectedOption) => {
  //   setUpdatedData({ ...updatedData, relationShip: selectedOption.value });
  //   setErrors({ ...errors, relationShip: '' }); // Clear the error message
  // };

  const handlefoodTypeChange = (selectedOption) => {
    setUpdatedData({ ...updatedData, foodType: selectedOption.value });
    setErrors({ ...errors, foodType: '' }); // Clear the error message
  };

  const handleUpdate = () => {
    let error = false;
    const errorObj = {};

    // Validate each field in updatedData
    for (const key in updatedData) {

      if (Object.hasOwnProperty.call(updatedData, key)) {
        const value = updatedData[key];

        if (key === 'dob') { // Handle DOB field separately
          if (!value || value === "NaN-NaN-NaN") {
            errorObj[key] = t('DOB is required');
            error = true;
          } else {
            errorObj[key] = ''; // Clear the error if field is not empty
          }
        } else if (key === 'profession' && value === 'Others' && customProfession.trim() === '') {
          errorObj[key] = t('Profession is required');
          error = true;
        } else if (value === null || value === undefined || value.trim() === '') {
          errorObj[key] = t(`${key} is required`);
          error = true;
        } else {
          errorObj[key] = ''; // Clear the error if field is not empty
        }
      }
    }

    // Update DOB format
    if (updatedData.dob) {
      const dateOfBirth = new Date(updatedData.dob);
      const day = dateOfBirth.getDate().toString().padStart(2, "0");
      const month = (dateOfBirth.getMonth() + 1).toString().padStart(2, "0");
      const year = dateOfBirth.getFullYear();
      updatedData.dob = day + "-" + month + "-" + year;
    }

    // Update state with errors
    setErrors(errorObj);

    // If there are errors, halt further processing
    if (error) {
      return;
    }

    // If no errors, proceed with dispatching update
    dispatch(updateUserData(updatedData)).then((res) => {
      if (res.payload.data !== undefined) {
        const Updatedata = AesDecrypt(res.payload.data);
        setAlldata(Updatedata);
        if (res.payload.response === 'S') {
          localStorage.removeItem('name')
          localStorage.setItem('name', Updatedata.name)
        }
      }
      // Close the modal after successful update
      setShowModal(false);
    });
  };


  // errorsobject for Addfamily members
  const Addfamily = (data) => {
    setName('');
    setDob('');
    setGender(''); // Corrected to setGender
    setRelation('');
    setopenModal(true);
  };


  const resetFields = () => {
    setName('');
    setDob('');
    setGender('');
    setRelation('');
    setErrorchange({ name: '', dob: '', gender: '', relationship: '' });
  };

  const options = [
    { value: 'Male', label: t('Male') },
    { value: 'Female', label: t('Female') },
    { value: 'Transgender', label: t('Transgender') },
  ];
  const Relationoptions = [
    { value: 'Father', label: t('Father') },
    { value: 'Mother', label: t('Mother') },
    { value: 'Son', label: t('Son') },
    { value: 'Brother', label: t('Brother') },
    { value: 'Sister', label: t('Sister') },
    { value: 'Wife', label: t('Wife') },
    { value: 'Husband', label: t('Husband') },
  ];

  const handlegenderChange = selectedOption => {
    setGender(selectedOption.value);
    setErrorchange({ ...error, gender: '' });
  };

  const handlerelationChange = selectedoption => {
    setRelation(selectedoption.value);
    setErrorchange({ ...error, relationship: '' });
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    if (field === 'name') {
      const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
      setName(capitalizedValue);
      setErrorchange({ ...error, name: '' });
    } else if (field === 'dob') {
      setDob(value);
      setErrorchange({ ...error, dob: '' });
    }
  };

  const addmember = (e) => {

    e.preventDefault();
    let hasError = false;
    const newError = { name: '', dob: '', gender: '', relationship: '' };

    if (!name.trim()) {
      newError.name = t('Name is Required');
      hasError = true;
    }
    if (!dob.trim()) {
      newError.dob = t('DOB is Required');
      hasError = true;
    }
    if (!gender.trim()) {
      newError.gender = t('Select Gender..');
      hasError = true;
    }
    if (!relationship.trim()) {
      newError.relationship = t('Select relationship');
      hasError = true;
    }

    if (hasError) {
      setErrorchange(newError);
      return;
    }
    const dateofbirth = new Date(dob);
    const day = dateofbirth.getDate().toString().padStart(2, "0");
    const month = (dateofbirth.getMonth() + 1).toString().padStart(2, "0");
    const year = dateofbirth.getFullYear();
    const FullDate = day + "-" + month + "-" + year;


    const formatdob = FullDate
    const data = { name, gender, dob: formatdob, parentId, lang, relationship }
    dispatch(Addfamilydata(data)).then((res) => {
      if (res.payload.response === 'S') {
        // Set state to show success alert 
        handleReloadTable();
      }

      setName('');
      setDob('');
      setGender('');
      setRelation('')
      // Close modal regardless of response
      setopenModal(false);
      resetFields();
    });
  }


  // const switchuser = (switchid) => {

  //   swal({
  //     title: t("Confirm Switch Account"),
  //     text: "",
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true,
  //   }).then((switchaccount) => {
  //     if (switchaccount) {
  //       dispatch(switchuseraccount(switchid)).then((response) => {
  //         const responseData = response.payload;
  //         if (responseData.status === "success") {
  //           localStorage.removeItem('id', 'dob', 'name', 'bloodGroup');
  //           dispatch(getProfileData(switchid)).then((profileResponse) => {
  //             const profiledata = AesDecrypt(profileResponse.payload.encryptedData);
  //             const profileId = profiledata
  //             localStorage.setItem('id', (profileId._id));
  //             localStorage.setItem('dob', (profileId.dob));
  //             localStorage.setItem('name', (profileId.name));
  //             localStorage.setItem('blood', (profileId.bloodGroup));
  //             setAlldata(profiledata);
  //             setId(profileId._id);
  //           });
  //           swal(t("Account Switch Successful"), {
  //             icon: "success",
  //           }).then(() => {
  //             window.location.reload();
  //             window.location.href = "/dashboard";
  //           });
  //         } else {
  //           swal(t("Failed to switch account"), {
  //             icon: "error",
  //           });
  //         }
  //       });
  //     }
  //   });
  // };

  // delete family

  const deletefamily = (member) => {

    const id = member;
    const deletefamily = async () => {
      swal({
        title: t("Are you sure?"),
        text:
          t("Once deleted, you will not be able to recover this Child!"),
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          dispatch(deletemember(id)).then((res) => {
            const deletedata = res.payload;
            if (deletedata.response === "S") {
              swal(t("Poof! Your child user has been deleted!"), {
                icon: "success",
              });
            } else {
              // Perform action when response is not "S"
              // For example, show another swal message
              swal(t("Failed to delete file!"), {
                icon: "error",
              });
            }
          }).then(() => {
            handleReloadTable();
          });
        } else {
          swal(t("Your child user is safe!"));
        }
      });
    };
    deletefamily();
  };


  const [selectedFile, setSelectedFile] = useState(null);
  const [avatarImg, setAvatarImg] = useState(null);


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    // Display selected image in the avatar circle
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setAvatarImg(e.target.result);
        // Call handleUpload immediately after selecting the file
        handleUpload(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const userId = localStorage.id


  const handleEditClick = () => {
    // Trigger file input click
    const fileInput = document.getElementById("fileInput");

    if (fileInput) {
      fileInput.click();
    }
  };


  const handleUpload = async (file) => {

    // Handle file upload here
    if (file) {
      try {
        const MAX_FILE_SIZE_MB = 3; // Maximum file size in MB after compression

        // Create a temporary canvas element
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Load the image
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = async () => {
          // Calculate new dimensions to reduce size by 50%
          const MAX_WIDTH = img.width * 0.5;
          const MAX_HEIGHT = img.height * 0.5;
          let newWidth = img.width;
          let newHeight = img.height;

          if (img.width > MAX_WIDTH || img.height > MAX_HEIGHT) {
            if (img.width > img.height) {
              newWidth = MAX_WIDTH;
              newHeight = (img.height * MAX_WIDTH) / img.width;
            } else {
              newHeight = MAX_HEIGHT;
              newWidth = (img.width * MAX_HEIGHT) / img.height;
            }
          }

          // Set canvas dimensions
          canvas.width = newWidth;
          canvas.height = newHeight;

          // Draw image on canvas
          ctx.drawImage(img, 0, 0, newWidth, newHeight);

          // Convert canvas to Blob with desired quality (compression)
          canvas.toBlob(async (blob) => {
            // Check if compressed file size is within limit
            if (blob.size / (1024 * 1024) > MAX_FILE_SIZE_MB) {
              console.error('File size after compression exceeds limit.');
              return;
            }

            // Create FormData object
            const formData = new FormData();

            // Append the file
            formData.append('file', file); // Retain original filename

            // Create an object containing your data to be encrypted
            const dataToEncrypt = { userId: userId };

            // Encrypt the data and append it to the FormData object
            formData.append(
              'filedata',
              JSON.stringify({ Encparams: AesEncrypt(dataToEncrypt) })
            );

            // Upload compressed image
            const response = await fetch(webservice + 'api/uploadProfilepic', {
              method: 'POST',
              body: formData,

            });

            if (response.ok) {
              setShowPopup(!showPopup);
              dispatch(getprofile(localStorage.id))
                .then((res) => {
                  if (res.payload.response === 'S') {
                    const data1 = AesDecrypt(res.payload.data);
                    if (Array.isArray(data1) && data1.length !== 0) {
                      setAvatarImg(data1[0].image);
                    }
                  }
                })

              const responseData = await response.json(); // Parse JSON response

              // Handle success
            } else {
              console.error('Failed to upload file.');
              // Handle error
            }
          }, 'image/jpeg', 0.7); // Adjust quality as needed
        };
      } catch (error) {
        console.error('Error handling file:', error);
      }
    }
  };


  useEffect(() => {
    const _id = localStorage.id;
    dispatch(getprofile(_id))
      .then((res) => {

        if (res.payload.response === 'S') {
          const data1 = AesDecrypt(res.payload.data);
          if (Array.isArray(data1) && data1.length !== 0) {
            setAvatarImg(data1[0].image);
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching profile:', error);
        // Handle error
      });
  }, [dispatch]);


  const handleRemoveProfile = () => {
    // Add logic to remove profile image
    setAvatarImg(null); // Assuming this clears the image
    setShowPopup(!showPopup);
    // Add logic to send ID to a collection or perform any other action
    const id = localStorage.id;
    dispatch(removeprofile(id)).then((res) => {

    })

    // Example: Send profile ID to a collection
  };

  const [showPopup, setShowPopup] = useState(false);

  const handleTogglePopup = () => {
    setShowPopup(!showPopup);
  };

  const today = new Date().toISOString().split('T')[0];
  const [isEditing, setIsEditing] = useState(false);
  const [hover, setHover] = useState(false);

  const handleProfileClick = () => {
    setIsEditing(!isEditing); // Toggle the edit mode on profile picture click
  };
  return (
    <Fragment>
      {/* <Breadcrumbs title={t('App Profile')} data={[{ title: t('App Profile') }]} /> */}

      <Card className="profile-card" style={{ borderRadius: '15px', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)', padding: '20px', width: '90%' }}>
        <Card.Header className="profilecard_header" style={{ backgroundColor: '#f1f1f1', borderRadius: '10px', padding: '20px' }}>
          <div className="profilecard_container">
            <div className="profile_pricture_name">
              <div className="profilecard_picture " style={{ position: 'relative' }}>
                <input type="file" id="fileInput" accept="image/*" onChange={handleFileChange} style={{ display: 'none' }} />

                <div
                  className={`avatar-circle ${isEditing ? 'edit-mode' : ''}`} // Add class for edit mode
                  onClick={handleProfileClick} // Toggle edit mode on click
                  onMouseEnter={() => setHover(true)} // Set hover state
                  onMouseLeave={() => setHover(false)} // Unset hover state
                  style={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '150px',
                    height: '150px',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    position: 'relative',
                    cursor: 'pointer',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
                    transition: 'all 0.3s ease',
                  }}
                >
                  {avatarImg ? (
                    <img className="rounded-circle" src={`data:image/jpeg;base64,${avatarImg}`}
                      style={{ width: '100%', height: 'auto' }} />
                  ) : (
                    <span className="avatar-letter" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '28px' }}>
                      {renderAvatar()}
                    </span>
                  )}

                  {/* Show text labels on hover */}
                  {/* {hover && (  
          <>
             
            <div 
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255, 239, 239, 0.5)',  
                backdropFilter: 'blur(10px)',  
                borderRadius: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1,
              }}
            />

            <div 
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column', 
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 2,
              }}
            >
              <div 
                style={{
                  backgroundColor: '#fefefe00',
                  padding: '5px 10px',
                  cursor: 'pointer',
                  color: '#007bff',  
                  fontWeight: 'bold',
                  textAlign: 'center',

                }}
                onClick={handleEditClick}
              >
                Edit
              </div>

              {avatarImg ? (
              <div 
                style={{
                  backgroundColor: '#fefefe00',
                  padding: '5px 10px',
                  cursor: 'pointer',
                  color: '#dc3545',  
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
                onClick={handleRemoveProfile}
              >
                Remove
              </div>
              ) : null}
            </div>
          </>
        )} */}
                </div>
              </div>
              <div className="profile_name">
                <h2 className="">{Alldata.name}</h2>
              </div>
            </div>
            <div className="profilecard_settings">
              <div className="setting_change_password">
                {usertype ? (null) : (<Link to={'/changepassword'}>
                  <lable style={{ color: '#0072ff' }}>Change password</lable>
                </Link>)}
              </div>
              <div className="setting_language">
                <IntlDropdown />
              </div>
              <div className="setting_logout">
                <LogoutPage />
              </div>


            </div>
          </div>

        </Card.Header>


        <Card.Body>
          {/* Unified Information Section */}
          <div className="info-section mb-4">
            <Card className="detail-card" style={{ borderRadius: '10px', padding: '20px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5>{t('Profile Information')}</h5>
                <span className="btn btn-warning" onClick={() => handleUpdateClick(Alldata)}><i className="fas fa-pen"></i> {t('Edit')}</span>
              </div>
              <hr />
              <div className="row">
                {[
                  { label: 'Mobile', value: Alldata.mobile, icon: 'fa-solid fa-mobile-alt' },
                  { label: 'Date of Birth', value: Alldata.dob, icon: 'fa-solid fa-birthday-cake' },
                  { label: 'Gender', value: t(Alldata.gender), icon: 'fa-solid fa-mars' },
                  { label: 'Blood Group', value: Alldata.bloodGroup, icon: 'fa-solid fa-droplet' },
                  { label: 'Marital Status', value: t(Alldata.maritalStatus), icon: 'fa-solid fa-heart' },
                  { label: 'Profession', value: t(Alldata.profession), icon: 'fa-solid fa-user-tie' },
                  { label: 'Food Type', value: t(Alldata.foodType), icon: 'fa-solid fa-carrot' },
                  // { label: 'Language', value: <IntlDropdown />, icon: 'fa-solid fa-carrot' },
                ].map((item, index) => (
                  <div key={index} className="col-md-6 mb-2">
                    <div className="profile-item d-flex align-items-center">
                      <i className={item.icon + " me-2"}></i>
                      <strong>{t(item.label)}:</strong> <span className="ms-2">{item.value}</span>
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          </div>

          {/* Family Members Section */}

          {usertype ? (
            null
          ) : (
            <div className="family-members">
              <div><span className="mb-3 fs-10 font-w700">{t('Family Members')}</span>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <Badge variant="primary" onClick={Addfamily} className="mb-1" style={{ borderRadius: '20px', }}>
                  <i className="fa-solid fa-user-plus" ></i>
                  {/* {t('Add Family Member')} */}
                </Badge></div>

              <div className="row mt-3">
                {FamilyData && FamilyData.length > 0 ? (
                  FamilyData.map((member, index) => (
                    member.userType === 'Child' && (
                      <div key={index} className="col-md-6">
                        <Card className="family-member-card" style={{ borderRadius: '10px', padding: '20px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <strong>{member.name}</strong>
                            </div>
                            <div className="d-flex">
                              <Link to={`/update-profile/${member._id}`} className="btn btn-warning me-2" title={t('Edit')}>
                                <i className="fas fa-pen"></i>
                              </Link>
                              {member.childStatus === 'A' && member.profileStatus === 'I' && (
                                <Button variant="danger" onClick={() => deletefamily(member._id)} title={t('Delete')}>
                                  <i className="fa fa-trash"></i>
                                </Button>
                              )}
                            </div>
                          </div>
                        </Card>
                      </div>
                    )
                  ))
                ) : (
                  <div className="text-muted">{t('No family members added.')}</div>
                )}
              </div>
            </div>
          )}


        </Card.Body>
      </Card>




      <Modal className="fade bd-example-modal-lg" size="lg" show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Update Profile')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Create a form with input fields for each data field */}
          <div className="form-validation">
            <Form
              className="form-valide"
            >
              <div className="row">
                <div className="col-xl-6">
                  <div className="form-group mb-3 row text-black">
                    <label
                      className="col-lg-4 col-form-label"
                      htmlFor="val-username"
                    >
                      {t('Name')}
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        onChange={handleNameChange}
                        value={updatedData.name}
                        className="form-control text-black"
                        id="name"
                        name="val-username"
                        placeholder={t("Enter name..")}
                        maxLength={30}
                      />
                    </div>
                    {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
                  </div>
                  <div className="form-group mb-3 row text-black">
                    <label className="col-lg-4 col-form-label" htmlFor="val-blood-group">
                      {t('Blood')} <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-8">
                      <Select
                        className=" text-black"
                        options={bloodGroupOptions}
                        value={bloodGroupOptions.find(option => option.value === updatedData.bloodGroup)}
                        onChange={handleBloodGroupChange}
                        placeholder={t("Select your blood group")}
                        styles={customStyles}
                      />
                    </div>
                    {errors.bloodGroup && <div className="text-danger fs-12">{errors.bloodGroup}</div>}
                  </div>

                  <div className="form-group mb-3 row text-black">
                    <label className="col-lg-4 col-form-label" htmlFor="val-password">
                      {t('D O B')}
                      &nbsp;<span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-8">
                      <DatePicker
                        // type="date"
                        onChange={handleDOBChange}
                        value={updatedData.dob}
                        // dateFormat='dd-mm-yyyy'
                        className="form-control text-black"
                        id="dob"
                        name="dob"
                      />
                    </div>
                    {errors.dob && <div className="text-danger fs-12">{errors.dob}</div>}
                  </div>

                  <div className="form-group mb-3 row text-black">
                    <label className="col-lg-4 col-form-label" htmlFor="val-gender">
                      {t('Gender')}&nbsp;<span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-6">
                      <Select
                        options={genderOptions}
                        value={genderOptions.find(option => option.value === updatedData.gender)}
                        onChange={handleGenderChange}
                        placeholder={t("Select gender")}
                        styles={customStyles}
                      />
                    </div>
                    {errors.gender && <div className="text-danger fs-12">{errors.gender}</div>}
                  </div>

                  {/* <div className="form-group mb-3 row text-black">
                    <label className="col-lg-4 col-form-label" htmlFor="val-blood-group">
                      {t('Relationship')} <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-8">
                      <Select
                        className=" text-black"
                        options={RelationOptions}
                        value={RelationOptions.find(option => option.value === updatedData.relationShip)}
                        onChange={handlerelationshipChange}
                        placeholder={t("Select relationship")}
                      />
                    </div>
                    {errors.relationShip && <div className="text-danger fs-12">{errors.relationShip}</div>}
                  </div> */}

                </div>
                <div className="col-xl-6">
                  <div className="form-group mb-3 row text-black">
                    <label
                      className="col-lg-4 col-form-label"
                      htmlFor="val-digits"
                    >
                      {t('MaritalStatus')}&nbsp;<span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-8">
                      <Select
                        className="text-black"
                        options={maritalStatusOptions}
                        value={maritalStatusOptions.find(option => option.value === updatedData.maritalStatus)}
                        onChange={handleMaritalStatusChange}
                        placeholder={t("Select marital status")}
                        styles={customStyles}
                      />
                    </div>

                    {errors.maritalStatus && <div className="text-danger fs-12">{errors.maritalStatus}</div>}
                  </div>
                  <div className="form-group mb-3 row text-black">
                    <label className="col-lg-4 col-form-label" htmlFor="val-number">
                      {t('Profession')}&nbsp;<span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-8">
                      <Select
                        className="text-black"
                        options={typeOptions}
                        value={typeOptions.find(option => option.value === updatedData.profession)}
                        onChange={handleProfessionChange}
                        placeholder={t("Select profession...")}
                        styles={customStyles}
                      />
                      {showInput && (
                        <input
                          type="text"
                          className='form-control mt-2 text-black'
                          value={updatedData.profession}
                          onChange={handleCustomProfessionChange}
                          placeholder={t("Enter profession...")}
                        />
                      )}
                      {errors.profession && <div className="text-danger fs-12">{errors.profession}</div>}

                    </div>
                  </div>

                  <div className="form-group mb-3 row text-black">
                    <label className="col-lg-4 col-form-label" htmlFor="val-number">
                      {t('Foodtype')}&nbsp;<span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-8">
                      <Select
                        className="text-black"
                        options={FoodOptions}
                        value={FoodOptions.find(option => option.value === updatedData.foodType)}
                        onChange={handlefoodTypeChange}
                        placeholder={t("Select preferfood")}
                        styles={customStyles}
                      />
                    </div>
                    {errors.foodType && <div className="text-danger fs-12">{errors.foodType}</div>}
                  </div>

                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className='me-2' variant="dark" style={{ width: "35%", marginRight: "35%" }} onClick={() => setShowModal(false)}>
            {t('Close')}
          </Button>
          <Button className='me-2' variant="primary" onClick={handleUpdate} >
            {t('Save Changes')}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* add family members modal */}
      <Modal className="fade bd-example-modal-lg" size="lg" show={openModal} onHide={() => { setopenModal(false); resetFields(); }}>
        {/* <Modal className="fade bd-example-modal-lg" size="lg" show={openModal} onHide={() => setopenModal(false); resetFields();} > */}
        <Modal.Header closeButton>
          <Modal.Title>{t('Add Family Members')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Create a form with input fields for each data field */}
          <div className="form-validation">
            <Form className="form-valide">
              <div className="row">
                <div className="col-xl-6">
                  <div className="form-group mb-3 row text-black">
                    <label className="col-lg-4 col-form-label" htmlFor="val-username">{t('Name')} <span className="text-danger">*</span></label>
                    <div className="col-lg-6">
                      <input
                        type="text"
                        onChange={e => handleInputChange(e, 'name')}
                        value={name}
                        className="form-control text-black"
                        id="val-username"
                        name="val-username"
                        placeholder="Enter name.."
                      />
                    </div>
                    {error.name && <div className="text-danger fs-12">{error.name}</div>}
                  </div>
                  <div className="form-group mb-3 row text-black">
                    <label className="col-lg-4 col-form-label" htmlFor="gender">{t('Gender')} <span className="text-danger">*</span></label>
                    <div className="col-lg-6">
                      <Select
                        className="text-black"
                        value={options.find(option => option.value === gender)}
                        onChange={handlegenderChange}
                        options={options}
                        placeholder="Select "
                        styles={customStyles}
                      />
                    </div>
                    {error.gender && <div className="text-danger fs-12">{error.gender}</div>}
                  </div>

                </div>
                <div className="col-xl-6">
                  <div className="form-group mb-3 row text-black">
                    <label className="col-lg-4 col-form-label" htmlFor="date">{t('DOB')} <span className="text-danger">*</span></label>
                    <div className="col-lg-6">
                      <input
                        type="date"
                        value={dob}
                        onChange={e => handleInputChange(e, 'dob')}
                        className="form-control text-black"
                        id="date"
                        name="date"
                        max={today}
                      />
                    </div>
                    {error.dob && <div className="text-danger fs-12">{error.dob}</div>}
                  </div>
                  <div className="form-group mb-3 row text-black">
                    <label className="col-lg-4 col-form-label" htmlFor="relationship">{t('Relationship')}&nbsp;<span className="text-danger">*</span></label>
                    <div className="col-lg-6">
                      <Select
                        className="text-black"
                        value={Relationoptions.find(option => option.value === relationship)}
                        onChange={handlerelationChange}
                        options={Relationoptions}
                        placeholder={t("Select")}
                        styles={customStyles}
                      />
                    </div>
                    {error.relationship && <div className="text-danger fs-12">{error.relationship}</div>}
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className='me-2 ' variant="dark" style={{ width: "35%", marginRight: "35%" }} onClick={() => setopenModal(false)} >
            {t('Close')}
          </Button>
          <Button className='me-2' variant="primary" onClick={addmember}>
            {t('Save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default AppProfile;
