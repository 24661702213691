import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import Donut from "../Gymove/WorkoutStatistic/Donut";
import { Button, Modal, Nav, Tab } from "react-bootstrap";
import footprint from '../../../images/small/footprint.svg'
import { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Setstep, getstep } from "./store";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { AesDecrypt } from "../../../services/crypto";
import VisitorActivity from "./VisitorActivity";
import img from '../../../images/EmptyFile.svg'

const RunningChart = loadable(() =>
  pMinDelay(import("../Gymove/WorkoutStatistic/RunningChart"), 1000)
);
const CyclingChart = loadable(() =>
  pMinDelay(import("../Gymove/WorkoutStatistic/CyclingChart"), 1000)
  // pMinDelay(import("../Gymove/WorkoutStatistic/CyclingChart"), 1000)
);

const YogaTrainingChart = loadable(() =>
  pMinDelay(import("../Gymove/WorkoutStatistic/YogaTrainingChart"), 1000)
);
const PieChart = loadable(() =>
  pMinDelay(import("../Gymove/WorkoutStatistic/PieChart"), 1000)
);
const RedialBar = loadable(() =>
  pMinDelay(import("../Gymove/WorkoutStatistic/RadialBar"), 1000)
);

const WorkoutStatistic = () => {
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const [steps, setstepcount] = useState(true);
  const _id = localStorage.id;
  const handleReloadTable = () => {
    dispatch(getstep(_id))
      .then((res) => {
        const tempdata = res.payload.data
        if (tempdata && tempdata.length !== 0) {
          const data = AesDecrypt(tempdata);
          setstepcount(data)
        }
      })
  };


  useEffect(() => {

    dispatch(getstep(_id))
      .then((res) => {
        const tempdata = res.payload.data
        if (tempdata && tempdata.length !== 0) {
          const data = AesDecrypt(tempdata);
          setstepcount(data)
        }
      })
      .catch((error) => {
        console.error('Error fetching profile:', error);
        // Handle error
      });
  }, [dispatch]);

  // Function to handle opening the modal
  const handleOpenModal = () => {
    setShowModal(true);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setShowModal(false);
    setErrorMessage(""); // Reset error message when closing the modal
  };

  // Function to handle setting the step target
  const userId = localStorage.id
  const handleSetStepTarget = (stepTarget) => {
    if (!stepTarget) {
      setErrorMessage("Step target cannot be empty.");
    } else {
      // Logic to set the step target goes here
      const data = { stepTarget, userId };
      dispatch(Setstep(data)).then((res) => {
        handleReloadTable();
      });
      setShowModal(false); // Close the modal after setting the target
    }
  };
  return (
    <Fragment>
      {steps === true ? (
        <>
          <h1 style={{ textAlign: 'center' }}>No Data Available</h1>
          <div className="d-flex justify-content-center">
            <img src={img} alt="No Data" style={{ position: 'relative', top: '100px', height: "400px" }} />
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col-xl-6" >
            <div className="row">
              <div className="col-xl-12">
                <div className="card" style={{ boxShadow: "0px 0px 10px 10px rgb(144 144 144 / 56%)" }}>
                  <div className="card-header flex-wrap border-0 pb-0">
                    <h4 className="text-black fs-20 mb-3">{t('Progress')}</h4>
                    <Button
                      onClick={handleOpenModal}
                      className="btn btn-primary"
                    >
                      {t('Set Target')}
                    </Button>
                  </div>
                  <div className="card-body pt-0 pb-3">
                    <div className="row align-items-center">
                      <div className="col-lg-4 mb-lg-0 mb-4 text-center radialBar">
                        {/* <RedialBar /> */}
                      </div>
                      <div className="col-lg-8">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="d-flex align-items-center mb-sm-5 mb-3">
                              <div className="d-inline-block relative donut-chart-sale me-3">
                                <Donut backgroundColor="#FFBC11" backgroundColor2="rgba(236, 236, 236, 1)" value={(steps.Count / steps.Target) * 100} />
                                <small>
                                  <span className="activity-icon bgl-warning  ">
                                    <img src={footprint} />
                                  </span>
                                </small>
                              </div>
                              <div>
                                <h4 className="fs-18 text-black">
                                  {t("Steps")} {isNaN(steps.Count) || steps.Target === 0 ? '0%' : Math.round((steps.Count / steps.Target) * 100) + '%'}
                                </h4>
                                <span className="text-black">{steps.Count}/{steps.Target}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-xxl-8 col-lg-12 col-sm-12">
            <div id="user-activity" className="card">
              <Tab.Container defaultActiveKey="day">
                <div className="card-header border-0 pb-0 d-sm-flex d-block">
                  <h4 className="card-title">Visitor Activity</h4>
                  <div className="card-action mb-sm-0 my-2">
                    <Nav className="nav nav-tabs" role="tablist">
                      <Nav.Item className="nav-item">
                        <Nav.Link
                          className="text-black "
                          data-toggle="tab"
                          to="#"
                          role="tab"
                          eventKey="day"
                        >
                          Day
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="nav-item">
                        <Nav.Link
                          className="text-black"
                          data-toggle="tab"
                          to="#"
                          role="tab"
                          eventKey="month"
                        >
                          Month
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="nav-item">
                        <Nav.Link
                          className="text-black"
                          data-toggle="tab"
                          to="#"
                          role="tab"
                          eventKey="year"
                        >
                          Year
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
                <div className="card-body">
                  <Tab.Content className="tab-content" id="myTabContent">
                    <Tab.Pane eventKey="day" id="user" role="tabpanel">
                      <VisitorActivity selectedMonth={0} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="month" id="user" role="tabpanel">
                      <VisitorActivity selectedMonth={1} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="year" id="user" role="tabpanel">
                      <VisitorActivity selectedMonth={2} />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      )}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Set Step Target')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Form to set the step target */}
          <form onSubmit={(e) => {
            e.preventDefault();
            const stepTarget = e.target.stepTarget.value;
            handleSetStepTarget(stepTarget);
          }}>
            <div className="mb-3">
              <label htmlFor="stepTarget" className="form-label text-black">{t('Step Target')}</label>
              <input type="number" className="form-control text-black" id="stepTarget" name="stepTarget" required />
              {/* Error message */}
              {errorMessage && <div className="text-danger">{errorMessage}</div>}
            </div>
            <div className="d-flex justify-content-end">
              <Button type="submit" className="btn btn-primary">{t('Save')}</Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default WorkoutStatistic;
