import React, { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { getlanguage, getnutdata } from "./store/index"
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next'
import Breadcrumbs from '../../breadcrumbs'

const Nuts = () => {
  const [imageData, setImageData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation()

  const dispatch = useDispatch()



  useEffect(() => {
    const id = localStorage.id;
    if (id !== "undefined") {
      dispatch(getlanguage(id)).then((res) => {
        const lang = res.payload.language;
        dispatch(getnutdata(lang)).then((response) => {
          setImageData(response.payload.data);
        })
      });
    }
  }, [dispatch]);


  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filterednuts = imageData.filter((item) => {
    return item.name.toLowerCase().includes(searchTerm.toLowerCase());
  });


  return (

    <Fragment>
      {/* <Breadcrumbs title={t('Nuts')} data={[{ title: t('Fruits') }]} /> */}
      <h2 className="mb-4">{t("Fruits")}</h2>
      <div className=" shopping-cart mb-3">
        <input
          type="text"
          placeholder="Search Fruit Name..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="form-control text-black"
        />
      </div>

      <div className="row">
        {filterednuts !== undefined && filterednuts.map((item, index) => (
          <div className="mood-responsive-card " key={index}>
            <div
              className="card card-zoom "
              style={{
                // backgroundColor:"#dff7e4",
                boxShadow: " 0px 0px 10px 0px rgb(153, 201, 144)",
              }}
            >
              <Link to={`/nut-details/${item.parent_name}`}>
                <div style={{ padding: '5%' }} className="  ">
                  <div className="media ">
                    <img
                      src={item.img}
                      alt=""
                      className="rounded me-3"
                      style={{ maxWidth: '100px', maxHeight: '100px' }}

                    />
                    <div className="media-body ">
                      {/* <h4  className="fs-20 font-w600">{t(item.name)}</h4> */}
                      <h4 style={{ marginTop: '10%' }} className="fs-20 font-w600">{t(item.name)}</h4>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default Nuts;
