import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import RecomendedSlider from "../Gymove/Home/RecomendedSlider";
import '../../../config/i18n'

import { useTranslation } from 'react-i18next'
import { getbloodData, getBloodPost, getbloodsugarlevel, getBmi, getmooddata, getstepcount, getUserAnxiety, getUserDepression, getUserStress } from "./store";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import bp from '../../../images/small/blood-pressure.svg'
import BMI from '../../../images/small/BloodDonation.svg'
import mentalhealth from '../../../images/small/Narcissistic.svg'
import mentalhealthtest from '../../../images/small/Distress.svg'
import sugar from '../../../images/small/blood-sugar.svg'
import bmimage from '../../../images/small/bmi.svg'
import footprint from '../../../images/small/footprint.svg'
import Cycling from '../../../images/small/Cycling.svg'
import '../../../css/custom.css'
import { Link as RouterLink } from 'react-router-dom';
import webservice from "../../../services/webservice";
import love from '../../../images/emoji/Emoji - Heart Eyes.gif'
import angry from '../../../images/emoji/anger.gif'
import Fear from '../../../images/emoji/fear.gif'
import Sadness from '../../../images/emoji/sad.gif'
import Happy from '../../../images/emoji/happy.gif'
import Cheerful from '../../../images/emoji/cheerful.gif'
import Madness from '../../../images/emoji/Madness.gif'
import Calm from '../../../images/emoji/calm.gif'
import Negative from '../../../images/emoji/negative_mood.gif'
import Anxious from '../../../images/emoji/Anxious.gif'
import Wink from '../../../images/emoji/Wink.gif'
import Irritability from '../../../images/emoji/irritability.gif'
import Disgust from '../../../images/emoji/disgust.gif'

import "./../../../css/custom.css"
import Sugarcharts from "./diabeteschart";
import Bloodpressurechart from "./bloodpressurechart";
import { AesDecrypt } from "../../../services/crypto";
import { t } from "i18next";


import { Card } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const defaultMood = (t("How is your Mood today"));

const moodColors = {
  Anger: "red",
  Fear: "orange",
  Sadness: "gray",
  Happy: "#1eac00",
  Cheerful: "blue",
  Romantic: "#ff8096",
  Madness: "#b0b058",
  Calm: "#1eac00",
  NegativeMood: "red",
  Anxious: "#b0b058",
  Irritability: "red",
  Disgust: "orange"
};


const style = {
  width: '100%',
  // height: '60%'
}

const moodOptions = [
  { value: "Anger", label: t("Anger"), emoji: <img src={angry} alt="Heart Eyes Emoji" style={style} /> },
  { value: "Fear", label: t("Fear"), emoji: <img src={Fear} alt="Heart Eyes Emoji" style={style} /> },
  { value: "Sadness", label: t("Sadness"), emoji: <img src={Sadness} alt="Heart Eyes Emoji" style={style} /> },
  { value: "Happy", label: t("Happy"), emoji: <img src={Happy} alt="Heart Eyes Emoji" style={style} /> },
  { value: "Cheerful", label: t("Cheerful"), emoji: <img src={Cheerful} alt="Heart Eyes Emoji" style={style} /> },
  { value: "Romantic", label: t("Romantic"), emoji: <img src={love} alt="Heart Eyes Emoji" style={style} /> },
  { value: "Madness", label: t("Madness"), emoji: <img src={Madness} alt="Heart Eyes Emoji" style={style} /> },
  { value: "Calm", label: t("Calm"), emoji: <img src={Calm} alt="Heart Eyes Emoji" style={style} /> },
  { value: "Negative", label: t("Negative"), emoji: <img src={Negative} alt="Heart Eyes Emoji" style={style} /> },
  { value: "Anxious", label: t("Anxious"), emoji: <img src={Anxious} alt="Heart Eyes Emoji" style={style} /> },
  { value: "Irritability", label: t("Irritability"), emoji: <img src={Irritability} alt="Heart Eyes Emoji" style={style} /> },
  { value: "Disgust", label: t("Disgust"), emoji: <img src={Disgust} alt="Heart Eyes Emoji" style={style} /> },
  { label: t("How is your Mood today"), emoji: <img src={Wink} alt="Heart Eyes Emoji" style={style} /> }
];


const Home = () => {

  const dispatch = useDispatch();

  const [selectChange, setSelectChange] = useState('Monthly');
  const [sugarlevel, setsugarlevel] = useState([]);
  const [systolic, setsystolic] = useState([]);
  const [stressScore, setStressScore] = useState([]);
  const [anxietyScore, setAnxietyScore] = useState([]);
  const [depressionScore, setDepressionScore] = useState([]);
  const [resentBloodPost, setResentBloodPost] = useState([]);
  const [diastolic, setdiastolic] = useState([]);
  const [bmi, setbmi] = useState([]);
  const [mood, setMood] = useState(defaultMood);
  const [reason, setReason] = useState("");
  // const [moodData, setMoodData] = useState({});
  const [loading, setLoading] = useState(true);
  const [steps, setstepcount] = useState(true);

  const { t } = useTranslation()
  const [expandedReason, setExpandedReason] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalReason, setModalReason] = useState("");
  const userId = localStorage.id


  const toggleReason = (index) => {
    setExpandedReason({
      ...expandedReason,
      [index]: !expandedReason[index]
    });
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleReadMore = (reason) => {
    setModalReason(reason);
    toggleModal();
  };


  useEffect(() => {
    const _id = localStorage.id;
    dispatch(getbloodData(_id))
      .then((res) => {
        const tempdata = res.payload.data
        if (tempdata.length !== 0) {
          const data1 = AesDecrypt(tempdata);
          setsystolic(data1.systolic)
          setdiastolic(data1.diastolic)
        }
      })
      .catch((error) => {
        console.error('Error fetching profile:', error);
        // Handle error
      });
  }, [dispatch]);

  useEffect(() => {
    const _id = localStorage.id;
    dispatch(getbloodsugarlevel(_id))
      .then((res) => {
        const tempdata = res.payload.data
        if (tempdata.length !== 0) {
          const data1 = AesDecrypt(tempdata);
          setsugarlevel(data1.sugarLevel)
        }

      })
      .catch((error) => {
        console.error('Error fetching profile:', error);
        // Handle error
      });
  }, [dispatch]);

  useEffect(() => {
    const _id = localStorage.id;
    dispatch(getmooddata(_id))
      .then((res) => {
        const tempdata = res.payload.data

        if (tempdata && tempdata.length !== 0) {
          const data1 = AesDecrypt(tempdata);
          // setMoodData(data1.mood);
          setReason(data1.reason);
          setMood(data1.mood); // Set mood based on fetched data
        } else {
          // If data is empty or undefined, set default mood and emoji
          setMood(defaultMood);
          setReason(""); // Optionally set default reason to empty string
        }
      })
      .catch((error) => {
        console.error('Error fetching profile:', error);
        // Handle error
      })
      .finally(() => {
        setLoading(false); // Update loading state after fetching data
      });
  }, [dispatch]);

  useEffect(() => {
    const _id = localStorage.id;
    dispatch(getBmi(_id))
      .then((res) => {
        const tempdata = res.payload.data
        if (tempdata.length !== 0) {
          const data1 = AesDecrypt(tempdata);
          setbmi(data1.bmi)
        }
      })
      .catch((error) => {
        console.error('Error fetching profile:', error);
        // Handle error
      });
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserStress(userId))
      .then((res) => {
        const stressData = res.payload?.data || 0
        setStressScore(stressData.score || 0)
      })
  }, [dispatch])


  useEffect(() => {
    dispatch(getUserDepression(userId))
      .then((res) => {
        const depressionData = res.payload?.data || 0
        setDepressionScore(depressionData?.score || 0)
      })
  }, [dispatch])

  useEffect(() => {

    dispatch(getUserAnxiety(userId))
      .then((res) => {
        const anxietyData = res.payload?.data || 'anxity'
        setAnxietyScore(anxietyData?.score || 0)
      })
  }, [dispatch])

  
  useEffect(() => {
    if (!loading) {
      const _id = localStorage.id;
      dispatch(getstepcount(_id))
        .then((res) => {
          const tempdata = res.payload.data
          if (tempdata && tempdata.length !== 0) {
            const data1 = AesDecrypt(tempdata);
            setstepcount(data1)
          }
        })
        .catch((error) => {
          console.error('Error fetching profile:', error);
          // Handle error
        });
    }
  }, [dispatch, loading]);


  useEffect(() => {
    dispatch((getBloodPost(userId)))
      .then((res) => {
        if (res.payload.response === 'S') {
          const responseData = res.payload.data
          setResentBloodPost(responseData)
        } else {
          setResentBloodPost('')
        }
      })
  }, [dispatch])

  if (loading) {
    return (
      <>
        {/* <div style={{ textAlign: 'center', marginTop: '20%' }}>{t("Loading...")}</div> */}
        <div class="dashboard-loading">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </>
    );

  }


  const getColor = (score) => {
    if (score < 20) return "#4CAF50"; // Green
    else if (score < 40) return "#2196F3"; // Blue
    else if (score < 60) return "#ffa500"; // Yellow
    else if (score < 80) return "#ff6347"; // Orange
    else return "#f42702"; // Red
  };

  const getTrailColor = (score) => {
    if (score < 20) return "#a7f4aa"; // Lighter Green
    else if (score < 40) return "#96c6ec"; // Lighter Blue
    else if (score < 60) return "#f6d089"; // Lighter Yellow
    else if (score < 80) return "#fca596"; // Lighter Orange
    else return "#f42702"; // Lighter Red
  };

  
  return (
    <div className="row">
      <div className="col-xl-6 col-xxl-12" >
        <div className="row" >
          <div className="col-sm-6">

            <Link to="/BloodPressure" className="card avtivity-card">
              <div className="card-body">
                <h4 className="card_title">Blood Pressure</h4>
                <div className="media align-items-center">
                  <span className="activity-icon bgl-success me-md-4 me-3">
                    <img src={bp} />
                  </span>
                  <div className="media-body">
                    {
                      diastolic.length === 0 ? (
                        <>
                          <h2 className="fs-16 mb-2 font-w600">{t('Track Bloodpressure')}</h2>                                               </>
                      ) : (
                        <>
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                            {/* <span className="fs-14 mb-2 text-gray font-w600">{t('Recent Blood Pressure')}</span> */}
                            <div className="dash_container" >
                              <div className="dash_content">
                                <div className="dash_bp" >{systolic}<h6 className="sample_text" >mmHg</h6></div>
                                <div className="systolic"><span className="text-gray " >{t('Systolic')}</span></div>
                              </div>
                              <div className="dash_content">
                                <div className="dash_bp" >{diastolic}<h6 className="sample_text" >mmHg</h6></div>
                                <div className="diastolic"> <span className="text-gray " >{t('Diastolic')}</span></div>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    }
                  </div>
                </div>
                <div className="progress" style={{ height: 5 }}>
                  <div
                    className="bg-success"
                    style={{ width: "100%", height: 5 }}
                    role="progressbar"
                  >
                  </div>
                </div>
              </div>
              <div className="effect bg-success" />
            </Link>
          </div>

          <div className="col-sm-6 ">
            <Link to="/Diabetes" className="card avtivity-card">
              <div className="card-body  ">
                <h4 className="card_title">Blood Sugar</h4>
                <div className="media align-items-center">
                  <span className="activity-icon bgl-secondary  me-md-4 me-3">
                    <img src={sugar} />
                  </span>
                  <div className="media-body">
                    {sugarlevel == '' ?
                      (
                        <>
                          <h2 className="fs-16 mb-2 font-w600">{t('Track Diabetes')}</h2>
                        </>
                      ) : (
                        <>
                          <p className="fs-14 mb-2 text-grey font-w600">{t('Recent Blood Sugar level')}</p>
                          <span className="title text-black font-w600">{sugarlevel}(mg/dL)</span>
                        </>
                      )

                    }

                  </div>
                </div>
                <div className="progress" style={{ height: 5 }}>
                  <div
                    className="bg-secondary"
                    style={{ width: "100%", height: 5 }}
                    role="progressbar"
                  >
                    <span className="sr-only">42% {t('Complete')}</span>
                  </div>
                </div>
              </div>
              <div className="effect bg-secondary" />
            </Link>
          </div>



          <div className="col-sm-6">
            <Link to='/mentalhealth'>
              <div className="card avtivity-card">
                <div className="card-body">
                  <h4 className="card_title">Mental health</h4>
                  <div className="media align-items-center">
                    <span className="activity-icon bgl-danger me-md-4 me-3">
                      <img src={mentalhealth} />
                    </span>
                    <div className="media-body">
                      {stressScore || depressionScore || anxietyScore > 0 ? (
                        <div>
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}><div>{
                            depressionScore > 0 ? (
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                <div className="circular_progress" >
                                  <CircularProgressbar
                                    value={depressionScore}
                                    maxValue={100}
                                    text={`${depressionScore}%`}
                                    styles={buildStyles({
                                      pathColor: getColor(depressionScore),
                                      trailColor: getTrailColor(depressionScore),
                                      textColor: "#333",
                                    })}
                                  />
                                </div>
                                <p className=" circular_text " style={{ fontWeight: "600" }}>{t('Depression ')} </p>
                              </div>
                            ) : (
                              null
                            )
                          }</div>
                            <div className="dash_anxiety" >
                              {
                                anxietyScore > 0 ? (
                                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>


                                    <div className="circular_progress" >
                                      <CircularProgressbar
                                        value={anxietyScore}
                                        maxValue={100}
                                        text={`${anxietyScore}%`}
                                        styles={buildStyles({
                                          pathColor: getColor(anxietyScore),
                                          trailColor: getTrailColor(anxietyScore),
                                          textColor: "#333",
                                        })}
                                      />
                                    </div>
                                    <p className=" circular_text" style={{ fontWeight: "600" }}>{t('Anxiety ')} </p>
                                  </div>
                                ) : (
                                  null
                                )
                              }
                            </div>
                            <div>
                              {stressScore > 0 ? (
                                <div style={{ display:"flex", alignItems:"center", flexDirection:"column"}}>
                                 <div className="circular_progress" >
                                    <CircularProgressbar
                                      value={stressScore}
                                      maxValue={100}
                                      text={`${stressScore}%`}
                                      styles={buildStyles({
                                        pathColor: getColor(stressScore),
                                        trailColor: getTrailColor(stressScore),
                                        textColor: "#333",
                                      })}
                                    />
                                  </div>
                                  <p className=" circular_text " style={{ fontWeight: "600" }}>{t('Stress ')} </p>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                      ) : (
                        <h2 className="fs-16 mb-2 font-w600">{t('Track Mental peace')}</h2>
                      )}

                    </div>
                  </div>
                  <div className="progress" style={{ height: 5 }}>
                    <div
                      className="bg-danger"
                      style={{ width: "100%", height: 5 }}
                      role="progressbar"
                    >
                      <span className="sr-only">42% {t('Complete')}</span>
                    </div>
                  </div>
                </div>
                <div className="effect bg-danger" />
              </div>
            </Link>
          </div>


          <div className="col-sm-6">
            <Link to="/workout-statistic" className="card avtivity-card">
              <div className="card-body">
                <h4 className="card_title">steps</h4>
                <div className="media align-items-center">
                  <span className="activity-icon bgl-warning  me-md-4 me-3">
                    <img src={footprint} />
                  </span>
                  <div className="media-body">
                    {steps && Object.keys(steps).length > 0 ? (
                      <>
                        <p className="fs-14 mb-2 text-black">{t('Walk Counts')}</p>
                        <span className="title text-black font-w600">
                          {steps} {t('Steps')}
                        </span>
                      </>

                    ) : (
                      <span className="fs-16 mb-2  font-w600">{t('Track steps')}</span>
                    )
                    }

                  </div>
                </div>

                <div className="progress" style={{ height: 5 }}>
                  <div
                    className="bg-warning"
                    style={{ width: "100%", height: 5 }}
                    role="progressbar"
                  >
                  </div>
                </div>

              </div>
              <div className="effect bg-warning" />
            </Link>
          </div>


          <div className="col-sm-6">
            <Link to="/mood_monitor" className="card avtivity-card">
              <div className="card-body">
                <h4 className="card_title">Mood Monitor</h4>
                <div className="media align-items-center">
                  {/* <span > */}
                  {moodOptions.map((moodOption) =>
                    moodOption.label === mood ? (
                      <span style={{ fontSize: '300%', maxWidth: "30%" }} className="me-md-4 me-3" key={moodOption.label} >
                        {moodOption.emoji}
                      </span>
                    ) : null
                  )}
                  {/* </span> */}
                  <div className="media-body">

                    {mood === 'How is your Mood today' ? (
                      <>
                        <h2 className="fs-16 mb-2 font-w600">{t('How is your Mood today')}</h2>
                      </>
                    ) : (
                      <>
                        <span className="fs-14 mb-2 text-gray font-w600">{t('Your Recent Mood')}</span>
                        <span className="text-black font-w700 " style={{ display: "flex", alignItems: "center" }} >
                          {/* <span>
                            Mood : &nbsp;
                          </span> */}
                          <h3 className="font-w700" style={{ color: moodColors[mood] ? "#000" : "" }}>{mood}</h3>
                          {/* <h3 style={{ marginLeft: "3%", marginTop: "1%", color: moodColors[mood] ? moodColors[mood] : "" }}>
                            <span>
                              Reason : &nbsp;
                            </span>
                            {t(reason)}
                          </h3> */}
                        </span>
                      </>

                    )}

                  </div>
                  <Modal isOpen={modalOpen} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}><strong>{t('Reason')}</strong></ModalHeader>
                    <ModalBody>
                      <p>{modalReason}</p>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={toggleModal}>{t('Close')}</Button>
                    </ModalFooter>
                  </Modal>
                </div>
                <div className="progress" style={{ height: 5 }}>
                  <div
                    className=" bg-primary"
                    style={{ width: "100%", height: 5 }}
                    role="progressbar"
                  >
                  </div>
                </div>
              </div>
              <div className="effect bg-primary" />
            </Link>
          </div>


          <div className="col-sm-6 " >
            <Link to="/BMICalculator" className="card avtivity-card ">
              <div className="card-body ">
                <h4 className="card_title">BMI</h4>
                <div className="media align-items-center">
                  <span className="activity-icon bgl-info  me-md-4 me-3">
                    <img src={bmimage} />
                  </span>
                  <div className="media-body " style={{ display: "flex", alignItems: "start", flexDirection: "column" }}>
                    <span className="fs-14 mb-2 text-gray font-w700">{t('Your BMI')}</span>
                    <span className="title text-black font-w600">
                      {bmi}(kg/m2)
                    </span>
                  </div>
                </div>
                <div className="progress" style={{ height: 5 }}>
                  <div
                    className=" bg-info"
                    style={{ width: "100%", height: 5 }}
                    role="progressbar"
                  >
                  </div>
                </div>
              </div>
              <div className="effect bg-info" />
            </Link>
          </div>
        </div>


        <div className="col-xl-12 col-xxl-8" style={{ width: "205%" }}>
          {/* <div className="row"> */}
          <div className="col-xl-6 dashboard-fruit-card" >
            <div className="card" style={{ boxShadow: "0px 0px 5px 5px rgb(144 144 144 / 56%)" }}>
              <div className="card-header d-sm-flex d-block pb-0 border-0">
                <div className="me-auto pr-3">
                  <h4 className="card_title">
                    {t('Nature Food Details and Benefits')}
                  </h4>
                </div>
              </div>
              <div className="card-body pt-2">
                <div className="testimonial-one owl-carousel">
                  <RecomendedSlider />
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>

      </div>


      <div className="col-xl-6 col-xxl-12">

        <div className="dashboard-bpsugar-container chart_bp_sugar " >
          <Card className="Dashboad-bloodpressure-card chart_bp_card">
            <h4 className="card_title">{t('Blood Pressure')}</h4>
            <Bloodpressurechart />
          </Card>
          &nbsp;
          &nbsp;
          <Card className="Dashboad-diabetes-card chart_bp_card">
            <h4 className="card_title">{t('Blood Sugar Level')}</h4>
            <Sugarcharts />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Home;
