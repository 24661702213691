import React, { Fragment, useState } from "react";
import "../../../css/custom.css";
import { Card, CardBody, Button, Form, FormGroup, Label, Input, Alert, Col } from "reactstrap";
import { useDispatch } from "react-redux";
import { AddAnxiety } from "./store";
import { Link, useNavigate } from "react-router-dom";
import { AesDecrypt } from "../../../services/crypto";
import { useTranslation } from 'react-i18next';
import BreadCrumbs from "../../breadcrumbs";
import Swal from "sweetalert2";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';

const Anxiety = () => {
  const dispatch = useDispatch();
  const [anxiety, setAnxiety] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedChoice, setSelectedChoice] = useState("");
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const userId = localStorage.id;
  const navigate = useNavigate();

  const handleChoiceSelection = (choice) => {
    setSelectedChoice(choice);
    setError("");
  };

  const handleNextQuestion = (e) => {
    e.preventDefault();
    if (!selectedChoice) {
      setError(t("Please select an option"));
      return;
    }
    const currentQuestion = anxietyQuestions[currentQuestionIndex].id;
    setAnxiety((prevMood) => ({ ...prevMood, [currentQuestion]: selectedChoice }));
    setSelectedChoice(anxiety[anxietyQuestions[currentQuestionIndex + 1]?.id] || "");
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handlePreviousQuestion = (e) => {
    e.preventDefault();
    setError(""); // Clear any existing error
    setSelectedChoice(anxiety[anxietyQuestions[currentQuestionIndex - 1].id] || "");
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const [dateTime, setDateTime] = useState(new Date());
  const calculateScore = (scoreData, type) => {
    if (!scoreData || typeof scoreData !== 'object') return 0;

    const scores = Object.values(scoreData).map(Number);
    let totalScore = scores.reduce((acc, score) => acc + score, 0);

    if (totalScore === 0) {
      totalScore = 5; // Consider 0 as 5
    }
    const adjustedScore = (totalScore * 100) / 21;
    return Math.round(Math.min(adjustedScore, 100)); // Return rounded score
  };

  const UTCDate = moment(dateTime, 'MM-DD-YYYY hh:mm a').utc().toISOString();

  const handleSaveMood = (e) => {
    e.preventDefault();

    if (!selectedChoice) {
      setError(t("Please select an option"));
      return;
    }

    // Capture current anxiety question and selected choice
    const currentQuestion = anxietyQuestions[currentQuestionIndex].id;
    const AnxietyData = { ...anxiety, [currentQuestion]: selectedChoice };

    // Calculate anxiety score
    const score = calculateScore(AnxietyData);

    // Prepare dataToInsert according to the structure
    const uid = uuidv4(); // Generate unique ID
    const UTCDate = new Date().toISOString(); // UTC date format for creation/modification time
    const collection = 'Anxiety';
    const lastSyncDate = ''; // If no sync date is provided, leave it empty
    const dataToInsert = [{
      anxiety: AnxietyData,
      score,
      // testDate: UTCDate,       // The UTC formatted test date
      userId,
      createdDate: UTCDate,    // UTC date for creation time
      // modifiedDate: UTCDate,   // UTC date for modification time
      uid
    }];
    const dataToDelete = []; // Add any data that needs to be deleted, currently it's empty

    // Construct the final data object
    const data = {
      dataToInsert,
      lastSyncDate,
      collection,
      dataToDelete,
      userId
    };

    // Dispatch the data to be stored in the backend
    dispatch(AddAnxiety(data)).then((response) => {
      const Response = response.payload.response;
      if (Response === "S") {
        Swal.fire({
          title: t("Anxiety Test Successful"),
          icon: "success",
        });
        navigate('/mentalhealth');
      }
    });

    // Reset states after save
    setAnxiety({});
    setSelectedChoice("");
    setCurrentQuestionIndex(0);
  };


  const anxietyQuestions = [
    { question: t("1.Feeling nervous, anxious or on edge"), id: "a1" },
    { question: t("2.Not being able to stop or control worrying"), id: "a2" },
    { question: t("3.Worrying too much about different things"), id: "a3" },
    { question: t("4.Trouble relaxing"), id: "a4" },
    { question: t("5.Being so restless that it is hard to sit still"), id: "a5" },
    { question: t("6.Becoming easily annoyed or irritable"), id: "a6" },
    { question: t("7.Feeling afraid as if something awful might happen"), id: "a7" }
  ];


  const commonChoices = [
    { label: t("Not at all"), value: "0" },
    { label: t("Several days"), value: "1" },
    { label: t("More than half the days"), value: "2" },
    { label: t("Nearly every day"), value: "3" }
  ];

  const isLastQuestion = currentQuestionIndex === anxietyQuestions.length - 1;
  const isFirstQuestion = currentQuestionIndex === 0;

  return (
    <Fragment>
      <BreadCrumbs title={t('Anxiety')} data={[{ title: t('Anxiety') }]} />

      <span className="mentalhealth text-black">{t('Over the last 2 weeks, how often have you been bothered by the following problems?')}</span>
      <Card style={{ marginTop: "2%", boxShadow: "0px 0px 10px 5px rgb(144 144 144 / 56%)" }}>
        <CardBody>
          <Form onSubmit={isLastQuestion ? handleSaveMood : handleNextQuestion}>
            <FormGroup tag="fieldset">
              <legend className="text-black" style={{ fontSize: "30px" }}>{anxietyQuestions[currentQuestionIndex].question}</legend>
              {commonChoices.map((choice, i) => (
                <FormGroup className="text-black" check key={i}>
                  <Label check>
                    <Input
                      type="checkbox"
                      className="form-check-input mb-4"
                      value={choice.value}
                      checked={selectedChoice === choice.value}
                      onChange={() => handleChoiceSelection(choice.value)}
                    />
                    {choice.label}
                  </Label>
                </FormGroup>
              ))}
            </FormGroup>
            {error && <Alert color="danger">{error}</Alert>}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {!isFirstQuestion && (
                <Button color="secondary" onClick={handlePreviousQuestion} style={{ marginRight: "10px" }}>
                  {t('Previous')}
                </Button>
              )}
              <Button style={{ width: 'auto' }} color="primary" type="submit" className="small-button"  >
                {isLastQuestion ? t('Save') : t('Next')}
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default Anxiety;
