import React, { useEffect, useState } from "react";
import { Modal, Nav, Tab, Card, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import { vaccine_details, getlanguage } from "./store/index"
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next'



//import StarRating from './../ProductList/StarRating';

const Vaccinedetails = () => {

    const { id } = useParams();
    const dispatch = useDispatch()
    const [AllData, setVaccineData] = useState([]);
    const { t } = useTranslation()




    useEffect(() => {
        const fetchData = async () => {
            const _id = localStorage.id;
            if (_id && _id !== "undefined") {
                const langResponse = await dispatch(getlanguage(_id));
                const lang = langResponse.payload.language;
                const data = { id, lang };
                const Response = await dispatch(vaccine_details(data));
                if (Response.payload.data) {
                    setVaccineData(Response.payload.data);
                } else {
                    setVaccineData('')
                }
            }
        };

        fetchData();
    }, [dispatch, id]);

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <Card className="card" style={{ boxShadow: "0px 0px 10px 10px rgb(144 144 144 / 56%)" }}>
                        <Card.Header>
                            <Col style={{ maxWidth: '37px' }}>
                                <div className='icon_backButton'>
                                    <Link to={"/vaccination"}>
                                        <i className="fas fa-arrow-alt-circle-left"></i>
                                    </Link>
                                </div>
                            </Col>
                            <Col >
                                <h2 className="text-align-center">{t(AllData.vaccine_name)}</h2>
                            </Col>
                        </Card.Header>
                        <Card.Body>
                            <div className="row">
                                <div className="col" style={{ padding: '5%' }}>
                                    <div >
                                        <div dangerouslySetInnerHTML={{ __html: AllData.content }}></div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default Vaccinedetails;
