import React, { useEffect, useState } from 'react';
import webservice from '../../../services/webservice';
import { useTranslation } from 'react-i18next';

function BMIuserCalculator() {
    const { t } = useTranslation();
    const [bmi, setBMI] = useState(null);
    // const [userHeight, setUserHeight] = useState(null);
    const [interpretation, setInterpretation] = useState('');
    const [interpretationColor, setInterpretationColor] = useState('');
    const [averageWeightRange, setAverageWeightRange] = useState('');
    const [weightInRange, setWeightInRange] = useState(false);
    useEffect(() => {

        const fetchData = async () => {
            try {
                const userID = localStorage.getItem('id');
                const response = await fetch(webservice + 'api/bmiValue', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ userID: userID })
                });
                const data = await response.json();
                if (data.user) {
                    setBMI(data.user.bmi);
                    setInterpretation(data.interpretation);
                    // setUserHeight(data.user.height)
                    setInterpretationColor(getInterpretationColor(data.interpretation)); // Set interpretation color
                    const avgWeight = getAverageWeight(data.user.height, data.user.bmi);
                    setAverageWeightRange(avgWeight);
                    if (data.user.weight >= avgWeight.lower && data.user.weight <= avgWeight.upper) {
                        setWeightInRange(true);
                    } else {
                        setWeightInRange(false);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const intervalId = setInterval(fetchData, 1000); // Fetch data every 1 second
        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, []);

    const name = localStorage.name;

    const getInterpretationColor = (interpretation) => {
        let color = '';

        switch (interpretation) {
            case 'Underweight':
                color = '#f9f988';
                break;
            case 'Normal weight':
                color = '#257815';
                break;
            case 'Overweight':
                color = '#e47611';
                break;
            case 'Obese':
                color = 'red';
                break;
            default:
                color = ''; // Default color
        }

        return color;
    };

    const getAverageWeight = (height, bmi) => {
        const heightInMeters = height / 100; // Convert height to meters
        const bmiLowerLimit = 18.5;
        const bmiUpperLimit = 24.9;
        const lowerWeight = Math.round(bmiLowerLimit * heightInMeters * heightInMeters); // Calculate lower weight limit
        const upperWeight = Math.round(bmiUpperLimit * heightInMeters * heightInMeters); // Calculate upper weight limit
        return {
            lower: lowerWeight,
            upper: upperWeight
        }; // Return the weight range
    };

    return (
        <>
            <div className="userBmi_card" >
                <h2 style={{ marginTop: '20px', fontSize: '24px', color: 'black' }}>{name}, {t('your BMI is')} : <span style={{ color: interpretationColor }}>{bmi}</span></h2>
                <h3 style={{ marginTop: '10px', fontSize: '20px', color: 'black' }}>{t('BMI Categories')} :  <span style={{ color: interpretationColor }}> {interpretation}</span></h3>
                <p style={{ marginTop: '10px', fontSize: '18px', color: 'black' }}>{t('Average Weight Range')} : <span style={{ color: interpretationColor }}> {averageWeightRange.lower} - {averageWeightRange.upper} kg</span></p>

            </div>
        </>
    );
}

export default BMIuserCalculator;
