import React, { useEffect, useState } from 'react';
import webservice from '../../../services/webservice';
import { useTranslation } from 'react-i18next';

function UpdateHeightWeight() {
    const [height, setHeight] = useState(''); // Initialize with empty string
    const [weight, setWeight] = useState(''); // Initialize with empty string
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { t } = useTranslation();

    const handleUpdate = async () => {
        setErrorMessage('');
        try {
            if (!height) {
                setErrorMessage(t('Height is required'));
                return;
            } else if (!weight) {
                setErrorMessage(t('Weight is required'));
                return;
            } else {
                const userId = localStorage.getItem('id');
                const response = await fetch(webservice + 'api/updateHeightWeight', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ userId, height, weight })
                });
                const data = await response.json();
                setSuccessMessage(t('Successfully updated height and weight.'));

                setTimeout(() => {
                    setSuccessMessage('');
                }, 3000);
            }
        } catch (error) {
            console.error('Error updating user data:', error);
            setErrorMessage('Error updating user data');
        }
    };

    useEffect(() => {
        const userDetails = localStorage.getItem('userDetails');
        if (userDetails) {
            const user_detail = JSON.parse(userDetails);
            setHeight(user_detail.height || '');
            setWeight(user_detail.weight || '');
        }
    }, []);

    return (
        <div className="update-height-weight-container">
            <h2 className="update-height-weight-title">{t('Update Your Height and Weight')}</h2>
            <label htmlFor="height" className="input-label text-black">{t('Height')}(cm):</label>
            <input
                required
                type="number"
                id="height"
                value={height}
                onChange={(e) => setHeight(e.target.value || '')}
                placeholder={t("Enter Height")}
                className="input-field"
            />
            &nbsp;
            <label htmlFor="weight" className="input-label text-black">{t('Weight')} (kg):</label>
            <input
                required
                type="number"
                id="weight"
                value={weight}
                onChange={(e) => setWeight(e.target.value || '')} // Fallback to empty string
                placeholder={t("Enter Weight")}
                className="input-field"
            />
            <button
                onClick={handleUpdate}
                className="update-button"
            >
                {t('Update')}
            </button>
            {successMessage && <p className="success-message">{successMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
    );
}

export default UpdateHeightWeight;
