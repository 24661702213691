import { t } from 'i18next'
import React from 'react'
import { Fragment } from 'react'
import { Row, Col, Card, Carousel } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'



const Slides = () => {

  const { t } = useTranslation()


  const carousel2 = [
    {
      text: 'First',
      title: t("Wellness Assistant"),
      description: t("Empower your health journey with our app, your trusted wellness assistant for a better life.")
    },
    {
      text: 'Second',
      title: t("Mood Monitoring"),
      description: t("Track your mood daily for insights and self-awareness, empowering personal growth and well-being.")
    },
    {
      text: 'Third',
      title: t("Safe and Privacy"),
      description: t("Safely store and view your medical records with encrypted protection for complete privacy and security.")
    }
  ];


  return (
    <Fragment>

      <Row>
        <div className='slideshow'>

          <Col xl={6}>
            <Card>
              <Card.Body className='p-2'>
                <Carousel controls={false} indicators={false}>
                  {carousel2.map((carousel, i) => (
                    <Carousel.Item key={i}>
                      {/* <img
                
                      width='50%'
                      src={carousel.img}
                      alt=" "
                    /> */}
                      <div className='text-black ' style={{ fontSize: "50px" }}>{carousel.title} </div>
                      <h1 style={{ color: "rgb(64 57 57)" }}>{carousel.description} </h1>
                    </Carousel.Item>

                  ))}
                </Carousel>
              </Card.Body>
            </Card>
          </Col>
        </div>
      </Row>
    </Fragment>
  )
}

export default Slides
